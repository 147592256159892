import { DOMAIN_STATUS, DOCUMENT_STATUS, HTTP_STATUSES } from "../../constants/constant"

export const TRANSLATION_KM =
{
    domain: 'ឈ្មោះដែនជាតិ',
    firstYearFee: 'តម្លៃឆ្នាំដំបូង',
    fromTwoYearFee: 'តម្លៃឆ្នាំបន្ទាប់',
    renewPriceFee: 'តម្លៃបន្តសុពលភាពដែន',
    "khqrDescription":"ស្គែន ដើម្បីទូទាត់ជាមួយកម្មវិធីធនាគារដែលជាសមាជិក",
    "abaPayDescription": "ស្គែន ដើម្បីទូទាត់តាម ABA Mobile",
    "acledaXPayDescription": "បង់ប្រាក់តាមរយៈកម្មវិធីទូរស័ព្ទដៃ ACLEDA",
    "creditDebitCard": "កាតឥណទាន/ឥណពន្ធ",
    "AddMoreNameServer": "បន្ថែមសេវូបករណ៍ផ្ទុកទិន្នន័យ",
    "AboutDNS": "អំពីឈ្មោះដែនជាតិ",
    "AccountText": "គណនី",
    "FillServerInfo": "បញ្ចូលព័ត៌មានពេលក្រោយ",
    "AddOrEditDomainInfoText": "បន្ថែម ឬ កែប្រែព័ត៌មានដែន",
    "DomainInfoSkip": "រំលង",
    "DomainInfoSkipTooltip": "រំលងដើម្បីទៅទំព័របន្ទាប់",
    "DomainServerInfoTitle": "វិធីស្វែងរកព័ត៌មានសេវូបករណ៍",
    "DomainServerInfoText": "​អ្នក​បញ្ចូល​ឈ្មោះ​កំណត់កន្លែង​ដែល​ឯកសារ​ ​D​NS ​របស់​អ្នក​ត្រូវ​បាន​បង្ហោះ​។​ ​នេះ​ក៏​អាច​ត្រូវ​បាន​រក​ឃើញ​យ៉ាង​ឆាប់រហ័ស​តាមរយៈ​ការស្វែងរក​របស់​ ​WHO​I​S​។​ ​ប្រើ​ម៉ាស៊ីន​ស្វែងរក​របស់​ ​WHO​I​S ​ដូចជា",
    "DomainServerInfoText1": "ស្វែងរកឈ្មោះដែនរបស់អ្នក",
    "DomainServerInfoText2": "​រកមើល​លទ្ធផល​របស់​អ្នក​រហូតដល់​អ្នករកឃើញ​ព័ត៌មាន​សេ​វូប​ករ​ណ៍​។​ ​នេះ​នឹង​អនុញ្ញាត​ឱ្យ​អ្នកដឹង​ថា​អ្នកណា​ជា​អ្នក​រៀបចំ​ ​D​NS ​របស់​អ្នក",
    "DomainServerInfoOkay": "យល់ព្រម",
    "AddressRequireText": "សូមបំពេញអាសយដ្ឋាន",
    "InstitutionRequireText": "សូមជ្រើសរើសប្រភេទ",
    "SelectInstitutionText": "ជ្រើសរើសប្រភេទនីតិបុគ្គល",
    "AddressText": "អាសយដ្ឋាន",
    "AddToCartText": "បន្ថែមទៅកន្ត្រក",
    "Back": "ត្រលប់ក្រោយ",
    "BackToTopText": "ត្រលប់​ទៅ​ខាងលើ",
    "bluePanelIndex1": "ស្វែងរកឈ្មោះ, ម៉ាក, អាជីវកម្ម, ក្រុមហ៊ុន, ឬអង្គការរបស់អ្នក",
    "bluePanelIndex2": "ជ្រើសរើសឈ្មោះដែន និងប្រភេទដែន .kh",
    "bluePanelIndex3": "បញ្ជូនព័ត៌មាននិងឯកសារពាក់ព័ន្ធដើម្បីអនុម័ត",
    "bluePanelIndex4": "ពិនិត្យ និងបង់ប្រាក់",
    "ByCompletedDomainOrderPleaseReadandConfirmTermAndPrivacyText": "​ដើម្បី​ធ្វើការ​បញ្ជាទិញ​ដែន​នេះ​សូម​អាន​និង​យល់ព្រម​តាម",
    "Cart": "រទេះ",
    "CheckOutText": "រួចរាល់",
    "ChooseFile": "ជ្រើសរើស​ឯកសារ",
    "DragDrop": "ទាញឯកសារដាក់ចូលទីនេះ",
    "RemoveFile": "លុប",
    "FileHere": "ឯកសារជាទម្រង់ (jpg, png or pdf) ឬក៏",
    "CivilServantID": "អត្តលេខមន្ត្រីរាជការ",
    "CivilServantIDRequire": "​អត្តលេខ​មន្ត្រី​រាជការ​គឺ​តម្រូវ​ឱ្យ​មាន​។",
    "CloseText": "បិទ",
    "Company": "ក្រុមហ៊ុន",
    "companyDescription": "សម្រាប់​គេហទំព័រ ​​សហគ្រាស​ពាណិជ្ជកម្ម សហគ្រាសសាធារណៈ និង ក្រុមហ៊ុន​",
    "CompanyInformationText": "ព័ត៌​មាន​ក្រុមហ៊ុន",
    "companyTitle": "ពាណិជ្ជកម្ម",
    "CompleteProfileText": "បំពេញព័ត៌មានផ្ទាល់ខ្លួន",
    "CompleteYourDomain": "បំពេញដែនរបស់អ្នក",
    "ContactInformation": "ព័ត៌មានទំនាក់ទំនង",
    "ContactText": "ទំនាក់ទំនង",
    "DataSavedSuccessfully": "​ទិន្នន័យ​ត្រូវ​បាន​រក្សាទុក​ដោយ​ជោគជ័យ​។",
    "Date": "កាលបរិច្ឆេទ",
    "DateofBirthRequireText": "សូមបំពេញថ្ងៃខែ​ឆ្នាំ​កំណើត",
    "DateofBirthText": "ថ្ងៃខែ​ឆ្នាំ​កំណើត",
    "DelegateManagementAndAdministrationToOfficer1": "​ប្រគល់​សិទ្ធិ​គ្រប់គ្រង​ ​និង​ ​ចាត់ចែង​ជូន​មន្ត្រី​ទី​១",
    "DelegateManagementAndAdministrationToOfficer2": "​ប្រគល់​សិទ្ធិ​គ្រប់គ្រង​ ​និង​ ​ចាត់ចែង​ជូន​មន្ត្រី​ទី​២",
    "DelegateManagementAndAdministrationToOfficer3": "​ប្រគល់​សិទ្ធិ​គ្រប់គ្រង​ ​និង​ ​ចាត់ចែង​ជូន​មន្ត្រី​ទី​៣",
    "Detail": "ព័ត៌មានលម្អិត",
    "Discount": "បញ្ចុះតម្លៃ",
    "DisplayName": "ឈ្មោះ",
    "DisplayNameRequire": "ឈ្មោះគឺតម្រូវឱ្យមាន។",
    "docRequireText": "ឯកសារភ្ជាប់",
    "DocumentStatus": "ស្ថានភាពឯកសារ",
    "DocumentText": "ឯកសារភ្ជាប់",
    "DocumentFileSizeText": "ឯកសារភ្ជាប់អនុញ្ញាតត្រឹមទំហំអតិបរិមា ១០​មេហ្កាមៃ",
    "DocumentFileTypeText": "សូមភ្ជាប់ឯកសារជាទម្រង់ pdf",
    "DomainGovAndMilNotAvailableText": "​ដែន​ .​go​v​.k​h​ ​និង​ .​m​i​l​.k​h​ ​មិន​មាន​សម្រាប់​អ្នក​ទេ​។",
    "DomainInformationIsNotYetFillIn": "​ព័ត៌មាន​ដែន​មិនទាន់​បំពេញ​ទេ​។",
    "DomainInformationText": "ព័ត៌មានសេវូបករណ៍",
    "domainInfoText": "​សូម​បញ្ចូល​ព័ត៌មាន​នៃ​ឈ្មោះ​សេ​វូប​ករ​ណ៍ ​សម្រាប់",
    "DomainKHText": "ដែន .kh",
    "DomainManagerContact": "​ព័ត៌មាន​ទំនាក់ទំនង​អ្នកគ្រប់គ្រង​ដែន",
    "UntickText": "​(​បើ​ព័ត៌មាន​ខុសគ្នា​សូម​លុប​សញ្ញា​ធី​ក​(​tick​)​ពី​ក្នុង​ប្រអប់​ )",
    "domainMangerDescription": "​អ្នកគ្រប់គ្រង​ដែន​គឺជា​ជន​បង្គោល​ដែល​អាច​ផ្ដល់​នូវ​ព័ត៌មាន​ទូទៅ​ទាក់ទង​ទៅ​នឹង​ដែន​។",
    "DomainNameAlreadyInUsedText": "​ឈ្មោះ​ដែន​ ​ត្រូវ​បាន​កំពុង​ប្រើប្រាស់​។​ ​សូម​ព្យាយាម​នូវ​ដែន​ផ្សេងទៀត​!",
    "DomainNameAlreadySubmitText": "​ឈ្មោះ​ដែន​​បាន​ដាក់​ស្នើ​រួចហើយ",
    "DomainNameNotValidatedText": "​ឈ្មោះ​ដែន​ .k​h​ ​ត្រូវ​មាន​តួ​រ​អ​ក្ស​ឬ​លេខ​ ​ចន្លោះ​ពី១​ ​ទៅដល់​៦​៣​ ​ដែល​អាច​ជា​អក្សរ​ឡាតាំង​ (​a​ ​ដល់​ ​z​) ​លេខ​ (0 ​ដល់​ 9) ​និង​សហ​សញ្ញា​ (-)​។",
    "DomainNameRequireText": "សូមបំពេញឈ្មោះដែន",
    "DomainPayerContact": "​ព័ត៌មាន​ទំនាក់ទំនង​អ្នក​បង់​ប្រាក់​ដែន",
    "domainPayerDescription": "អ្នកបង់​ប្រាក់​ដែនជាតិ​គឺជា​ជន​បង្គោល​ដែល​អាច​ផ្ដល់​នូវ​ព័ត៌មាន​នៃ​ការ​ទូទាត់​វិកយបត្រដែល​ពាក់ព័ន្ធ​នឹង​ដែនជាតិ​។",
    "DomainStatus": "ស្ថានភាពដែន",
    "DomainTechnicianContact": "​ព័ត៌មាន​ទំនាក់ទំនង​អ្នកបច្ចេកទេស​ដែន",
    "domainTechnicianDescription": "​អ្នកបច្ចេកទេស​ដែន​គឺជា​ជន​បង្គោល​ដែល​អាច​ផ្ដល់​នូវ​ព័ត៌មាន​បច្ចេកទេស​ទាក់ទង​ទៅ​នឹង​ដែនជាតិ​។",
    "DomainText": "ដែនជាតិ",
    "download-our-document-for-reference": "​ទាញ​យក​ឯកសារ​របស់យើង​សម្រាប់​ជា​ឯកសារ​យោង",
    "download-sample": "ទាញយកគំរូ",
    "DownloadYourOldDocumentText": "​ទាញ​យក​ឯកសារ​របស់​អ្នក",
    "ViewDownload": "ទាញយកគំរូ",
    "SampleDocumentText": "គំរូ​ឯកសារ",
    "DragAndDrop": "អូសនិងទម្លាក់",
    "emailDescription": "​​អុី​មែ​ល​​បច្ចុប្បន្ន​ឬ​អុី​មែ​ល​ថ្មី​ ​ដែល​ត្រូវ​បង្កើត​ជាមួយ​ដែន​ដែល​បាន​ធ្វើការ​ស្នើសុំ​។",
    "EmailNotValidFormat": "​​អុី​មែ​ល​​មិន​ត្រឹមត្រូវតាម​ទម្រង់​ទេ​។",
    "EmailRequireText": "សូមបំពេញអុីមែល",
    "EmailText": "អុីមែល",
    "ContactEmailText": "អុីមែលទំនាក់ទំនង",
    "UserEmailText": "អុីមែលអ្នកប្រើប្រាស់",
    "ExpireDate": "ថ្ងៃផុតកំណត់",
    "FaxNumberText": "លេខទូរសារ",
    "FindPerfectDomainText": "​ស្វែងរក​ដែន​ដ៏​ល្អឥតខ្ចោះ​របស់​អ្នក",
    "FirstName": "គោត្តនាម",
    "FirstNameRequire": "​គោត្តនាម​គឺ​តម្រូវ​ឱ្យ​មាន​។",
    "FillinServerInfo":"សូមបំពេញព័ត៌មានសេវូបករណ៍",
    "ForOrganizationOrCompanyText": "សម្រាប់នីតិបុគ្គល",
    "ForNewUpdate": "សម្រាប់ព័ត៌មានថ្មីៗ",
    "ForPersonalUseText": "សម្រាប់រូបវន្តបុគ្គល",
    "FreeText": "មិនគិតថ្លៃ",
    "TermAndCondition": "​លក្ខខណ្ឌនៃការប្រើប្រាស់ឈ្មោះដែនជាតិ",
    "TermCaption": "ម្ចាស់ឈ្មោះដែនជាតិ ត្រូវមានកាតព្វកិច្ចដូចខាងក្រោម៖",
    "TermCondition1": "​១. ធានាភាពត្រឹមត្រូវនៃព័ត៌មាននិងឯកសារដែលផ្តល់ជូននិយ័តករទូរគមនាគមន៍កម្ពុជា (ន.ទ.ក.)។",
    "TermCondition2": "២. មិនត្រូវប្រើប្រាស់ឈ្មោះដែនជាតិដើម្បីផ្សព្វផ្សាយសកម្មភាព ឬពាណិជ្ជកិច្ច ឬព័ត៌មានផ្សេងៗ និងធ្វើទំនាក់ទំនងនៅលើប្រព័ន្ធអ៊ីនធឺណិតដែលប៉ះពាល់វប្បធម៌ សីលធម៌ ទំនៀមទម្លាប់ប្រពៃណីល្អរបស់ជាតិ សិទ្ធិបុគ្គល សិទ្ធិអ្នកប្រើប្រាស់ ផលប្រយោជន៍សាធារណៈស្របច្បាប់ សន្តិសុខជាតិ ឬសណ្តាប់ធ្នាប់សាធារណៈ។",
    "TermCondition3": "​៣. មិនត្រូវអនុញ្ញាតឱ្យប្រើប្រាស់ឬជួលឈ្មោះដែនជាតិទៅតតិយជន។",
    "TermCondition4": "​៤. មិនត្រូវផ្ទេរ ឬអនុញ្ញាតឱ្យប្រើប្រាស់ឬជួលឈ្មោះដែនជាតិទៅតតិយជន។",
    "TermCondition5": "​៥. ត្រូវបង់កម្រៃសេវាប្រើប្រាស់ឈ្មោះដែនជាតិឱ្យបានទៀងទាត់។ ក្នុងករណីម្ចាស់ឈ្មោះដែនជាតិពុំបង់កម្រៃក្នុងរយៈពេល៦០ (ហុកសិប)ថ្ងៃ ឈ្មោះដែនជាតិនឹងត្រូវលុបចេញពីប្រព័ន្ធគ្រប់គ្រងឈ្មោះដែនជាតិ ។",
    "TermCondition6": "​៦. ត្រូវជូនដំណឹងនិងបំពេញតាមលក្ខខណ្ឌតម្រូវមក ន.ទ.ក. ក្នុងករណីមានបំណងផ្ទេរការប្រើប្រាស់ឈ្មោះដែនជាតិ។",
    "TermCondition7": "៧. សហការជាមួយក្រសួងប្រៃសណីយ៍និងទូរគមនាគមន៍ និង ន.ទ.ក. ព្រមទាំងអាជ្ញាធរមានសមត្ថកិច្ចពាក់ព័ន្ធ ក្នុងករណីដែលមានការតម្រូវដោយច្បាប់និងលិខិតបទដ្ឋានគតិយុត្តផ្សេងទៀតជាធរមាន។",
    "generalUsageDescription": "សម្រាប់​គេហទំព័រ​​ ​រូបវន្តបុគ្គល​ ​និង​នីតិបុគ្គល",
    "generalUsageTitle": "ការប្រើប្រាស់ទូទៅ",
    "generalInformation": "ព័ត៌មានទូទៅ",
    "generalInfoText1": "ការចុះឈ្មោះដែនជាតិ .kh ត្រូវចែកចេញជាពីរកម្រិត៖ កម្រិតទី១ និងកម្រិតទី២។ ដែនជាតិកម្រិតទី១ ត្រូវបានដាក់ឱ្យប្រើប្រាស់សម្រាប់បុគ្គលក្នុងនិងក្រៅប្រទេស នៃព្រះរាជាណាចក្រកម្ពុជា។ ដែនជាតិកម្រិតទី២ ត្រូវបានដាក់ឱ្យប្រើប្រាស់សម្រាប់ បុគ្គលដែលបង្កើតឬចុះបញ្ជីស្របតាមច្បាប់នៃព្រះរាជាណាចក្រកម្ពុជា ហើយត្រូវបានបែងចែកទៅតាមប្រភេទដូចខាងក្រោម៖",
    "generalInfoText1.1": "សម្រាប់សហគ្រាសពាណិជ្ជកម្ម ឬសហគ្រាសសាធារណៈ។",
    "generalInfoText1.2": "សម្រាប់អង្គការ សមាគម ឬសហជីព។",
    "generalInfoText1.3": "សម្រាប់គ្រឹះស្ថានអប់រំសាធារណៈ និងឯកជន។",
    "generalInfoText1.4": "សម្រាប់ក្រុមហ៊ុន ឬស្ថាប័នដែលផ្តល់សេវាបណ្តាញកុំព្យូទ័រ។",
    "generalInfoText2": "ក្រសួងប្រៃសណីយ៍និងទូរគមនាគមន៍ (ក.ប.ទ.) ជាស្ថាប័នមានសមត្ថកិច្ចលើការគ្រប់គ្រងឈ្មោះដែនជាតិនៅក្នុងប្រព័ន្ធអ៊ីនធឺណិត។ និយ័តករទូរគមនាគមន៍កម្ពុជា (ន.ទ.ក.) ទទួលបន្ទុកធ្វើនិយតកម្មលើការប្រើប្រាស់ឈ្មោះដែនជាតិនៅក្នុងប្រព័ន្ធអ៊ីនធឺណិតនិងមានតួនាទីនិងភារកិច្ចដូចតទៅ៖",
    "generalInfoText2.1": "ផ្តល់ កែប្រែ ផ្ទេរ ដកហូតវិញ្ញាបនបត្រចុះឈ្មោះការប្រើប្រាស់ឈ្មោះដែនជាតិ និងធ្វើបច្ចុប្បន្នកម្មព័ត៌មាន ពាក់ព័ន្ធនឹងឈ្មោះដែនជាតិ។",
    "generalInfoText2.2": "រៀបចំបញ្ជីឈ្មោះដែនជាតិដែលត្រូវហាមឃាត់ឬបម្រុងទុក។",
    "generalInfoText2.3": "ផ្រៀបចំប្រមូលទិន្នន័យសម្រាប់ដំណើរការវិធីការព័ត៌មានឈ្មោះដែនជាតិនិងផ្សព្វផ្សាយជាសាធារណៈ។",
    "generalInfoText2.4": "ត្រូវរក្សាព័ត៌មានដែលមានលក្ខណៈជាឯកជន ក្រោមទម្រង់អេឡិចត្រូនិកតាមគ្រប់មធ្យោបាយដើម្បីធានាថាព័ត៌មាននោះត្រូវបានការពារប្រកដដោយ សុវត្ថិភាព គ្រប់កាលៈទេសៈសមហេតុផល ដើម្បីចៀសវាងការបាត់បង់ ការចូល ការប្រើប្រាស់ ការកែប្រែ ការបែកធ្លាយ ឬការលាតត្រដាងព័ត៌មាន ទាំងនោះលើកលែងតែមានការអនុញ្ញាតពីម្ចាស់ព័ត៌មាននោះឬភាគីផ្សេងទៀតដែលអនុញ្ញាតដោយច្បាប់។",
    "generalInfoText2.5": "ចាត់វិធានការផ្អាកបណ្តោះអាសន្ននិងលុបចោលឈ្មោះដែនជាតិ ដែលប្រើប្រាស់ផ្ទុយនឹងច្បាប់ឬបទបញ្ញត្តិ ជាធរមាន។",
    "generalInfoText2.6": "ផ្រៀបចំលិខិតបទដ្ឋានគតិយុត្តនិងផែនការអភិវឌ្ឍន៍ ដើម្បីជំរុញនិងលើកទឹកចិត្តការប្រើប្រាស់ឈ្មោះដែនជាតិ។",
    "generalInfoText2.7": "សហប្រតិបត្តិការជាមួយក្រសួង ស្ថាប័នក្នុងប្រទេសនិងអន្តរជាតិ លើការងារពាក់ព័ន្ធនឹងការគ្រប់គ្រងឈ្មោះដែនជាតិ។",
    "generalInfoText2.8": "អនុវត្តតួនាទីនិងភារកិច្ចផ្សេងៗទៀតតាមការកំណត់របស់រដ្ឋមន្ត្រី ក.ប.ទ.",
    "governmentTitle":"រដ្ឋាភិបាល",
    "governmentDescription":"សម្រាប់គេហទំព័រ រាជរដ្ឋាភិបាល",
    "headerText": "​​បង្ហាញ​ពី​គំនិត​របស់​អ្នក​នៅ​លើ​",
    "headingDomainTypeText": "​ដែន​ .kh ​អាច​ប្រើប្រាស់​បាន​សម្រាប់​គ្រប់​ប្រភេទ​គេហទំព័រ",
    "headingHowtoBuyDescription": "​អ្នក​អាច​ទទួល​បាន​ឈ្មោះ​ដែន​របស់​អ្នក​ ​រយៈពេល​ត្រឹមតែ​ពីរ​ទៅ​បី​នាទី",
    "headingHowtoBuyDescription1": "រហ័ស សាមញ្ញ និងងាយស្រួល។",
    "headingHowtoBuyText": "របៀបទិញដែនជាតិ",
    "howToBuyResgisterText": "របៀបចុះឈ្មោះ",
    "howToDescription": "និតិវិធីស្តីពី ដំណើរការស្នើសុំចុះឈ្មោះ ដែនជាតិ .kh តាមប្រព័ន្ធស្វ័យប្រវត្តិកម្ម",
    "howToStep1": "ចូលទៅគេហទំព័រ",
    "howToStep2": "បំពេញព័ត៍មានផ្ទាល់ខ្លួន",
    "howToStep3": "បញ្ជាទិញដែនជាតិ",
    "howToStep4": "បង់ប្រាក់",
    "howToStep5": "បង្កើតគណនី",
    "howToStep6": "ស្វែងរកដែនជាតិ",
    "howToStep7": "បញ្ជូនព័ត៌មាននិងឯកសារ ពាក់ព័ន្ធដើម្បីអនុម័ត",
    "howToStep8": "ដំណើរការដែនជាតិ",
    "howToVideoTitle": "វីដេអូស្តីពី ដំណើរការស្នើសុំចុះឈ្មោះដែនជាតិ",
    "howToGuideTitle": "សៀវភៅមគ្គុទេសក៍ដំណើរការស្នើសុំចុះឈ្មោះដែនជាតិ",
    "howToDownload": "ទាញយក",
    "MyAccount": "គណនេយ្យខ្ញុំ",
    "helpText": "ជំនួយ",
    "DomainRegistered": "ដែននេះបានចុះឈ្មោះរួចហើយ",
    "iCertifyText": "ខ្ញុំ​បាទ​/​នាង​ខ្ញុំំ ​សូម​ធានាអះអាង​ថា​ព័ត៌មាន​ដែល​បាន​ផ្ដល់​ខាងលើ​ពិតជា​ត្រឹមត្រូវ​និង​ពិតប្រាកដ​មែន​។​ ​ប្រសិនបើ​មាន​ព័ត៌មាន​ដែល​មិន​ពិតប្រាកដ​ ខ្ញុំំ​បាទ​/​នាង​ខ្ញុំំ​សូម​ទទួលខុសត្រូវ​ចំពោះមុខ​ច្បាប់​ហើយ​ដែន​របស់​ខ្ញុំ​នឹង​ត្រូវ​បាន​ព្យួរ​។",
    "IdentificationDocument": "ឯកសារបញ្ជាក់អត្តសញ្ញាណ",
    "IDOrPassportRequireText": "​សូម​បំពេញ​លេខ​អត្តសញ្ញាណប័ណ្ណ​ / ​លិខិតឆ្លងដែន",
    "IDOrPassportText": "​លេខ​អត្តសញ្ញាណប័ណ្ណ​ / លិខិតឆ្លងដែន",
    "IDText": "​លេខ​អត្តសញ្ញាណប័ណ្ណ​",
    "PassportText": "​លិខិតឆ្លងដែន",
    "IHaveReadAndAgreeToThe": "​ខ្ញុំ​បាន​អាន​និង​យល់ស្រប​នឹង",
    "IntroductionVideoText": "វីដេអូណែនាំ",
    "InternetTitle":"អ៊ីនធឺណិត",
    "InternetDescription":"សម្រាប់គេហទំព័រ ស្ថាប័នផ្តល់សេវាបណ្តាញកុំព្យូទ័រ",
    "IPAddress": "អាសយដ្ឋាន IP Address",
    "IPAddressNotValidFormatText": "​អាសយដ្ឋាន​ IP Address {{value}} ​មិន​ត្រឹមត្រូវតាម​ទម្រង់​ទេ​។",
    "IPAddressRequire": "​សូម​បញ្ចូល​ព័ត៌មាន​អាសយដ្ឋាន​ IP Address ​របស់​សេ​វូប​ករ​ណ៍​ផ្ទុក​ទិន្នន័យ",
    "IsAvailableText": "នៅទំនេរ",
    "IsNotAvailableText": "​មិន​មាន​សម្រាប់​បញ្ជាទិញ​បានទេ",
    "IsThisDomainForCompanyOrPersonalUsage": "​តើ​ដែន​ត្រូវ​បាន​ប្រើប្រាស់​សម្រាប់​ ​នីតិបុគ្គល​ ​ឬ​ ​រូបវន្តបុគ្គល​?",
    "JobTitleRequireText": "​សូម​បំ​ពេញមុខ​ងារ​ / ​តួនាទី",
    "JobTitleText": "មុខងារ / តួនាទី",
    "KHRText": "រៀល",
    "LastName": "នាម",
    "LastNameRequire": "នាមគឺតម្រូវឱ្យមាន។",
    "LatinNameRequireText": "​សូម​បំពេញឈ្មោះ​ជា​អក្សរ​ឡាតាំង",
    "LatinNamePatternText": "​សូម​បំពេញឈ្មោះ​ជា​អក្សរ​ឡាតាំង, មិនអនុញ្ញាតតួអក្សរពិសេស",
    "LatinNameText": "​ឈ្មោះ​ជា​អក្សរ​ឡាតាំង",
    "legalText": "ច្បាប់ និងបទប្បញ្ញត្តិ",
    "LoginText": "ចូលគណនី",
    "LogOutText": "ចាកចេញ",
    "copyright": "រក្សាសិទ្ធិ",
    "weAccept": "យើងទទួល",
    "mptc": "ក្រសួងប្រៃសណីយ៍និងទូរគមនាគមន៍",
    "trc": "និយ័តករទូរគមនាគមន៍កម្ពុជា(ន.ទ.ក)",
    "allRightReserved": "រក្សាសិទ្ធិ​គ្រប់យ៉ាង",
    "MinistryName": "ឈ្មោះក្រសួង",
    "MinistryNameRequire": "ឈ្មោះក្រសួងគឺតម្រូវឱ្យមាន។",
    "MyProfileText": "ព័ត៌មានរបស់ខ្ញុំ",
    "NameRequireText": "សូមបំពេញឈ្មោះ",
    "NameServer1RequireText": "​ឈ្មោះ​របស់​សេ​វូប​ករ​ណ៍ ​១​ ​គឺ​តម្រូវ​ឱ្យ​មាន​។",
    "NameServerLabel": "ឈ្មោះរបស់សេវូបករណ៍",
    "NameServer2RequireText": "​ឈ្មោះ​របស់​សេ​វូប​ករ​ណ៍ ​២​ ​គឺ​តម្រូវ​ឱ្យ​មាន​។",
    "NameServer2Text": "ឈ្មោះរបស់សេវូបករណ៍​​ ២",
    "NameServerNotValidFormatText": "​ឈ្មោះ​របស់​សេ​វូប​ករ​ណ៍​ {{value}} មិន​ត្រឹមត្រូវតាម​ទម្រង់​ទេ​។",
    "NameServerRequireText": "​សូម​បញ្ចូល​ព័ត៌​មានឈ្មោះ​សេ​វូប​ករ​ណ៍​ផ្ទុក​ទិន្នន័យ",
    "NameServerText": "ឈ្មោះម៉ាស៊ីនសេវូបករណ៍",
    "nameServeText": "ឈ្មោះម៉ាស៊ីនសេវូបករណ៍",
    "NameText": "ឈ្មោះ",
    "NationalityRequireText": "សញ្ជាតិគឺតម្រូវឱ្យមាន។",
    "NationalityText": "សញ្ជាតិ",
    "NextText": "បន្ទាប់",
    "OnlyGovAndMilAvaiableForYouText": "​មានតែ​ដែន​ .MIL.KH ​និង​ ..gov.kh ​ដែល​អាច​​​រក​បាន​សម្រាប់​អ្នក",
    "orderSummaryTitle": "​សេចក្តីសង្ខេប​នៃ​ការ​បញ្ជាទិញ",
    "organizationDescription": "សម្រាប់​គេហទំព័រ​ ​អង្គការ​ ​​សមាគម​​ និងសហ​ជីព",
    "OrganizationOrCompanyInformationText": "ព័ត៌មានអង្គការ / ក្រុមហ៊ុន",
    "OrganizationOrCompanyRequireText": "សូមបំពេញអង្គភាព / ក្រុមហ៊ុន",
    "OrganizationOrCompanyText": "អង្គភាព / ក្រុមហ៊ុន",
    "organizationTitle": "អង្គការ",
    "OrText": "ឬ",
    "OurCustomer": "អតិថិជនរបស់យើង",
    "OurMoreThan": "ច្រើនជាង​ ៣០០០ អាជីវកម្មដែលបានចុះឈ្មោះជាមួយដែនជាតិ .kh",
    "our-feeback": "មតិប្រតិកម្ម សម្រាប់យើង",
    "PayerInformationText": "​ព័ត៌មាន​ទំនាក់ទំនង​វិ​ក័យ​ប័ត្រ​ទូទាត់ប្រាក់",
    "PaymentDetailText": "​ព័ត៌មាន​លម្អិត​នៃ​វិ​ក័យ​ប័ត្រ​ទូទាត់ប្រាក់",
    "paymentMethodTitle": "​សូម​ជ្រើសរើស​វិធីសាស្ត្រ​បង់​ប្រាក់",
    "PaymentText": "ការបង់ប្រាក់",
    "PayNowText": "បង់ប្រាក់ឥឡូវនេះ",
    "Personal": "​រូបវន្តបុគ្គល​",
    "PrivacyPolicy": "គោលការណ៍ឯកជន",
    "educationDescription": "សម្រាប់​គេហទំព័រ​​ ​គ្រឹះស្ថាន​អប់រំរដ្ឋ និងឯកជន​",
    "PersonalInformation": "ព័ត៌មាន​ផ្ទាល់ខ្លួន",
    "personalInfoTitle": "ព័ត៌មាន​ផ្ទាល់ខ្លួន",
    "educationTitle": "គ្រឹះស្ថានអប់រំ",
    "PhoneNumberRequireText": "សូមបំពេញលេខទូរស័ព្ទ",
    "PhoneNumberInvalidText": "លេខទូរស័ព្ទមិនត្រឹមត្រូវ",
    "PhoneNumberText": "លេខទូរស័ព្ទ",
    "PhoneText": "ទូរស័ព្ទ",
    "please-choose-payment-method": "សូមជ្រើសរើសវិធីបង់ប្រាក់",
    "PleaseAcceptReadandConfirmTermAndPrivacyText": "​សូម​អាន​លក្ខខណ្ឌ​និង​គោលការណ៍​ឯកជន​ភាព​។",
    "PleaseAcceptTermAndConditionText": "​សូម​អាន​លក្ខ​ខ​ណ្ណ​នៃ​ការប្រើប្រាស់",
    "PleaseChooseOfBelowPaymentMethodText": "​សូម​ជ្រើសរើស​វិធី​ទូទាត់​មួយ​ខាងក្រោម",
    "PleaseUpdateYourDomainInformation": "​សូម​ធ្វើការ​កែប្រែ​ព័ត៌មាន​ដែន​របស់​អ្នក",
    "PleaseUploadAllRequireDocumentEndStar": "​សូម​ ​Up​load​ ​ឯកសារ​ភ្ជាប់​ដែល​បញ្ចប់​ដោយ *",
    "PleaseUploadOfBelowFileText": "​សូម​ ​Up​load​ ​ឯកសារ​មួយ​ក្នុងចំណោម​ឯកសារ​ខាងក្រោម",
    "Prefession": "តួនាទីនិងស្ថាប័ន",
    "PreviousText": "ថយក្រោយ",
    "Price": "តម្លៃ",
    "profile-and-documenttitle": "ព័ត៌មានអំពីប្រវត្តិរូប និងឯកសារនានា",
    "ViewComment": "មើលមតិយោបល់",
    "ViewMore":"មើលបន្ថែម",
    "ViewMoreInfo":"មើលព័ត៌មានបន្ថែម",
    "RequireChanges": "​ដែន​នេះ​ទាមទារ​ឱ្យ​មាន​ការផ្លាស់ប្តូរ​។​ ​សូម​ពិនិត្យមើល​មតិ​ខាងក្រោម​ហើយ​បញ្ជូន​ព័ត៌មាន​របស់​អ្នក​ឡើងវិញ​តាម​នោះ​។",
    "RequestChanges": "ទាមទារការផ្លាស់ប្តូរ",
    "ManageDomainTitle": "គ្រប់គ្រងដែន",
    "ProfileText": "ព័ត៌មានផ្ទាល់ខ្លួន",
    "PurchaseNowText": "បញ្ជាទិញ",
    "PurchaseThankText": "​ដែន​ជាតិរបស់​អ្នក​កំពុងតែ​រៀបចំ​!",
    "PurchaseThankText1": "​ពួកយើង​នឹង​ផ្ញើ​អុីម៉ែ​ល​ជា​ការ​ជូនដំណឹង​នា​ពេល​បន្តិចទៀត​នេះ​ ទៅកាន់",
    "Purpose": "គោលបំណង",
    "PurposeOfDomainText": "​គោលបំណង​នៃ​ការ​ប្រើ​ឈ្មោះ​ដែន​ជាតិ, ",
    "PurposeoftheWebsite": "​គោលបំណង​នៃ​ការ​ប្រើ​ដែន​គេហទំព័រ",
    "PurposeRequire": "​គោលបំណង​គឺ​តម្រូវ​ឱ្យ​មាន​។",
    "questionAnswer": "សំណួរ | ចម្លើយ",
    "qnaTitle": "សំណួរ​ដែលសួរញឹកញាប់",
    "qnaText1": "តើឈ្មោះដែន (Domain Name) ជាអ្វី?",
    "qnaAnswer1": "ឈ្មោះដែន​គឺជា​ឈ្មោះ​កំណត់​អត្តសញ្ញាណ​តែមួយ​គត់​នៅ​ក្នុង​សកលលោក​ ​ដែល​សម្រួល​ឱ្យ​សាធារណជន​អាច​ទៅ​រក​ធនធាន​ប្រព័ន្ធ​អុី​ន​ធឺ​ណិ​ត​នៅ​ក្នុង​តំបន់​។​ ​និយាយ​ឱ្យ​ស្រួល​ទៅ​​វា​គឺជា​អាសយដ្ឋាន​ប្រៃសណីយ៍​របស់​អ្នក​នៅ​ក្នុង​ពិភព​អុី​ន​ធឺ​ណិ​ត​។",
    "qnaText2": "​តើការ​ចុះឈ្មោះ​ដែន​ជាតិ​មាន​អត្ថប្រយោជន៍​អ្វីខ្លះ​?",
    "qnaAnswer2": "​ឈ្មោះ​ដែន​ជាតិ​ផ្តល់​នូវ​អាសយដ្ឋាន​អុី​ន​ធឺ​ណិ​ត​ដែល​គួរ​ឱ្យ​ទុកចិត្ត​និង​ងាយស្រួល​ក្នុង​ការស្វែងរក​ ​ហើយ​វា​ជា​ថ្នាល​ចំណត​ដែល​ផ្តល់​ព័ត៌មាន​ពី​ផលិតផល​ឬ​សេវាកម្ម​របស់​អ្នក​ទៅកាន់​សាធារណជន​។",
    "qnaText3": "តើដែនកម្រិតទី១ (ឬដែនកម្រិតខ្ពស់) (top level domain) ជាអ្វី?",

    "qnaAnswer3": "ដែន​ក​ម្រិ​ត​ទី​១​ (​ឬ​ដែន​ក​ម្រិ​ត​ខ្ពស់​) (​top​ ​level​ ​domain​) ​សំដៅ​ដល់​ដែន​ក​ម្រិ​ត​ខ្ពស់​បំផុត​នៅ​ក្នុង​ឋានានុក្រម​នៃ​ឈ្មោះ​ដែន​។​",
    "qnaAnswer3.1": "ឧទាហរណ៍​៖​ ​ដែន​ “.k​h​” ​និង​ “.​ខ្មែរ​ ​គឺជា​ដែន​ក​ម្រិ​ត​ខ្ពស់​បំផុត​ក្នុង​ក្នុង​ឋានានុក្រម​នៃ​ឈ្មោះ​ដែន​ជាតិ​របស់​ព្រះរាជាណាចក្រកម្ពុជា​ ​ដែល​ត្រូវ​បាន​បង្ហាញ​ជា​អត្តសញ្ញាណ​ ​នៃ​ព្រះរាជាណាចក្រកម្ពុជា​ក្នុង​ប្រព័ន្ធ​អុី​ន​ធឺ​ណិ​ត​។​",
    "qnaText4": "តើដែនកម្រិតទី១ទូទៅ (generic top level domain - gTLD) និងដែនកម្រិតទី១តាមកូដប្រទេស (country code top-level domain - ccTLD) ខុសគ្នាដូចមេ្តច?",
    "qnaAnswer4": "ដែន​ក​ម្រិ​ត​ទី​១​ទូទៅ​គឺជា​ដែន​ក​ម្រិ​ត​ខ្ពស់​បំផុត​នៅ​ក្នុង​ឋានានុក្រម​នៃ​ឈ្មោះ​ដែន​ក្នុង​ប្រព័ន្ធ​អុី​ន​ធឺ​ណិ​ត​ ​ដែល​មានឈ្មោះ​ដូចជា​ .​co​m​, .​net​ ​និង​ .or​g​។​ ",
    "qnaAnswer4.1": "​ដែន​ក​ម្រិ​ត​ទី​១​ទូទៅ​មិន​ត្រូវ​បាន​កំណត់​ឬ​តំណាង​ឱ្យ​ប្រទេស​ណាមួយ​ទេ​ហើយ​ដែន​នេះ​ត្រូវ​បាន​គ្រប់គ្រង​ដោយ​សាជីវកម្ម​គ្រប់គ្រង​អាសយដ្ឋាន​ឈ្មោះ​និង​លេខ​អុី​ន​ធឺ​ណិ​ត​ (​Inter​net​ ​Corporation​ ​for​ ​Assign​e​d​ ​Name​s ​and​ ​Number​s - ​I​CAN​N). ​ដែន​ក​ម្រិ​ត​ទី​១​តាម​កូ​ដ​ប្រទេស​ (​country​ ​code​ ​top​-​level​ ​domain​ - cc​T​L​D​) ​គឺជា​ដែន​ក​ម្រិ​ត​ខ្ពស់​បំផុត​នៅ​ក្នុង​ឋានានុក្រម​នៃ​ឈ្មោះ​ដែន​ក្នុង​ប្រព័ន្ធ​អុី​ន​ធឺ​ណិ​ត​ដែល​ត្រូវ​បាន​រក្សាទុក​សម្រាប់​ប្រទេស​ ​តំបន់​ ​ឬ​ទីតាំង​ភូមិសាស្ត្រ​ណាមួយ​។​ ​ឧទាហរណ៍​៖​ k​h​ ​ជា​ដែន​ក​ម្រិ​ត​ទី​១​តាម​កូ​ដ​ប្រទេស​សម្រាប់​ព្រះរាជាណាចក្រកម្ពុជា​។",
    "qnaText5": "តើដែនកម្រិតទី២ជាអ្វី?",
    "qnaAnswer5": "​ដែន​ក​ម្រិ​ត​ទី​២​គឺជា​ដែន​ដែល​នៅ​ខាងឆ្វេង​បន្ទាប់ពី​ដែន​ក​ម្រិ​ត​ទី​១​។​ ​ឧទាហរណ៍​៖​ ​នៅ​ក្នុង​ដែន​ ​cam​bo​d​i​a​.k​h​, ​cam​bo​d​i​a​ ​គឺជា​ដែន​ក​ម្រិ​ត​ទី​២​ ​និង​ k​h​ ​គឺជា​ដែន​ក​ម្រិ​ត​ទី​១។",
    "qnaText6": "តើអនុដែនជាអ្វី?",
    "qnaAnswer6": "​អនុ​ដែន​គឺជា​ដែន​ដែល​ត្រូវ​បាន​បង្កើត​ ​និង​មាន​ទីតាំងនៅ​ខាងឆ្វេង​បន្ទាប់ពី​ដែន​ដែល​ស្នើសុំ​។​ ​ឧទាហរណ៍​៖​ ​m​p​t​c.​go​v​.k​h​, ​m​p​t​c ​គឺជា​អនុ​ដែន​របស់​ដែន​ .​go​v​.k​h​ ​និង​ ​go​v​ ​គឺជា​អនុ​ដែន​របស់​ .k​h​។​ ​m​p​t​c.​go​v​.k​h​ ​ក៏​អាច​ហៅថា​ជា​ដែន​ក​ម្រិ​ត​ទី​៣​ ​និង​ ​go​v​.k​h​ ​ជា​ដែន​ក​ម្រិ​ត​ទី​២។",
    "qnaText7": "តើភ្នាក់ងារចុះឈ្មោះដែនជាតិជាអ្នកណា?",
    "qnaAnswer7": "​ភ្នាក់ងារ​ចុះឈ្មោះ​ដែន​ជាតិ​ជា​នីតិបុគ្គល​ដែល​បាន​ចុះបញ្ជី​ស្រប​តាមច្បាប់​នៃ​ព្រះរាជាណាចក្រកម្ពុជា​និង​ទទួល​បាន​វិញ្ញា​បន​ប​ត្រ​ចេញ​ដោយ​ ​ន​.​ទ​.​ក​. ​ក្នុង​ការ​ ​ផ្តល់​សេវា​ទាក់ទង​នឹង​ការ​ចុះឈ្មោះ​ដែន​ជាតិ​។",
    "qnaText8": "តើម្ចាស់ឈ្មោះដែនជាតិជាអ្នកណា?",
    "qnaAnswer8": "​ម្ចាស់​ឈ្មោះ​ដែន​ជាតិ​ជា​បុគ្គល​ដែល​បាន​ទទួល​វិញ្ញា​បន​ប​ត្រ​ចុះឈ្មោះ​ការប្រើប្រាស់​ឈ្មោះ​ដែន​ជាតិ​ពី​ ​ន​.​ទ​.​ក​.។",
    "qnaText9": "តើ​មាន​ប្រភេទ​ឈ្មោះ​ដែន​អ្វីខ្លះ​ដែល​អាច​ចុះឈ្មោះ​បាន​នៅ​ក្នុង​ព្រះរាជាណាចក្រកម្ពុជា​?",
    "qnaAnswer9": " អ្នកអាចចុះឈ្មោះដែនជាតិក្រោមដែនកម្រិតទី១ និងដែនកម្រិតទី២។ ការចុះឈ្មោះដែនជាតិក្រោមដែនកម្រិតទី១ មានពីរទម្រង់៖",
    "qnaAnswer9.1": "១-«ឈ្មោះដែលស្នើសុំ».kh  សម្រាប់បុគ្គលនៅក្នុងនិងក្រៅព្រះរាជាណាចក្រកម្ពុជា",
    "qnaAnswer9.2": "២-«ឈ្មោះដែលស្នើសុំ».ខ្មែរ ",
    "qnaAnswer9.3": "សម្រាប់បុគ្គលនៅក្នុងនិងក្រៅព្រះរាជាណាចក្រកម្ពុជាការចុះឈ្មោះដែនជាតិក្រោមដែនកម្រិតទី២មានទម្រង់ដូចខាងក្រោម៖",
    "qnaAnswer9.4": "១-«ឈ្មោះដែលស្នើសុំ».com.kh ជាប្រភេទដែនសម្រាប់សហគ្រាសពាណិជ្ជកម្ម ឬសហគ្រាសសាធារណៈ",
    "qnaAnswer9.5": "២-«ឈ្មោះដែលស្នើសុំ».org.kh ជាប្រភេទដែនសម្រាប់អង្គការ សមាគម និងសហជីព",
    "qnaAnswer9.6": "៣-«ឈ្មោះដែលស្នើសុំ».edu.kh ជាប្រភេទដែនសម្រាប់គ្រឹះស្ថានសិក្សារដ្ឋ និងឯកជន",
    "qnaAnswer9.7": "៤-«ឈ្មោះដែលស្នើសុំ».net.kh ជា​ប្រភេទ​ដែន​សម្រាប់​ក្រុមហ៊ុន​ឬ​ស្ថាប័ន​ផ្តល់​សេវា​បណ្តាញ​កុំព្យូទ័រ",
    "qnaText10": "​តើ​មាន​វិធីសាស្ត្រ​ក្នុង​ការ​ជ្រើសរើស​ឈ្មោះ​ដែន​ល្អ​ទេ​?",
    "qnaAnswer10":"​អ្នក​អាច​ពិចារណា​ចំណុច​ខាងក្រោម​៖​",
    "qnaAnswer10.1":"-​ឈ្មោះ​ដែន​ជាតិ​ដែល​ស្នើសុំ​គួរតែ​ខ្លី​និង​ងាយស្រួល​ចាំ​",
    "qnaAnswer10.2":"-​ឈ្មោះ​ដែន​ជាតិ​ដែល​ស្នើសុំ​ត្រូវ​ឆ្លុះបញ្ចាំង​ពី​អត្តសញ្ញាណ​របស់​អ្នក​ ​អាជីវកម្ម​របស់​អ្នក​ ផលិតផល​ឬ​សេវាកម្ម​របស់​អ្នក",
    "qnaAnswer10.3":"-​ជៀសវាង​ការ​ជ្រើសរើស​ឈ្មោះ​ផ្លូវការ​ ​ម៉ាក​ ​ឬ​ឈ្មោះ​ដែល​ហាមឃាត់​ ​ឬ​ពាក្យ​ផ្សេងទៀត​ដែល​ធ្វើ​ឱ្យ​សាធារណជន​យល់​ច្រឡំ",
    "qnaText11": "​តើ​ឯកសារ​អ្វី​ត្រូវការ​សម្រាប់​ការ​ចុះឈ្មោះ​ដែន​ ​co​m​.k​h​ ​ឬ​ ​net​.k​h​?",
    "qnaAnswer11":"​១​- ​ច្បាប់​ចម្លង​អត្ត​សញ្ញាប័ណ្ណ​សញ្ជាតិ​ខ្មែរ​ ​ឬ​លិខិតឆ្លងដែន​",
    "qnaAnswer11.1":"​២​- ច្បាប់​ចម្លង​វិញ្ញា​ប​ណ្ណ​ប​ត្រ​បញ្ជាក់​ការ​ចុះឈ្មោះ​ក្នុង​បញ្ជី​ពាណិជ្ជកម្ម​ ឬ​លិ​ខិតចេញ​ដោយ​ក្រសួង​ពាណិជ្ជកម្ម​អនុញ្ញាត​ឱ្យ​ប្រកប​អាជីវកម្ម​​",
    "qnaAnswer11.2":"​៣​- សម្រង់​ក្រុមហ៊ុន​",
    "qnaAnswer11.3":"​៤​- ច្បាប់​ចម្លង​វិញ្ញា​ប​ណ្ណ​ប​ត្រ​ចុះបញ្ជី​ម៉ាក​ (​ប្រសិនបើ​មាន​",
    "qnaText12": "តើឯកសារអ្វីត្រូវការសម្រាប់ការចុះឈ្មោះដែន edu.kh?",
    "qnaAnswer12": "១​- ​ច្បាប់​ចម្លង​អត្ត​សញ្ញាប័ណ្ណ​សញ្ជាតិ​ខ្មែរ​ ​ឬ​លិខិតឆ្លងដែន​",
    "qnaAnswer12.1": "២​- ច្បាប់​ចម្លង​អនុក្រឹត្យ​ ​ប្រកាស​ ​ឬ​លិ​ខិតចេញ​ដោយ​ក្រសួង​ពាណិជ្ជកម្ម​អនុញ្ញាត​ឱ្យ​បើ​គ្រឹះស្ថាន​អប់រំ​",
    "qnaAnswer12.2": "៣​- ​ច្បាប់​ចម្លង​វិញ្ញា​ប​ណ្ណ​ប​ត្រ​ចុះបញ្ជី​ម៉ាក​ (​ប្រសិនបើ​មាន​)",
    "qnaText13": "តើឯកសារអ្វីត្រូវការសម្រាប់ការចុះឈ្មោះដែន org.kh?",
    "qnaAnswer13": "១​- ​ច្បាប់​ចម្លង​អត្ត​សញ្ញាប័ណ្ណ​សញ្ជាតិ​ខ្មែរ​ ​ឬ​លិខិតឆ្លងដែន​",
    "qnaAnswer13.1": "២​- ច្បាប់​ចម្លង​លិខិតអនុញ្ញាត​ចេញ​ដោយ​ក្រសួងការបរទេស​និង​សហប្រតិបត្តិការ​អន្តរជាតិ​ ​ឬ​ក្រសួងមហាផ្ទៃ​សម្រាប់​អង្គការ​ឬ​សមាគម",
    "qnaAnswer13.2": "៣​- ​ច្បាប់​ចម្លង​វិញ្ញា​ប​ណ្ណ​ប​ត្រ​ចុះបញ្ជី​ម៉ាក​ (​ប្រសិនបើ​មាន​)",
    "qnaText14": "តើខ្ញុំអាចផ្លាស់ប្តូរឬកែឈ្មោះដែនរបស់ខ្ញុំ ក្រោយពេលចុះឈ្មោះរួច?",
    "qnaAnswer14": "​ក្រោយពេល​ចុះឈ្មោះ​រួច​ ​អ្នក​មិន​អាច​ផ្លាស់ប្តូរ​ឬ​កែ​ឈ្មោះ​ដែន​បានទេ​។​ ​អ្នក​ត្រូវ​ចុះឈ្មោះ​ដែន​ថ្មី​ប្រសិន​អ្នក​ចង់បាន​ឈ្មោះ​ដែន​ផ្សេង​។",
    "qnaText15": "​ក្រោយ​ពី​ចុះឈ្មោះ​ដែន​ជាមួយ​និយ័ត​ករ​ទូរគមនាគមន៍​កម្ពុជា​ ​និយ័ត​ករ​ទូរគមនាគមន៍​ ​កម្ពុជា​នឹង​ផ្តល់​ការ​បង្ហោះ​គេហទំព័រ​សម្រាប់​ខ្ញុំ​ដែរ​ទេ​?",
    "qnaAnswer15": "​ជាទូទៅ​សាធារណជន​តែង​យល់​ច្រឡំ​ថា​ការ​ចុះឈ្មោះ​ដែន​នឹង​មាន​រួម​ប​ញ្ជូ​ល​ទាំង​ការ​បង្ហោះ​គេហទំព័រ​ផង​ដែរ​។​ ​តាម​ជាក់ស្តែង​សេវា​ចុះឈ្មោះ​ដែន​និង​សេវា​បង្ហោះ​គេហទំព័រ​ ​ជា​សេវា​ពីរ​ផ្សេង​ដាច់ដោយឡែក​ពី​គ្នា​។​ ​និយ័ត​ករ​ទូរគមនាគមន៍​កម្ពុជា​ផ្តល់​សេវា​ពាក់ព័ន្ធ​នឹង​ការ​ចុះឈ្មោះ​ដែន​តែប៉ុណ្ណោះ​។",
    "qnaText16": "​តើ​សេវា​ក​ម្រៃ​ប្រើប្រាស់​ឈ្មោះ​ដែន​ប្រចាំ​ឆ្នាំ​ថ្លៃ​ប៉ុន្មាន​?",
    "qnaAnswer16": "​សេវា​ក​ម្រៃ​ប្រើប្រាស់​ឈ្មោះ​ដែន​ប្រចាំ​ឆ្នាំ​គិតចាប់ ​១​២​០​.​០​០​០​រៀល​។​ ​តម្លៃ​នេះ​មិនទាន់​គិត​រួមបញ្ចូល​នូវ​អាករ​លើ​តម្លៃ​បន្ថែម​(​VAT​)​។",
    "qnaText17": "​តើ​មាន​អ្វី​កើតឡើង​នៅពេលដែល​ខ្ញុំ​មិនបាន​បន្ត​សុពលភាព​ឈ្មោះ​ដែន​បន្ទាប់ពី​ថ្ងៃ​អស់​សុពលភាព​?",
    "qnaAnswer17": "​ឈ្មោះ​ដែន​របស់​អ្នក​នឹង​ត្រូវ​បាន​ផ្អាក​ជា​បណ្តោះអាសន្ន​។​ ​ដើម្បី​ឱ្យ​ឈ្មោះ​ដែន​នេះ​អាច​ដំណើរការ​បាន​ ​ម្ចាស់​ឈ្មោះ​ដែន​ត្រូវ​បង់ក​ម្រៃ​សេវា​ប្រើប្រាស់​ឈ្មោះ​ដែន​ប្រចាំ​ឆ្នាំ​ ​ក្នុង​អំឡុង​ពេល​ ​៦​០​ថ្ងៃ​ ​ក្រោយ​ពី​ថ្ងៃ​ផុត​សុពលភាព​។​ ​ប្រសិនបើ​អ្នក​មិនបាន​បង់​ក្នុង​អំឡុង​ពេលនេះ​ទេ​ ​នោះ​ឈ្មោះ​ដែន​នេះ​នឹង​ត្រូវ​លុបចេញ​ដោយស្វ័យប្រវត្តិ​ ​ហើយនឹង​ត្រូវ​ដាក់​ឱ្យ​ទំនេរ​សម្រាប់​សាធារណជន​ចុះឈ្មោះ​ប្រើប្រាស់​។",
    "qnaText18": "​ខ្ញុំ​ភ្លេច​បង់ក​ម្រៃ​សេវា​ប្រើប្រាស់​ប្រចាំ​ឆ្នាំ​ ​ហើយ​ឈ្មោះ​ដែន​របស់​ខ្ញុំ​ត្រូវ​បាន​លុបចេញ​។​ ​តើ​ខ្ញុំ​ត្រូវធ្វើ​ដូច​មេ្ត​ច​ដើម្បី​បាន​ឈ្មោះ​ដែន​នោះ​មកវិញ​?",
    "qnaAnswer18": "​ឈ្មោះ​ដែន​ដែល​ត្រូវ​បាន​លុបចេញ​នឹង​ត្រូវ​ដាក់​ឱ្យ​ស្ថិត​ក្នុង​ស្ថានភាព​ទំនេរ​ដែល​អនុញ្ញាត​ឱ្យ​សាធារណជន​ចុះឈ្មោះ​ប្រើប្រាស់​បាន​។​ ​ក្នុង​ករណី​ដែល​អ្នក​ចង់បាន​ឈ្មោះ​ដែន​នោះ​វិញ​អ្នក​ត្រូវ​ចុះឈ្មោះ​ប្រើប្រាស់​ឈ្មោះ​ដែន​នោះ​សារ​ជាថ្មី​។",
    "qnaText19": "​តើកា​រ​ចុះឈ្មោះ​ដែន​ជាតិ​ត្រូវធ្វើ​ឡើង​តាម​អ​ទិ​ភាព​ដែរ​ឬទេ​?",
    "qnaAnswer19": "​ការ​ចុះឈ្មោះ​ដែន​ជាតិ​ត្រូវ​អនុវត្ត​តាម​គោលការណ៍​អ​ទិ​ភាព​នៃ​ការ​ដាក់ពាក្យ​ស្នើសុំ​។",
    "qnaText20": "ឈ្មោះ​ដែន​របស់​ខ្ញុំ​ត្រូវ​បាន​ផ្អាក​បណ្តោះអាសន្ន​ដោយសារ​ផុត​សុពលភាព​។​ ​តើ​ខ្ញុំ​ត្រូវការ​រយៈពេល​ប៉ុន្មាន​ដើម្បី​ឱ្យ​ឈ្មោះ​ដែន​អាច​ដំណើរការ​ឡើងវិញ​ក្រោយពេល​ខ្ញុំ​បាន​បង់ក​ម្រៃ​សេវា​ប្រើប្រាស់​ឈ្មោះ​ដែន​ប្រចាំ​ឆ្នាំ​រួច​?",
    "qnaAnswer20": "​ឈ្មោះ​ដែន​របស់​អ្នក​នឹង​ត្រូវ​ភ្ជាប់​ឱ្យ​ដំណើរការ​ឡើងវិញ​ ​ក្នុងរយៈពេល​២​៤​ម៉ោង​ ​បន្ទាប់ពី​អ្នក​បាន​បង់ក​ម្រៃ​សេវា​ប្រើប្រាស់​ឈ្មោះ​ដែន​ប្រចាំ​ឆ្នាំ​រួច​។",
    "qnaText21": "​តើ​ខ្ញុំ​អាច​ទទួល​បាន​សំណង​ឬ​ប្រាក់​ត្រលប់​មកវិញ​ទេ​ ​ករណី​ដែល​ឈ្មោះ​ដែន​ដែល​ខ្ញុំ​ស្នើសុំ​ចុះឈ្មោះ​ដែន​ត្រូវ​បាន​បដិសេធ​ឬ​ខ្ញុំ​សុំ​ឈប់​ប្រើប្រាស់​ឈ្មោះ​ដែន​នោះ​មុន​ពេល​ផុត​សុពលភាព​?",
    "qnaAnswer21": "​ទេ​អ្នក​នឹង​មិន​ទទួល​បាន​ក​ម្រៃ ​ឬ​សំណង​ត្រលប់​មកវិញ​ទេ​។​ ​យោង​លើ​បទ​បញ្ញា​ត្តិ​នៃ​ការ​ចុះឈ្មោះ​ដែន​ ​រាល់​ក​ម្រៃ​ដែល​បាន​បង់​សម្រាប់​ការប្រើប្រាស់​ឈ្មោះ​ដែន​នឹង​មិន​ត្រូវ​បាន​សង​ឬ​ទូទាត់ប្រាក់​ត្រលប់​ទៅវិញ​ទេ​។",
    "qnaText22": "​តើ​ខ្ញុំ​អាច​លក់​ ​ឬ​ជួល​អនុ​ដែន​ទៅ​ឱ្យ​អ្នកដទៃ​បានទេ​?",
    "qnaAnswer22": "​អ្នក​មិន​អនុញ្ញាត​ឱ្យ​លក់​ឬ​ជួល​អនុ​ដែន​ទៅ​ឱ្យ​អ្នកដទៃ​បាន​ឡើយ​។​ ​ប៉ុន្តែ​អ្នក​អាច​បង្កើត​អនុ​ដែន​សម្រាប់​ប្រើប្រាស់​តាម​តម្រូវ​ការ​របស់​ក្រុមហ៊ុន​របស់​អ្នក​។",
    "qnaText23": "​ហេតុអ្វីបានជា​ព័ត៌មាន​ដែល​ខ្ញុំ​បាន​បំពេញ​ត្រូវ​បាន​បង្ហាញ​ជា​សាធារណៈ​?",
    "qnaAnswer23": "​នៅពេលដែល​អ្នក​ចុះឈ្មោះ​ប្រើប្រាស់​ឈ្មោះ​ដែន​អ្នក​បាន​ឯកភាព​ក្នុង​ការ​ផ្តល់​នូវ​ព័ត៌មាន​ដែល​អ្នក​បាន​បំពេញ​ក្នុង​ពេល​ស្នើសុំ​ចុះឈ្មោះ​ដែន​ដាក់​បង្ហាញ​ជា​សាធារណៈ​នៅ​មូល​ទិន្នន័យ​សម្រាប់​ដំណើរការ​វិធីការ​ព័ត៌​មានឈ្មោះ​ដែន​ជាតិ​ (​WHO​I​S ​D​at​abase​) ​ព័ត៌មាន​នេះ​ដាក់​តាំង​បង្ហាញ​ដើម្បី​អនុញ្ញាត​ឱ្យ​សាធារណជន​ដឹង​អំពី​ស្ថានភាព​នៃ​ឈ្មោះ​ដែន​របស់​អ្នក​ឬ​ទាក់ទង​ទៅ​អ្នក​ពាក់ព័ន្ធ​បញ្ហា​បច្ចេកទេស​ផ្សេងៗ​។",
    "qnaText24": "​តើ​ខ្ញុំ​នឹង​ត្រូវ​បាន​ផ្តល់​ដំណឹង​ទេ​ ​មុន​ពេល​ដែល​ឈ្មោះ​ដែន​របស់​ខ្ញុំ​អស់​សុពលភាព​?",
    "qnaAnswer24": "​បាទ​/​ចាស​ ​អ្នក​នឹង​ទទួល​បាន​អុី​មែ​ល​ជូនដំណឹង​ ​មុន​ពេល​ដែល​ឈ្មោះ​ដែន​របស់​អ្នក​អស់​សុពលភាព​។​ ​សូម​ធ្វើតាម​ការណែនាំ​ក្នុង​ការ​បន្ត​សុពលភាព​ឈ្មោះ​ដែន​របស់​អ្នក​។​",
    "qnaText25": "​តើ​មានឈ្មោះ​ដែន​ដែល​រក្សាទុក​ ​ឬ​ឈ្មោះ​ដែន​ដែល​ហាមឃាត់​ទេ​?",
    "qnaAnswer25": "បាទ/ចាស មាន",
    "qnaText26": "​តើ​មានឈ្មោះ​ដែន​ដែល​មាន​លក្ខណៈ​ពិសេស​ទេ​?",
    "qnaAnswer26": "បាទ​/​ចាស​ ​មាន​។​ ​ឈ្មោះ​ដែន​ដែល​មាន​លក្ខណៈ​ពិ​សេ​សត្រូវ​រក្សាទុក​ជា​បណ្តោះអាសន្ន​និង​ដាក់​ឱ្យ​ចុះឈ្មោះ​នៅ​ពេល​សមរម្យ​ណាមួយ​។​",
    "qnaText27": "​តើ​ខ្ញុំ​អាច​ផ្ទេរ​ឈ្មោះ​ដែន​របស់​ខ្ញុំ​ទៅ​បុគ្គល​ផ្សេង​បានទេ​?",
    "qnaAnswer27": "​បាទ​/​ចាស​ ​អ្នក​អាច​ផ្ទេរ​ឈ្មោះ​ដែន​របស់​ខ្ញុំ​ទៅ​បុគ្គល​ផ្សេង​បាន​ ​ប៉ុន្តែ​ត្រូវ​គោរព​តាម​លក្ខខណ្ឌ​ ​និង​ទទួល​បាន​អនុញ្ញាត​ពី​និយ័ត​ករ​ទូរគមនាគមន៍​កម្ពុជា​។",
    "qnaText28": "​តើ​មានការ​យន្ត​ការ​ដោះស្រាយ​វិវាទ​ពាក់ព័ន្ធ​នឹង​ឈ្មោះ​ដែន​ជាតិ​ទេ​?",
    "qnaAnswer28": "​បាទ​/​ចាស​ ​មាន​។​ ​និយ័ត​ករ​ទូរគមនាគមន៍​កម្ពុជា​ជា​អង្គភាព​ផ្សះផ្សា​រ​វិវាទ​ពាក់ព័ន្ធ​នឹង​ឈ្មោះ​ដែន​ជាតិ​។",
    "RegisterText": "ចុះ​ឈ្មោះ",
    "RenewDomainText": "បន្តប្រើប្រាស់ដែន",
    "PayDomainText": "បង់ប្រាក់",
    "PrivacyPolicyText": "គោលការណ៍ឯកជន",
    "RegisterDomain": "ចុះឈ្មោះដែនជាតិ",
    "RequesterInformation": "ព័ត៌មានម្ចាស់ឈ្មោះដែនជាតិ",
    "RequesterText": "ម្ចាស់ឈ្មោះដែនជាតិ",
    "ResetPasswordText": "ផ្លាស់ប្តូរពាក្យសម្ងាត់",
    "ResubmitYourDocument": "បំពេញឯកសាររបស់អ្នកម្តងទៀត",
    "SameAsDelegateManagementAndAdministrationToOfficer1": "ដូចអ្នកប្រគល់សិទ្ធិគ្រប់គ្រង និង ចាត់ចែងជូនមន្រ្តីទី១",
    "SameAsRequesterInfo": "ដូចគ្នានឹងព័ត៌មានម្ចាស់ឈ្មោះដែនជាតិ",
    "SameAsWebmasterInformationText": "ដួចព័ត៌មានអ្នកគ្រប់គ្រង់គេហទំព័រ",
    "Save": "រក្សាទុក",
    "SearchDomainNameText": "ស្វែងរកឈ្មោះដែនជាតិ",
    "SearchYourNextDomainNameText": "ស្វែងរកឈ្មោះដែនជាតិបន្ទាប់របស់អ្នក",
    "searchDomainText": "ស្វែងរកដែន",
    "searchExampleText": "ឧទាហរណ៍ អ្នកអាចស្វែងរក “mycoffeeshop.kh”",
    "BackHome": "ទំព័រដើម",
    "SearchText": "ស្វែងរក",
    "SelectSexText": "--ជ្រើសរើសភេទ--",
    "ServerInfoTitle": "ព័ត៌មានអំពីសេវូបករណ៍",
    "SexRequireText": "ភេទគឺតម្រូវឱ្យមាន។",
    "SexText": "ភេទ",
    "SendEmailAlert": "ពួកយើងនឹងផ្ញើអ៊ីម៉ែលជាការជូនដំណឹងរាពេលបន្តិចទៀតនេះ !",
    "SignInEmail": "អុីម៉ែលប្រើសម្រាប់ចូលគណនី",
    "Submit": "ដាក់ស្នើ",
    "SubTotalText": "សរុបរង",
    "SuggestDomainsText": "ជម្រើសឈ្មោះដែនជាតិដទៃទៀត",
    "SummaryText": "សេចក្ដីសង្ខេប",
    "SummaryConfirm": "តើអ្នកប្រាកដថាបញ្ជាក់ទម្រង់នេះ?",
    "TechincalInformationText": "ព័ត៌មានអ្នក​ប​ច្ចេ​ក​ទេស",
    "TermAndConditionDotText": "លក្ខខណ្ឌនៃការប្រើប្រាស់",
    "TermsandPrivacyPolicyText": "លក្ខខ័ណ្ណនិងគោលការណ៍ភាពឯកជន",
    "ThankPurchased": "អរគុណសម្រាប់ការទិញ",
    "DownloadTitlePage": "ទាញយកឯកសារ",
    "DownloadNumber": "លេខរៀង",
    "DocFormat": "ទម្រង់ឯកសារ",
    "DocType": "ប្រភេទឯកសារ",
    "DocDownload": "ទាញយក",
    "DocMonthlyTax": "លិខិតប្រកាសពន្ធប្រចាំខែ",
    "DocGuideline": "គោលការណ៍ណែនាំ",
    "DocGuidelineDownload": "គោលការណ៍ណែនាំសម្រាប់តម្រូវការឯកសារ",
    "DomainDocument": "ឯកសារដែនជាតិ ",
    "DocFor": "ឯកសារសម្រាប់ស្នើសុំ",
    "ThankYouForPurchaseText": "អរគុណសម្រាប់ការទិញ",
    "ThankYouForRenewText": "អរគុណសម្រាប់ការបន្តដែន",
    "ThisDomainIsNotBelongToYouText": "ដែននេះមិនមែនជារបស់អ្នកទេ។",
    "ThisDomainNotYetUploadDocumentText": "ដែននេះមិនទាន់បានបញ្ចូលឯកសារនៅឡើយទេ",
    "ToPurchaseForUsagePleaseUpload": "ដើម្បីទិញ “{{domain}}” សូម Upload ឯកសាររបស់អ្នក (pdf) ទំហំអតិបរិមា ១០មេហ្កាបៃ",
    "Total": "សរុប",
    "TotalText": "សរុប",
    "TRCAddressText": "ផ្លូវ ១០២ សង្កាត់វត្តភ្នំ ខណ្ឌដូនពេញ រាជធានីភ្នំពេញ",
    "Hotline": "ខ្សែទូរស័ព្ទបន្ទាន់:",
    "Telephone": "ទូរស័ព្ទ:",
    "FaxText": "ទូរសារ",
    "UntilText": "រហូតដល់",
    "Update": "កែប្រែ",
    "update-your-domian-dns": "ធ្វើបច្ចុប្បន្នភាព​ដែន",
    "UploadFileText": "ទាញនិងទម្លាក់ឯកសារឬជ្រើសរើសឯកសារ",
    "vat-10": "អាករលើតម្លៃបន្ថែម",
    "feeOnBank": "អនុវត្តតាមធនាគារ",
    "ViewAllMyDomainText": "បង្ហាញដែនរបស់ខ្ញុំទាំងអស់",
    "ViewMyDomainText": "ចូលដែនរបស់ខ្ញុំ",
    "WebmasterInformationText": "ព័ត៌មានអ្នកគ្រប់គ្រង់គេហទំព័រ",
    "WebSiteText": "គេហទំព័រ",
    "Welcome": "សូមស្វាគមន៍",
    "withText": "អ៊ីនធឺណិតជាមួយដែនជាតិ",
    "WokringFromDayToDayText": "ច័ន្ទ - សុក្រ",
    "WorkingFromAfternoonTimeToAfternoonTimeText": "ម៉ោង ១៤ - ១៧ ល្ងាច",
    "WorkingFromMorningTimeToMoringTimeText": "ម៉ោង ០៨ - ១២ ព្រឹក",
    "WorkingHourText": "ម៉ោងធ្វើការ",
    "WrongFIleTypeText": "ទម្រង់ឯកសារមិនត្រឹមត្រូវ",
    "From":"ចាប់ពី",
    "Year": "ឆ្នាំ",
    "YearSubscriptionText": "ការជាវរយះពេល{0}ឆ្នាំ",
    "YouCanUseOnlyOneDomainText": "អ្នកអាចប្រើបានតែដែន .kh មួយទេ។",
    "YourDocumentHere": "ឯកសាររបស់អ្នក (jpg, png ឬ pdf) នៅទីនេះឬក៏",
    "YouDontHavePermission": "អ្នកមិនមានការអនុញ្ញាតទេ",
    "MyDomainDescription": "អ្នកអាចធ្វើបច្ចុប្បន្នភាពព័ត៌មានលម្អិតរបស់ដែន និងព័ត៌មានទាក់ទងដែនរបស់អ្នក។",
    "MyDomainText": "ដែនជាតិរបស់ខ្ញុំ",
    "MyCart": "My Cart",
    "YourOrderSummaryText": "សេចក្ដីសង្ខេបការបញ្ជាទិញរបស់អ្នក",
    "YourProfileInformationIsNotFillInText": "ព័ត៌មានផ្ទាល់ខ្លួនរបស់អ្នកមិនត្រូវបានបំពេញទេ។",
    "ipaddressserver1requiretext": "​IP Address របស់សេវូបករណ៍ ១ គឺតម្រូវឱ្យមាន។",
    "ipaddressserver1text": "​IP Address របស់សេវូបករណ៍ ១",
    "ipaddressserver2requiretext": "​IP Address របស់សេវូបករណ៍ ២ គឺតម្រូវឱ្យមាន។",
    "ipaddressserver2text": "​IP Address របស់សេវូបករណ៍​​ ២",
    "ipaddressserverrequiretext": "​IP Address របស់សេវូបករណ៍ គឺតម្រូវឱ្យមាន។",
    "ipaddressservertext": "អាសយដ្ឋាន ​IP Address សេវូបករណ៍",
    "AgreeToAgreementText": "សូមធ្វើការយល់ព្រម",
    "PleaseUploadOfOneBelowFile": "សូមបញ្ចូលឯកសារពាក់ព័ន្ធដូចខាងក្រោម ។",
    "Institutional": "នីតិបុគ្គល",
    "InstitutionType": "ប្រភេទនីតិបុគ្គល",
    "String1": "",
    "BankFee": "តម្លៃសេវាធនាគារ",
    "ExpiredSince": "អស់សុពលភាពតាំងពី",
    "WillExpiredOn": "នឹងអស់សុពលភាពនៅ",
    "ExampleText": "ឧទាហរណ៍ :",
    "WhoIsSearchText": "ស្វែងរកវិធីការព័ត៌មានឈ្មោះដែនជាតិ",
    "WhoIsText": "វិធីការព័ត៌មានឈ្មោះដែនជាតិ",
    "MyCartDescription": "ឈ្មោះដែនជាតិដែលនៅក្នុងកន្ត្រកនេះមិនមែនជាកម្មសិទ្ធិអ្នកនៅឡើយទេ។​​",
    "MyCartDescription1": "វានឹងក្លាយជាកម្មសិទ្ធិអ្នកនៅពេលអ្នកជាវរួចរាល់។",
    "MyCartText": "កន្ត្រកដែនជាតិ",
    "DeleteDomainButton": "លុបដែន",
    "domainsinfosubtext": "ស្វែងរកវិធីការព័ត៌មានឈ្មោះដែនជាតិក្នុងមូលទិន្នន័យ ដើម្បីយល់ដឹងបន្ថែមអំពីភាពជាកម្មសិទ្ធិលើដែនជាតិ .kh ។",
    "domainsinfotext": "ព័ត៌មានដែន",
    "ExpirationDateDescriptionText": "ដឹងអំពីកាលបរិច្ឆេទនៃការផុតកំណត់ដែន ដែលអាចជួយអ្នកធ្វើការជាវងាយស្រួលជាងមុន ។",
    "ExpirationDateText": "កាលបរិច្ឆេទផុតកំណត់",
    "findoutdomain-sownershiptext": "ស្វែងរកម្ចាស់កម្មសិទ្ធិដែនជាតិ",
    "FindTheOwnerText": "ធ្វើការស្វែងរកម្ចាស់កម្មសិទ្ធិដែន ដែលអ្នកចង់ទិញបន្ត។",
    "WhoIsTheOwnerText": "តើនរណាជាម្ចាស់កម្មសិទ្ធិ?",
    "RemovedText": "បានលុប",
    "FinishText": "រួចរាល់",
    "CancelText": "បោះបង់",
    "ActionButton": "សកម្មភាព",
    "InReviewText": "កំពុងត្រួតពិនិត្យ",
    "ExpiredText": "ផុតកំណត់",
    "AreYouSureWantToDelThisText": "តើអ្នកពិតជាចង់លុបដែននេះមែនឬទេ?",
    "ConfirmText": "យល់ព្រម",
    "Years": "ឆ្នាំ",
    "DocumentFeeText": "តម្លៃសេវាឯកសារ",
    "DomainFee": "តម្លៃដែន",
    "FindOutDomain’sOwnershipText": "ស្វែងរកម្ចាស់កម្មសិទ្ធិដែនជាតិ",
    "Domain'sInfoText": "ព័ត៌មានដែន",
    "Domain'sInfoSubText": "ស្វែងរកវិធីការព័ត៌មានឈ្មោះដែនជាតិក្នុងមូលទិន្នន័យ ដើម្បីយល់ដឹងបន្ថែមអំពីភាពជាកម្មសិទ្ធិលើដែនជាតិ .kh ។",
    
    "ResubmitYourDocumentText": "បំពេញឯកសាររបស់អ្នកម្តងទៀត",    
    "PleaseFillInYourProfileInformationText": "សូមបំពេញព័ត៌មានផ្ទាល់ខ្លួនរបស់អ្នកដើម្បីចាប់ផ្ដើមប្រើប្រាស់ប្រព័ន្ធ",
    "DocumentStatus2": "រង់ចាំការស្នើឯកសារឡើងវិញ",
    "DocumentStatus3": "បានអនុម័តលើឯកសារ",
    "DocumentStatus4": "បានលុប",
    "DocumentStatus5": "រង់ចាំការអនុម័តលើឯកសារ",
    "DocumentStatus6": "បានបិទដំណើរការ",
    "DocumentStatus7": "បានបដិសេធ",
    "DocumentStatus8": "រង់ចាំអ្នក",
    "Document1": "ច្បាប់ចម្លងអត្តសញ្ញាណបណ្ណសញ្ជាតិខ្មែរ ឬ លិខិតឆ្លងដែន (ក្នុងករណីអ្នកស្នើសុំជាជនបរទេស)",
    "Document2": "ច្បាប់ចម្លងវិញ្ញាបនបត្រចុះបញ្ជីម៉ាក (Trademark) ប្រសិនមាន",
    "Document3": "ច្បាប់ចម្លងសម្រង់ក្រុមហ៊ុន(Company Extract)",
    "Document4": "ច្បាប់ចម្លងវិញ្ញាបនបត្របញ្ជាក់ការចុះឈ្មោះក្នុងបញ្ជីពាណិជ្ជកម្ម",
    "Document5": "ច្បាប់ចម្លងលិខិតអនុញ្ញាតដែលចេញដោយក្រសួងការបរទេស និងសហប្រតិបត្តិការអន្តរជាតិ សម្រាប់អង្គការក្រៅរដ្ឋាភិបាល",
    "Document6": "ច្បាប់ចម្លងនៃអនុក្រឹត្យបង្កើតគ្រឹះស្ថានសិក្សាដែលចេញដោយរាជរដ្ឋាភិបាល ឬលិខិតអនុញ្ញាតឱ្យ បើកសាលាដែលចេញដោយក្រសួងអប់រំ យុវជន និងកីឡា",
    "GovDocument1":"លិខិតស្នើសុំជាផ្លូវការពីស្ថាប័នសាមី",
    "GovDocument2":"លិខិតបញ្ជាក់ការប្រគល់សិទ្ធិកាន់កាប់គេហទំព័រ និងភ្ជាប់ជាមួយនូវច្បាប់ចម្លងអត្តសញ្ញាណបណ្ណ សញ្ជាតិខ្មែរ ឬ បណ្ណមន្ត្រីរាជការរបស់អ្នកទទួលសិទ្ធិ ",
    "GovDomainLimitText":"សូមអភ័យទោស បច្ចុប្បន្នគណនីរាជរដ្ឋាភិបាលអនុញ្ញាតិបានត្រឹមដែនមួយប៉ុណ្ណោះ",
    "ProfileUpdatedText":"ព័ត៌មានផ្ទាល់ខ្លួនរបស់អ្នកបានធ្វើបច្ចុប្បន្នភាពដោយជោគជ័យ",

    "RequiredDocumentText": "ឯកសារតម្រូវ",
    "RequiredDocumentDescriptionText": "សេវាចុះឈ្មោះដែនជាតិ ផ្តល់ការគ្រប់គ្រងប្រព័ន្ធ និងសេវាឈ្មោះដែន .kh (Domain Name System) នៅលើបណ្តាញអ៊ិនធឺណេតឱ្យមានប្រសិទ្ធភាពតាមបទដ្ឋានអន្តរជាតិ និងបើកទូលាយឱ្យមានការប្រើប្រាស់គេហទំព័រជាតិជូនដល់ ក្រសួង/ស្ថាប័នរដ្ឋ អង្គការ ក្រុមហ៊ុន និងប្រជាពលរដ្ឋទូទៅ។ ជ្រើសរើសឈ្មោះដែនជាតិដែលអ្នកពេញចិត្ត និងត្រឹមត្រូវសម្រាប់លោកអ្នក។",
    "RequiredDocumentGuidelineTitleText": "សេចក្តីណែនាំ ស្តីពី ការស្នើសុំលិខិតអនុញ្ញាត្ត ចុះឈ្មោះគេហទំព័រ .kh",
    "RequiredDocumentLinkText": "ចុចតំណខាងក្រោម ដើម្បីទាញយកឯកសារ",

    "RequiredDocumentKhTitleText": "សម្រាប់​គេហទំព័រ​​ ​រូបវន្តបុគ្គល​ និងនីតិបុគ្គល",    
    "RequiredDocumentKhDocument1": "ច្បាប់ចម្លងអត្តសញ្ញាណបណ្ណសញ្ជាតិខ្មែរ",

    "RequiredDocumentComTitleText": "សម្រាប់គេហទំព័រ សហគ្រាសពាណិជ្ជកម្ម សហគ្រាសសាធារណៈ និង ក្រុមហ៊ុន",
    "RequiredDocumentComDocument1": "ច្បាប់ចម្លងវិញ្ញាបនបត្របញ្ជាក់ការចុះឈ្មោះក្នុង បញ្ជីពាណិជ្ជកម្ម",
    "RequiredDocumentComDocument2": "ច្បាប់ចម្លងសម្រង់ក្រុមហ៊ុន",
    "RequiredDocumentComDocument3": "ច្បាប់ចម្លងអត្តសញ្ញាណបណ្ណសញ្ជាតិខ្មែរ ឬលិខិតឆ្លងដែនរបស់អ្នកស្នើសុំ",
    "RequiredDocumentComDocument4": "ច្បាប់ចម្លងវិញ្ញាបនបត្រចុះបញ្ជីម៉ាក (ប្រសិនបើមាន)",

    "RequiredDocumentNetTitleText": "សម្រាប់​គេហទំព័រ​​ ស្ថាប័ន​ផ្តល់សេវា ​បណ្តាញ​កុំព្យូទ័រ",
    "RequiredDocumentNetDocument1": "ច្បាប់ចម្លងវិញ្ញាបនបត្របញ្ជាក់ការចុះឈ្មោះក្នុង បញ្ជីពាណិជ្ជកម្ម",
    "RequiredDocumentNetDocument2": "ច្បាប់ចម្លងសម្រង់ក្រុមហ៊ុន",
    "RequiredDocumentNetDocument3": "ច្បាប់ចម្លងអត្តសញ្ញាណបណ្ណសញ្ជាតិខ្មែរ ឬលិខិតឆ្លងដែនរបស់អ្នកស្នើសុំ",
    "RequiredDocumentNetDocument4": "ច្បាប់ចម្លងវិញ្ញាបនបត្រចុះបញ្ជីម៉ាក (ប្រសិនបើមាន)",

    "RequiredDocumentEduTitleText": "សម្រាប់​គេហទំព័រ​​ ​គ្រឹះស្ថាន​អប់រំ",
    "RequiredDocumentEduDocument1": "ច្បាប់ចម្លងនៃអនុក្រឹត្យបង្កើតឬទទួលស្គាល់ សម្រាប់សកលវិទ្យាល័យ ឬប្រកាសឬលិខិតអនុញ្ញត ដែលចេញដោយក្រសួងអប់រំ យុវជន និងកីឡា សម្រាប់គ្រឺះស្ថានអប់រំផ្សេងទៀត",
    "RequiredDocumentEduDocument2": "ច្បាប់ចម្លងអត្តសញ្ញាណបណ្ណសញ្ជាតិខ្មែរ ឬលិខិតឆ្លងដែនរបស់អ្នកស្នើសុំ",

    "RequiredDocumentOrgTitleText": "សម្រាប់គេហទំព័រ​​ ​អង្គការ​ ​សមាគម​ ​និង​សហ​ជីព",
    "RequiredDocumentOrgDocument1": "ច្បាប់ចម្លងលិខិតអនុញ្ញាតដែលចេញដោយ ក្រសួងការបរទេស និងសហប្រតិបត្តិការអន្ដរជាតិសម្រាប់សមាគម ឬអង្គការមិនមែនរដ្ឋាភិបាល",
    "RequiredDocumentOrgDocument2": "ច្បាប់ចម្លងលិខិតអនុញ្ញាតដែលចេញដោយ ក្រសួងមហាផ្ទៃសម្រាប់សមាគម និងអង្គការមិនមែនរដ្ឋាភិបាលក្នុងស្រុក",
    "RequiredDocumentOrgDocument3": "ច្បាប់ចម្លងលិខិតអនុញ្ញាតដែលចេញដោយ ក្រសួងការងារនិងបណ្តុះបណ្តាលវិជ្ជាជីវៈសម្រាប់ សហជីព ឬសមាគមនិយោជក (ប្រសិនបើមាន)",
    "RequiredDocumentOrgDocument4": "ច្បាប់ចម្លងអត្តសញ្ញាណបណ្ណសញ្ជាតិខ្មែរ ឬលិខិតឆ្លងដែនរបស់អ្នកស្នើសុំ",

    "RequiredDocumentGovTitleText": "សម្រាប់គេហទំព័រ រាជរដ្ឋាភិបាល",

    "AboutTitleText": "ឈ្មោះដែនជាតិ.kh",
    "AboutDescriptionText": "គឺជាដែនកម្រិតទី១តាមកូដប្រទេស (ccTLD) សម្រាប់ ប្រទេសកម្ពុជាដែលត្រូវបានទទួលស្គាល់ដោយសាជីវកម្មអ៊ីនធឺណិត សម្រាប់ផ្តល់ឈ្មោះនិងលេខ (Internet Corporation for Assigned Names and Numbers - ICANN)។",

    "AboutValue1Title": "ចក្ខុវិស័យ",
    "AboutValue1Description": "លើកកម្ពស់អត្តសញ្ញាណ តម្លៃ និងអត្ថិភាពនៃដែនជាតិនៅក្នុងប្រព័ន្ធអ៊ីនធឺណិត។",

    "AboutValue2Title": "បេសកកម្ម",
    "AboutValue2Description": "រៀបចំបទបញ្ញត្តិ និងយន្តការនៃការគ្រប់គ្រងនិងប្រើប្រាស់ ឈ្មោះដែនជាតិឱ្យមានភាពងាយស្រួលដើម្បីជំរុញការ ប្រើប្រាស់ឈ្មោះដែនជាតិឱ្យបានទូលំទូលាយនិងធានាការផ្តល់សេវាសាធារណៈប្រកបដោយតម្លាភាព និងគ្មានការរើសអើង។",

    "AboutValue3Title": "គុណតម្លៃ",
    "AboutValue3Subtitle1": "គុណភាពនៃសេវាកម្ម",
    "AboutValue3Subtitle1Description": "យើងពង្រឹងគុណភាពសេវាដើម្បីធានាថារាល់បញ្ហាទាំងអស់របស់អតិថិជនត្រូវបានដោះសា្រយទាន់ពេលវេលា មិនមានការរើសអើង។",    
    "AboutValue3Subtitle2": "សុច្ចរិតភាព",
    "AboutValue3Subtitle2Description": "យើងផ្តល់តម្លៃខ្ពស់ដល់ការផ្តល់សេវាជូន អតិថិជនប្រកបដោយសេចក្តីថ្លៃថ្នូរនិងការគោរពគ្នាទៅវិញទៅមក។រាល់ការប្រាស្រ័យទាក់ទងជាមួយ អតិថិជន និងការអនុវត្តការងារ ត្រូវគោរពទៅតាមស្តង់ដារក្រមសីលធម៌។",    
    "AboutValue3Subtitle3": "ទំនុកចិត្ត",
    "AboutValue3Subtitle3Description": "យើងធានាថារាល់សេវាទាំងអស់ដែលផ្តល់ជូន អតិថិជនត្រូវប្រកបដោយតម្លាភាព និងវិជ្ជាជីវៈ នៃការងារ។",    

    "AboutWhatWeDoTitle": "អ្វីដែលយើងធ្វើ",
    "AboutWhatWeDoDescription": "ការចុះឈ្មោះដែនជាតិ .kh ត្រូវចែកចេញជាពីរកម្រិត៖ កម្រិតទី១ និងកម្រិតទី២។ដែនជាតិកម្រិតទី១ត្រូវបានដាក់ឱ្យប្រើប្រាស់ សម្រាប់បុគ្គល ក្នុងនិងក្រៅប្រទេសនៃព្រះរាជាណាចក្រកម្ពុជា។ ដែនជាតិកម្រិតទី២ត្រូវបានដាក់ឱ្យប្រើប្រាស់សម្រាប់បុគ្គលដែល បង្កើតឬចុះបញ្ជីស្របតាម ច្បាប់នៃព្រះរាជាណាចក្រកម្ពុជា ហើយត្រូវបានបែងចែកទៅតាមប្រភេទដូចខាងក្រោម៖",

    "PurchaseWizardSuccessText1": "និយ័តករទូរគមនាគមន៍កម្ពុជា បានទទួលនូវសំណើចុះឈ្មោះដែនជាតិ .Kh របស់លោកអ្នករួចរាល់។",
    "PurchaseWizardSuccessText2": "សូមរង់ចាំក្រុមការងារផ្ដល់ដំណឹងតាមរយៈអ៊ីមែល ឬចូលទៅកាន់",
    
    "A": "ក",
    "B": "ខ",
    "C": "គ",
    "D": "ឃ",
    "E": "ង",
    "F": "ច",
    "G": "ឆ",
    "H": "ជ",
}

TRANSLATION_KM["DomainStatus" + DOMAIN_STATUS.READY] = "រួចរាល់";
TRANSLATION_KM["DomainStatus" + DOMAIN_STATUS.EXPIRED] = "បានផុតកំណត់";
TRANSLATION_KM["DomainStatus" + DOMAIN_STATUS.DRAW] = "រង់ចាំអ្នក";
TRANSLATION_KM["DomainStatus" + DOMAIN_STATUS.REMOVED] = "បានលុប";
TRANSLATION_KM["DomainStatus" + DOMAIN_STATUS.REQUESTED] = "បានស្នើសុំ";
TRANSLATION_KM["DomainStatus" + DOMAIN_STATUS.PENDING_PAYMENT] = "រង់ចាំការបង់ប្រាក់";
TRANSLATION_KM["DomainStatus" + DOMAIN_STATUS.PENDING_ACTIVATION] = "រង់ចាំបើកដំណើរការ";
TRANSLATION_KM["DomainStatus" + DOMAIN_STATUS.PENDING_DEACTIVATION] = "រង់ចាំបិទដំណើរការ";
TRANSLATION_KM["DomainStatus" + DOMAIN_STATUS.REJECTED] = "បានបដិសេធ";
TRANSLATION_KM["DomainStatus" + DOMAIN_STATUS.DEACTIVATED] = "បានបិទដំណើរការ";

TRANSLATION_KM["DocumentStatus" + DOCUMENT_STATUS.PENDING_RESUBMIT] = "រង់ចាំការស្នើឯកសារឡើងវិញ";
TRANSLATION_KM["DocumentStatus" + DOCUMENT_STATUS.APPROVED] = "បានអនុម័តលើឯកសារ";
TRANSLATION_KM["DocumentStatus" + DOCUMENT_STATUS.REMOVED] = "បានលុប";
TRANSLATION_KM["DocumentStatus" + DOCUMENT_STATUS.PENDING_APPROVAL] = "រង់ចាំការអនុម័តលើឯកសារ";
TRANSLATION_KM["DocumentStatus" + DOCUMENT_STATUS.DEACTIVATED] = "បានបិទដំណើរការ";
TRANSLATION_KM["DocumentStatus" + DOCUMENT_STATUS.REJECTED] = "បានបដិសេធ";
TRANSLATION_KM["DocumentStatus" + DOCUMENT_STATUS.DRAW] = "រង់ចាំអ្នក";

//Errors
TRANSLATION_KM["ErrorTitle" + HTTP_STATUSES.BAD_REQUEST] = "សំណើរមិនត្រឹមត្រូវ";
TRANSLATION_KM["ErrorSubtitle" + HTTP_STATUSES.BAD_REQUEST] = "សំណើររបស់លោកអ្នកមិនត្រឹមត្រូវ";

TRANSLATION_KM["ErrorTitle" + HTTP_STATUSES.NOT_FOUND] = "មិនមានក្នុងប្រព័ន្ធ";
TRANSLATION_KM["ErrorSubtitle" + HTTP_STATUSES.NOT_FOUND] = "ទំព័រដែលលោកអ្នកកំពុងរុករកមិនមានក្នុងប្រព័ន្ធយើងទេ";

TRANSLATION_KM["ErrorTitle" + HTTP_STATUSES.SERVER_ERROR] = "បញ្ហាមេម៉ាស៊ីន";
TRANSLATION_KM["ErrorSubtitle" + HTTP_STATUSES.SERVER_ERROR] = "សូមអភ័យទោសចំពោះបញ្ហារអាក់រអួល";