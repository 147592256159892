import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';


const SummaryValidationSchema = Yup.object().shape({
    isAcceptTerm: Yup.boolean()
        .oneOf([true],"AgreeToAgreementText"),
    isAcceptTerm2:Yup.boolean()
    .oneOf([true],"AgreeToAgreementText"),
});

export const summaryValidationOption = { resolver: yupResolver(SummaryValidationSchema) };