import { t } from "i18next";
import React from "react";

export function ButtonSkip(props) {
  return (
    <button
      hidden={props.hidden}
      onClick={props.onClick}
      size={props.size}
      type="button"
      disabled={props.disabled}
      icon={props.icon}
      style={props.style}
      htmlType={props.htmlType}
      className="btn btn-skip"
      title={t("DomainInfoSkipTooltip")}
      danger={props.danger}
      shape={props.shape}
      loading={props.loading}
    >
      <span className="text-nowrap">{t('DomainInfoSkip')}</span>
    </button>
  );
}