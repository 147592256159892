import axios from "axios";
import { API_ENDPOINT, HEADER_CONFIG } from "../../apiConfig";
const API_NAME = "profile";

export default class UserProfileService {

    async getProfile(accessToken) {
        HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
        return axios.get(API_ENDPOINT + API_NAME, HEADER_CONFIG);
    };

    async saveProfile(accessToken, body) {
        HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
        return axios.post(API_ENDPOINT + API_NAME, body, HEADER_CONFIG);
    };

    async checkProfile(accessToken) {
        HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
        return axios.get(API_ENDPOINT + API_NAME + "/check", HEADER_CONFIG);
    };
}