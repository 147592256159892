import { HTTP_STATUSES } from "../../constants/constant";
import { httpApi } from "../../helpers/httpApi";
export default class GovernmentSummaryService {

    getSummary(accessToken, domainId, successCallBack) {
        const uri = "Government/summary";
        httpApi.post(accessToken, uri, { "domainId": domainId }, successCallBack);
    };

    getSummaryDetail(accessToken, domainId, successCallBack) {
        const uri = "Government/summary-detail?domainId=" + domainId;
        httpApi.get(accessToken, uri, successCallBack,
            (error)=>{
                switch(error.response.status){
                    case HTTP_STATUSES.BAD_REQUEST: 
                        window.location.href = '/error/bad-request';
                        break;
                    default: 
                        window.location.href = '/error/server-error';
                        break;  
                }
            });
    };

    postPurchaseCompleted(accessToken, body, successCallBack) {
        const uri = "Government/completed";
        httpApi.post(accessToken, uri, body, successCallBack);
    };
}