import _ from "lodash";

export const getFileExtension = (filename) => {
    return ((/[.]/.exec(filename)) ? /[^.]+$/.exec(filename) : [""])[0];
}

export const getFileNameWithoutExtension = (filename) => {
    return filename.replace(/\.[^/.]+$/, "");
}

export const wrapForPreview = (fileUrl) => {
    var extension = fileUrl.split('.').pop();
    //if(extension.toLowerCase() === "pdf"){
    //    return `https://docs.google.com/viewer?url=${fileUrl}&embedded=true`;
    //}

    return fileUrl;
}

export const isDocumentRequired = (sampleDocuments, documentTypeId) => {
    var docType = _.find(sampleDocuments, { id: documentTypeId});
    return docType.isRequired;
}