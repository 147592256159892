import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import 'yup-phone';

const userProfileSchema = Yup.object().shape({
    nameKh: Yup.string().typeError('NameRequireText').required('NameRequireText'),
    nameEn: Yup.string()
        .typeError('LatinNameRequireText')
        .required('LatinNameRequireText')
        .matches(/^[a-zA-Z\s]+$/, 'LatinNamePatternText'),
    sexId: Yup.number().required('SexRequireText'),
    countryId: Yup.number().required('NationalityRequireText'),
    dateOfBirth: Yup.date().nullable().typeError('DateofBirthRequireText').required('DateofBirthRequireText'),
    idOrPassportNumber: Yup.string().typeError('IDOrPassportRequireText').required('IDOrPassportRequireText'),
    jobTitle: Yup.string().typeError('JobTitleRequireText').required('JobTitleRequireText'),
    companyName: Yup.string()
        .typeError('OrganizationOrCompanyRequireText')
        .required('OrganizationOrCompanyRequireText'),
    email: Yup.string().typeError('EmailRequireText').required('EmailRequireText').email('EmailNotValidFormat'),
    phoneNumber: Yup.string()
        .phone('KH', false, 'PhoneNumberInvalidText')
        .typeError('PhoneNumberRequireText')
        .required('PhoneNumberRequireText'),
    address: Yup.string().typeError('AddressRequireText').required('AddressRequireText')
});

export const userProfileValidation = { resolver: yupResolver(userProfileSchema) };
