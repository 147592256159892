import React from "react";
import {DOMAIN_STATUS} from "../../constants/constant";
import { useTranslation } from "react-i18next";
import { getBadgeClasses } from "../../helpers/statusDisplayHelper";

export function DomainStatus(props) {
    const { t } = useTranslation();
    const {domainDetail } = props;
    // let statusId = domainDetail.statusId;
    // console.log("test", domainDetail);
    // let textStyle = "";
    // if (statusId === DOMAIN_STATUS.EXPIRED) textStyle = "text-danger";

    return (
        <span className={`badge badge-pill ${getBadgeClasses(domainDetail.statusId)} body-2-text align-self-center ml-3`}>
            {domainDetail.statusId === DOMAIN_STATUS.READY ? <i className="fas fa-check mr-1"></i> : ""}{ t("DomainStatus" + domainDetail.statusId) }
            <div className="bullet bullet-success"></div>
        </span>
    )
}