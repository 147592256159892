import isValidDomain from 'is-valid-domain';

export const isDomainSearchValid = (value, rootDomain) => {
    if (
        value.length < 1 ||
        value.length > 63 ||
        !isValidDomain(`${value}${rootDomain}`, { subdomain: false }) ||
        value.indexOf('.') !== -1
    ) {
        return false;
    }
    return true;
};
