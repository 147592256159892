import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import 'yup-phone';

const instituteProfileInfoObjectShape = {
    isPersonal: Yup.string(),
    isManagerSameRegistrant: Yup.boolean(),
    isTechnicianSameRegistrant: Yup.boolean(),
    isBillingSameRegistrant: Yup.boolean(),
    isCompanySameRegistrant: Yup.boolean(),
    requester: Yup.object({
        nameKh: Yup.string().required('NameRequireText'),
        nameEn: Yup.string()
            .required('LatinNameRequireText')
            .matches(/^[a-zA-Z\s]+$/, 'LatinNamePatternText'),
        sexId: Yup.number().required('SexRequireText'),
        countryId: Yup.number().required('CountryRequireText'),
        dateOfBirth: Yup.date().nullable().typeError('DateofBirthRequireText').required('DateofBirthRequireText'),
        idOrPassportNumber: Yup.string().required('IDOrPassportRequireText'),
        jobTitle: Yup.string().required('JobTitleRequireText'),
        companyName: Yup.string().required('OrganizationOrCompanyRequireText'),
        email: Yup.string().required('EmailRequireText').email('EmailNotValidFormat'),
        fax: Yup.string(),
        phoneNumber: Yup.string().phone('KH', false, 'PhoneNumberInvalidText').required('PhoneNumberRequireText'),
        address: Yup.string().required('AddressRequireText')
    }),

    manager: Yup.object().when('isManagerSameRegistrant', {
        is: false,
        then: Yup.object({
            name: Yup.string().required('NameRequireText'),
            companyName: Yup.string().required('OrganizationOrCompanyRequireText'),
            email: Yup.string().required('EmailRequireText').email('EmailNotValidFormat'),
            phone: Yup.string().phone('KH', false, 'PhoneNumberInvalidText').required('PhoneNumberRequireText'),
            address: Yup.string().required('AddressRequireText')
        })
    }),
    technician: Yup.object().when('isTechnicianSameRegistrant', {
        is: false,
        then: Yup.object({
            name: Yup.string().required('NameRequireText'),
            companyName: Yup.string().required('OrganizationOrCompanyRequireText'),
            email: Yup.string().required('EmailRequireText').email('EmailNotValidFormat'),
            phone: Yup.string().phone('KH', false, 'PhoneNumberInvalidText').required('PhoneNumberRequireText'),
            address: Yup.string().required('AddressRequireText')
        })
    }),
    billing: Yup.object().when('isBillingSameRegistrant', {
        is: false,
        then: Yup.object({
            name: Yup.string().required('NameRequireText'),
            companyName: Yup.string().required('OrganizationOrCompanyRequireText'),
            email: Yup.string().required('EmailRequireText').email('EmailNotValidFormat'),
            phone: Yup.string().phone('KH', false, 'PhoneNumberInvalidText').required('PhoneNumberRequireText'),
            address: Yup.string().required('AddressRequireText')
        })
    }),

    companyInfo: Yup.object().when(['isPersonal', 'isCompanySameRegistrant'], {
        is: (isPersonal, isCompanySameRegistrant) => {
            return isPersonal === 'false' && isCompanySameRegistrant === false;
        },
        then: Yup.object({
            companyName: Yup.string().required('OrganizationOrCompanyRequireText'),
            email: Yup.string().required('EmailRequireText').email('EmailNotValidFormat'),
            phone: Yup.string().phone('KH', false, 'PhoneNumberInvalidText').required('PhoneNumberRequireText'),
            address: Yup.string().required('AddressRequireText')
        })
    })
};

const personalProfileInfoObjectShape = {
    ...instituteProfileInfoObjectShape,
    institutionTypeId: Yup.number()
        .nullable()
        .when('isPersonal', {
            is: (isPersonal) => isPersonal === 'false',
            then: Yup.number().typeError('InstitutionRequireText').required('InstitutionRequireText')
        })
};

const personalProfileInfoSchema = Yup.object().shape(personalProfileInfoObjectShape);
const instituteProfileInfoSchema = Yup.object().shape(instituteProfileInfoObjectShape);

export const personalProfileInfoValidation = { resolver: yupResolver(personalProfileInfoSchema) };

export const instituteProfileInfoValidation = { resolver: yupResolver(instituteProfileInfoSchema) };
