export const GovDocumentData = {
    domainId: 0,
    documents: [
        {
            "id": 0,
            "name": "",
            "fileUrl": "",
            "fileType": "",
            "documentTypeId": 12,
            "sampleUrl": "https://skaitemplate.blob.core.windows.net/skaiimage/1c7c329a-5a62-4790-abfd-61adc78050ee..pdf",
            "translate": 'GovDocumen1'
        },
        {
            "id": 0,
            "name": "",
            "fileUrl": "",
            "fileType": "",
            "documentTypeId": 13,
            "sampleUrl": "https://dnskhblob.blob.core.windows.net/uat/d5bf3b5e-f104-428a-8982-74218eb85957..jpg",
            "translate": 'GovDocument2'
        }
    ]
}