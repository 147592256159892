import './index.css';

import { AuthenticatedTemplate, useMsalAuthentication } from "@azure/msal-react";
import { Fragment, useEffect, useState } from "react";
import { getFileExtension, getFileNameWithoutExtension } from "../../helpers/fileHelper";
import { useNavigate, useParams } from "react-router-dom";

import { CustomSpinner } from "../../components/share/Spinner";
import { DocumentValidFileTypes } from "../../models/validations/DocumentValidFileTypes";
import DomainService from '../../services/domain/DomainService';
import { FILE_SIZE_LIMIT } from "../../apiConfig";
import { GovDocumentData } from "../../models/GovDocumentData";
import { GovResubmitDocumentStep } from "../../components/government_resubmit/steps/DocumentStep";
import { GovernmentDocumentRender } from "../../components/government/Document";
import GovernmentDocumentService from "../../services/government/DocumentService";
import { InfoMessageModal } from "../../components/share/modals/InfoMessageModal";
import { InteractionType } from '@azure/msal-browser';
import { ViewCommentModal } from "../../components/share/modals/ViewCommentModal";
import _ from 'lodash';
import { useForm } from "react-hook-form";
import { useMsalAccessToken } from "../../hooks/useMsalAccessToken";
import { useTranslation } from "react-i18next";
import { getCurrentLanguage } from "helpers/getLanguage";
import { ButtonForward } from 'components/purchase/ButtonForward';

export const GovernmentResubmitDocument = () => {
    const { login, result, error } = useMsalAuthentication(InteractionType.Redirect, { extraQueryParameters: { ui_locales: getCurrentLanguage() } });

    const { register, handleSubmit, formState: { errors } } = useForm();

    const { t } = useTranslation();

    const [visibleCommentDialog, setVisibleCommentDialog] = useState(false);
    const [loading, setLoading] = useState('none');
    const [visibleMessage, setVisibleMessage] = useState(false)
    const [messageContent, setMessageContent] = useState("");

    const [domainDetail, setDomainDetail] = useState();
    const [domainAttachmentFile, setDomainAttachmentFiles] = useState(undefined);

    let navigate = useNavigate();
    let { domainId } = useParams();
    let accessToken = useMsalAccessToken();

    useEffect(() => {
        if (accessToken !== undefined) {
            getDomainDetail(accessToken);
            getDocumentDetail(accessToken, Number(domainId), false);
        }
    }, [accessToken]);

    const getDomainDetail = (accessToken) => {
        let domainService = new DomainService();

        domainService.getDomainById(accessToken, domainId).then(res => {
            setDomainDetail(res.data);
        });
    }

    const getDocumentDetail = (accessToken, domainId, profileData) => {
        var profileService = new GovernmentDocumentService()
        profileService.getDomainDocument(accessToken, domainId).then(res => {
            console.log("get Document", res);
            if (res.statusCode === "OK" && res.data.documents.length > 0) {
                let docs = GovDocumentData.documents;
                //If documents previously saved we load it back
                var merge = _.map(docs, function (item) {
                    return _.merge(item, _.find(res.data.documents, { 'documentTypeId': item.documentTypeId }));
                });
                console.log("merge", merge);

                setDomainAttachmentFiles({ domainId, documents: merge });
            } else {
                setDomainAttachmentFiles(GovDocumentData)
            }
        })
    }


    const onSubmit = data => {
        var isFileUploadRequire = _.some(domainAttachmentFile.documents, d => d.name === "");
        var profileService = new GovernmentDocumentService();
        if (domainAttachmentFile === null || isFileUploadRequire) {
            setMessageContent(t('PleaseUploadAllRequireDocumentEndStar'));
            setVisibleMessage(true);
        } else {
            console.log(domainAttachmentFile);
            profileService.saveDocument(accessToken, domainAttachmentFile).then(res => {
                if (res.status === "Ok") {
                    navigate(`/government-resubmit/summary/${domainId}`);
                }
            })
        }
    }

    const onChangeFile = (e, index) => {
        if (e.target.files.length > 0) {
            setLoading('');
            const formData = new FormData();
            let [file] = e.target.files;
            let fileSize = file.size / 1024 / 1024;
            let isValidFileType = DocumentValidFileTypes.indexOf(file.type) !== -1;

            if (fileSize > FILE_SIZE_LIMIT) {
                setMessageContent(t('DocumentFileSizeText'));
                setVisibleMessage(true);
                setLoading('none');

                e.target.value = '';
                return;
            }

            if (!isValidFileType) {
                setMessageContent(t('DocumentFileTypeText'));
                setVisibleMessage(true);
                setLoading('none');

                e.target.value = '';
                return;
            }

            formData.append("files", file, file.name);

            var purchaseDocumentService = new GovernmentDocumentService();
            purchaseDocumentService.uploadDocument(accessToken, formData).then(res => {
                setLoading('none');
                let tempData = domainAttachmentFile;
                tempData.documents[index].name = getFileNameWithoutExtension(file.name);
                tempData.documents[index].fileUrl = res.data.url;
                tempData.documents[index].fileType = getFileExtension(file.name);
                tempData.domainId = Number(domainId);
                setDomainAttachmentFiles({
                    domainId: tempData.domainId,
                    documents: tempData.documents
                });
            });
        }
    }

    const onRemoveFile = (file, index) => {
        let tempData = domainAttachmentFile;
        tempData.documents[index].name = "";
        tempData.documents[index].fileUrl = "";
        tempData.documents[index].fileType = "";
        setDomainAttachmentFiles({
            domainId: tempData.domainId,
            documents: tempData.documents
        });
    }
    const handleCloseModal = () => {
        setVisibleMessage(false);
    }

    const handleCloseCommentDialog = () => {
        setVisibleCommentDialog(false);
    }

    return (
        <AuthenticatedTemplate>
            <Fragment>
                <div className="purchase-page purchase-wizard body-section">
                    <div className="container-1200 container-1200-max">
                        <div className="heading-section">
                            <h4 className="text-center mb-4">{domainDetail?.name}</h4>
                            <GovResubmitDocumentStep />
                        </div>
                        <CustomSpinner display={loading} />
                        <div className="content-section">
                            <div className="feedback p-3 p-lg-4 mb-2">
                                <p className="mb-2">{t('RequireChanges')}</p>
                                <button type="button" className="btn btn-outline-primary mr-2" onClick={() => setVisibleCommentDialog(true)}>{t('ViewComment')}</button>
                            </div>
                        </div>
                        <div className="content-section">
                            <form onSubmit={handleSubmit(onSubmit)} noValidate name="form" className="custom-form needs-validation">
                                <div className="wizard-navigation">
                                    <div className="d-flex btn-groups">
                                        <button onClick={() => navigate(`/government-resubmit/profile/${domainDetail?.id}`)} className="btn btn-prev" title={t("Back")}><i className="fas fa-chevron-left"></i></button>
                                        <ButtonForward/>
                                    </div>
                                </div>
                                <div className="card dns-card card-border-top">
                                    <div className='card-body'>
                                        <h4 className="mb-4 d-flex align-items-center justify-content-between">
                                            <span>
                                                2.{t('DocumentText')}
                                            </span>
                                        </h4>
                                        <div className="body-2-text mb-2">{t('ToPurchaseForUsagePleaseUpload', { domain: domainDetail?.name })}</div>
                                        {
                                            domainAttachmentFile &&
                                            <GovernmentDocumentRender
                                                domainAttachmentFile={domainAttachmentFile}
                                                onChangeFile={onChangeFile}
                                                onRemoveFile={(file, index) => onRemoveFile(file, index)}
                                                register={register}
                                            />
                                        }
                                    </div>
                                    <div className="card-footer">
                                        <div className="d-flex justify-content-md-end pt-3 mt-auto">
                                            <button className="btn btn-primary btn-block" type="submit">{t('NextText')}</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
            <ViewCommentModal domainId={domainId} visible={visibleCommentDialog} handleCancel={() => handleCloseCommentDialog()} />
            <InfoMessageModal handleCancel={() => handleCloseModal()} cssClassName="alert-warning" visible={visibleMessage} infoMessage={messageContent} />
        </AuthenticatedTemplate>
    )
}