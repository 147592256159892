import { yupResolver } from '@hookform/resolvers/yup';
import { t } from 'i18next';
import isValidHostname from 'is-valid-hostname';
import * as Yup from 'yup';


const serverInfoValidationSchema = Yup.object().shape({
    nameServerList: Yup.array().of(
        Yup.object().shape({
            nameServer: Yup.lazy(value=>
                Yup.string().required('NameServerRequireText')
                .test('nameServer', t('NameServerNotValidFormatText', { value }), value=> {
                    return isValidHostname(value);
                    //Todo: revalidate
                })
            ),
            ipAddress: Yup.lazy(value => 
                Yup.string()
                .when('nameServer',{is: (value)=> value !== '',then: Yup.string().required("IPAddressRequire")})
                .matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, { 
                    message: t('IPAddressNotValidFormatText', { value }), 
                    excludeEmptyString: true 
                })
                .test('ipAddress', t('IPAddressNotValidFormatText', { value }),
                    value => {
                        if(value === undefined || value.trim() === '') return true;
                        return value.split('.').find(i => parseInt(i) > 255) === undefined;
                })
            )
        })
    )
});

export const serverInfoValidationOption = { resolver: yupResolver(serverInfoValidationSchema) };