import { createTheme, ThemeProvider } from '@mui/material';
import { fontSize } from '@mui/system';
import { Outlet, useLocation } from 'react-router-dom';
import ScrollTop from '../scroll-top/scroll-top';
import FixedActions from './fixed-actions/fixed-actions';
import Footer from './footer/footer';
import Header from './header/header';

import './layout.scss';

const Layout = () => {
    const location = useLocation();
    const customTheme = createTheme({
        palette: {
            primary: { main: '#014282' },
            secondary: { main: '#ff7c00' }
        },
        breakpoints: {
            values: {
                xxl: 1920
            }
        },
        typography: {
            fontFamily: 'Kantumruy Pro, Poppins, sans-serif'
            // fontSize: '1rem'
        },
        components: {
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        [`& fieldset`]: {
                            borderRadius: '0.75em'
                        },
                        input: {
                            padding: '0.54rem 0.75rem'
                        }
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        [`& fieldset`]: {
                            borderColor: '#014282',
                            borderWidth: '1px !important'
                        }
                    }
                }
            },
            MuiFormLabel: {
                styleOverrides: {
                    asterisk: { color: 'red' },
                    root: {
                        fontWeight: 500,
                        fontSize: '0.825em',
                        color: '#000',
                        '&.Mui-focused': {
                            color: '#000'
                        },
                        '&.MuiInputLabel-shrink': {
                            fontSize: '1rem'
                        }
                    }
                }
            },
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        width: '100%'
                    }
                }
            },
            MuiCalendarPicker: {
                styleOverrides: {
                    root: {
                        // fontsiz
                        '& div': {
                            fontSize: '18px'
                        },
                        '& .MuiTypography-root': {
                            fontSize: '16px'
                        },
                        '& .MuiPickersDay-root': {
                            fontSize: '16px'
                        },
                        '& .MuiSvgIcon-root': {
                            fontSize: '1em'
                        }
                    }
                }
            }
        }
    });
    return (
        <ThemeProvider theme={customTheme}>
            <ScrollTop />
            <div className="page-container">
                <Header />
                <main>
                    <Outlet />
                </main>
                <Footer />
            </div>
            <FixedActions />
        </ThemeProvider>
    );
};

export default Layout;
