import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { faChevronDown, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCurrentLanguage } from 'helpers/getLanguage';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as GbFlag } from '../../../../assets/svg/gb.svg';
import { ReactComponent as KhFlag } from '../../../../assets/svg/kh.svg';
import { ReactComponent as SVGMenuToggle } from '../../../../assets/svg/mobile-menu.svg';
import './mobile-navigation.scss';

const MobileNavigation = ({ onLoginClick, onLogoutClick, onResetPasswordClick, onSwitchLanguage }) => {
    const toggleClicked = (e) => {
        const body = document.querySelector('body');
        const html = document.querySelector('html');
        body.classList.toggle('noscroll');
        html.classList.toggle('noscroll');

        const mobileNavigation = document.querySelector('.mobile-navigation');
        mobileNavigation.classList.toggle('active');
    };

    const removeClasses = () => {
        const body = document.querySelector('body');
        const html = document.querySelector('html');
        body.classList.remove('noscroll');
        html.classList.remove('noscroll');

        const mobileNavigation = document.querySelector('.mobile-navigation');
        mobileNavigation.classList.remove('active');
    };

    const dropdownClicked = (e) => {
        const dropdown = e.target.nextSibling;
        const dropdownBody = dropdown.querySelector('.dropdown-body');
        dropdownBody.classList.toggle('active');
    };

    const location = useLocation();
    const [pathname, setPathname] = useState(null);

    useEffect(() => {
        if (!pathname) setPathname(location.pathname);

        if (pathname && location.pathname !== pathname) {
            setPathname(location.pathname);
            removeClasses();
        }
    }, [location]);

    const renderFlag = () => {
        if (getCurrentLanguage() === 'km') return <GbFlag />;
        else return <KhFlag />;
    };

    return (
        <div className="flex-column mobile">
            <button onClick={toggleClicked} className="menu-toggle">
                <SVGMenuToggle />
            </button>
            <div className="mobile-navigation">
                <button onClick={toggleClicked}>
                    <FontAwesomeIcon icon={faClose} />
                </button>
                <div className="separator-border no-mg-top"></div>
                <div className="menu">
                    <button onClick={dropdownClicked} className="curp">
                        {t('RegisterDomain')}
                        <FontAwesomeIcon icon={faChevronDown} fontSize={'16px'}></FontAwesomeIcon>
                    </button>
                    <div className="dropdown">
                        <div className="dropdown-body">
                            <NavLink to="/help/general-info" className="link">
                                <span>{t('generalInformation')}</span>
                            </NavLink>
                            <NavLink to="/help/faq" className="link">
                                <span>{t('questionAnswer')}</span>
                            </NavLink>
                            <NavLink to="/whois" className="link">
                                <span>{t('findoutdomain-sownershiptext')}</span>
                            </NavLink>
                        </div>
                        <div className="dropdown-footer"></div>
                    </div>
                </div>
                <div className="separator-border"></div>
                <NavLink to="/help/how-to-register" className="menu">
                    <span>{t('howToBuyResgisterText')}</span>
                </NavLink>
                <div className="separator-border"></div>
                <NavLink to="/required-documents" className="menu">
                    <span>{t('DocumentText')}</span>
                </NavLink>
                <div className="separator-border"></div>
                <NavLink to="/about" className="menu">
                    <span>{t('AboutDNS')}.kh</span>
                </NavLink>
                <div className="separator-border"></div>
                <AuthenticatedTemplate>
                    <>
                        <div className="menu">
                            <button onClick={dropdownClicked} className="curp">
                                {t('DomainText')}
                                <FontAwesomeIcon icon={faChevronDown} fontSize={'16px'}></FontAwesomeIcon>
                            </button>
                            <div className="dropdown">
                                <div className="dropdown-body">
                                    <NavLink to="/my-cart" className="link">
                                        <span>{t('MyCartText')}</span>
                                    </NavLink>
                                    <NavLink to="/my-domain" className="link">
                                        <span>{t('MyDomainText')}</span>
                                    </NavLink>
                                </div>
                                <div className="dropdown-footer"></div>
                            </div>
                        </div>
                        <div className="separator-border"></div>
                        <div className="menu">
                            <button onClick={dropdownClicked} className="curp">
                                {t('AccountText')}
                                <FontAwesomeIcon icon={faChevronDown} fontSize={'16px'}></FontAwesomeIcon>
                            </button>
                            <div className="dropdown">
                                <div className="dropdown-body">
                                    <NavLink to="/my-profile" className="link">
                                        <span>ព័តមានរបស់ខ្ញុំ</span>
                                    </NavLink>
                                    <button onClick={onResetPasswordClick} className="link">
                                        <span>{t('ResetPasswordText')}</span>
                                    </button>
                                    <button className="link" onClick={onLogoutClick}>
                                        <span>{t('LogOutText')}</span>
                                    </button>
                                </div>
                                <div className="dropdown-footer"></div>
                            </div>
                        </div>
                        <div className="separator-border"></div>
                    </>
                </AuthenticatedTemplate>
                <button type="button" className="btnFlag" onClick={onSwitchLanguage}>
                    {renderFlag()}
                </button>
                <UnauthenticatedTemplate>
                    <button className="menu btnLogin button primary" onClick={onLoginClick}>
                        {t('LoginText')}
                    </button>
                </UnauthenticatedTemplate>
            </div>
        </div>
    );
};

export default MobileNavigation;
