import { DOMAIN_STATUS, DOCUMENT_STATUS, HTTP_STATUSES } from '../../constants/constant';

export const TRANSLATION_EN = {
    domain: 'Domain',
    firstYearFee: 'First Year Fee',
    fromTwoYearFee: 'From Second Year Fee',
    renewPriceFee: 'Renew Fee',
    khqrDescription: 'Scan to pay with member bank app',
    abaPayDescription: 'Scan to pay with ABA Mobile',
    acledaXPayDescription: 'Make payment with ACLEDA Mobile',
    creditDebitCard: 'Credit/Debit Card',
    AddMoreNameServer: 'Add More Hosting Server',
    AboutDNS: 'About ',
    AccountText: 'Account',
    FillServerInfo: 'FILL IN SERVER INFO LATER',
    AddOrEditDomainInfoText: 'Add or Modify Domain Info',
    DomainInfoSkip: 'Skip',
    DomainInfoSkipTooltip: 'Skip to next Page',
    DomainServerInfoTitle: 'How to find hosting server info',
    DomainServerInfoText:
        'The nameservers determine where your DNS files are hosted. This can also be quickly found through a WHOIS search. Use a WHOIS search engine such as',
    DomainServerInfoText1: 'Search for your domain name.',
    DomainServerInfoText2:
        'Look through your results until you find the Name Server information. This will let you know who is hosting your DNS',
    DomainServerInfoOkay: 'Okay',
    AddressRequireText: 'Address is required.',
    InstitutionRequireText: 'Institution is required.',
    SelectInstitutionText: 'Select an Institution Type',
    AddressText: 'Address',
    AddressText1: 'Address',
    AddToCartText: 'Add to Cart',
    Back: 'Back',
    BackToTopText: 'Back to top',
    bluePanelIndex1: 'Search for your name, brand, business, company, or organization',
    bluePanelIndex2: 'Select the available domain names and .kh extension',
    bluePanelIndex3: 'Submit relevant information and documents for approval',
    bluePanelIndex4: 'Check out and pay',
    ByCompletedDomainOrderPleaseReadandConfirmTermAndPrivacyText:
        "By completing this order domain, I confirm that I have read and agree to our'",
    Cart: 'Cart',
    CheckOutText: 'Checkout',
    ChooseFile: 'Choose File',
    DragDrop: 'Drag and Drop',
    FileHere: 'your documents (jpg, png or pdf) here, or',
    RemoveFile: 'Remove',
    CivilServantID: 'Civil Servant ID',
    CivilServantIDRequire: 'Civil Servant ID is require.',
    CloseText: 'Close',
    Company: 'Company',
    companyDescription: 'For commercial enterprise, state-owned enterprise and companies website',
    CompanyInformationText: 'Company Information',
    companyTitle: 'Commercial',
    CompleteProfileText: 'Complete Profile',
    CompleteYourDomain: 'Complete Your Domain',
    ContactInformation: 'Contact Information',
    ContactText: 'Contact',
    DataSavedSuccessfully: 'Data Saved Successfully.',
    Date: 'Date',
    DateofBirthRequireText: 'Date of Birth is required.',
    DateofBirthText: 'Date of Birth',
    DelegateManagementAndAdministrationToOfficer1: 'Delegate management and administration to officer 1',
    DelegateManagementAndAdministrationToOfficer2: 'Delegate management and administration to officer 2',
    DelegateManagementAndAdministrationToOfficer3: 'Delegate management and administration to officer 3',
    Detail: 'Detail',
    Discount: 'Discount',
    DisplayName: 'Display​ Name',
    DisplayNameRequire: 'Display​ Name is require.',
    docRequireText: 'Required Documents',
    DocumentStatus: 'Document Status',
    DocumentText: 'Document',
    DocumentFileSizeText: 'Document max size limit is 10MB',
    DocumentFileTypeText: 'Document supported file type is pdf',
    DomainGovAndMilNotAvailableText: 'Domain .gov.kh and .mil.kh is not available for you.',
    DomainInformationIsNotYetFillIn: 'Domain information is not yet fill In.',
    DomainInformationText: 'Server Information',
    domainInfoText: 'Please input Hosting Server information for',
    DomainKHText: 'Domain .kh',
    DomainManagerContact: 'Domain Manager Contact',
    UntickText: '( If different, please uncheck the box. )',
    domainMangerDescription:
        'Domain manager is the main contact person for all the relevant communication regarding the domain.',
    DomainNameAlreadyInUsedText: 'Domain is already used. Please try another one',
    DomainNameAlreadySubmitText: 'Domain name is already submit',
    DomainNameNotValidatedText:
        'The .kh domain name must be between 1 and 63 characters, and only letters (a-z), number (0-9), and hypen (-) are allowed.',
    DomainNameRequireText: 'Please fill out Domain name.',
    DomainPayerContact: 'Domain Billing Contact',
    domainPayerDescription:
        'Domain Billing Contact is the main contact person for all the communication regarding the domain’s payment.',
    DomainStatus: 'Domain Status',
    DomainTechnicianContact: 'Domain Technician Contact',
    domainTechnicianDescription:
        "Domain technician is the technician's contact person for all the relevant communication regarding the domain.",
    DomainText: 'Domain',
    'download-our-document-for-reference': 'Download our document for reference',
    'download-sample': 'Download sample',
    DownloadYourOldDocumentText: 'Download your existing document',
    ViewDownload: 'Download Sample',
    SampleDocumentText: 'Sample Document',
    DragAndDrop: 'Drag & Drop',
    emailDescription: 'Current email or new email to be created with requested domain.',
    EmailNotValidFormat: 'Email is not valid format',
    EmailRequireText: 'Email is required.',
    EmailText: 'Email',
    ContactEmailText: 'Contact Email',
    UserEmailText: 'User Email',
    ExpireDate: 'Expire Date',
    FaxNumberText: 'Fax Number',
    FindPerfectDomainText: 'Find your perfect domain!',
    FirstName: 'First Name',
    FirstNameRequire: 'First Name is require.',
    FillinServerInfo: 'Fill in Server Information',
    ForOrganizationOrCompanyText: 'For Organization / Company',
    ForNewUpdate: 'Social Media',
    ForPersonalUseText: 'For personal use',
    TermAndCondition: 'Term and Condition ',
    TermCaption: 'Registrant shall have obligations as follows:',
    TermCondition1:
        '1.   Ensure the accuracy of the information and documents provided to Telecommunication Regulator of Cambodia (TRC).',
    TermCondition2:
        '2.   Shall not use national domain name to promote activities or business or other information, and communication on the internet that affect culture, morality, tradition, individual rights, consumer rights, security, or social order.',
    TermCondition3: '3.   Shall not allow to use or lease national domain name to third party.',
    TermCondition4:
        '4.   Shall not transfer or allow to use or lease subdomain name created under registered national domain name to third party.',
    TermCondition5:
        '5.   Shall pay usage fee on time. In the event of nonpayment within 60 days, such domain name shall be deleted from the national domain name management system.',
    TermCondition6:
        '6.   Shall notify and fulfill conditions required by TRC in the event of transferring national domain name.',
    TermCondition7:
        '7.   Shall Cooperate with Ministry of Post and Telecommunication, TRC and competent authorities as required by laws and applicable regulations.',
    FreeText: 'Free',
    generalUsageDescription: 'For business and personal websites',
    generalUsageTitle: 'General Usage',
    generalInformation: 'General Information',
    generalInfoText1:
        '.kh domain name registration is divided into two levels: First Level and Second Level. The First Level are allocated for persons inside and outside of the Kingdom of Cambodia. For the second level, they are allocated for legal persons that are established or registered in accordance with the laws of the Kingdom of Cambodia as below:',
    'generalInfoText1.1': ' for commercial enterprises or public enterprises',
    'generalInfoText1.2': ' for organizations, associations or trade unions',
    'generalInfoText1.3': ' for public and private education institutions.',
    'generalInfoText1.4': ' for companies or institutions providing computer network services',
    generalInfoText2:
        'Ministry of Post and Telecommunications (MPTC) is the institution mandated to manage national domain names on the Internet while Telecommunication Regulator of Cambodia (TRC) regulates the use of national domain names, and performs the following roles and duties:',
    'generalInfoText2.1':
        'Grant, modify, transfer, suspend, or revoke national domain name registration certificates and update information related to the national domain names;',
    'generalInfoText2.2': 'Prepare a list of the reserved and prohibited domain names;',
    'generalInfoText2.3': 'Establish and publicly publish WHOIS Database;',
    'generalInfoText2.4':
        'Store electronic personal information through any means to ensure that the information is protected securely under all reasonable circumstances to circumvent the loss, access, use, modification, leaking, or disclosure of such information except otherwise agreed by registrant or other party permitted by laws;',
    'generalInfoText2.5':
        'Take measures to suspend or delete national domain names which are used contrary to the laws and applicable regulations;',
    'generalInfoText2.6':
        'Formulate legal regulations and development plans to promote and encourage the use of national domain names;',
    'generalInfoText2.7':
        'Cooperate with national and international institutions in relation to the management of national domain names; and',
    'generalInfoText2.8': 'Perform other roles and duties pursuant to the instruction of MPTC Minister.',
    governmentTitle: 'Government',
    governmentDescription: 'For government websites',
    headerText: 'Bring Your Ideas Online',
    headingDomainTypeText: '.kh domains are available for all types of websites',
    headingHowtoBuyDescription: 'Make your domain name ready in few minutes',
    headingHowtoBuyDescription1: 'Fast, Simple, and Easy.',
    headingHowtoBuyText: 'How to buy a .kh domain',
    howToBuyResgisterText: 'How to register',
    howToDescription: 'Procedure for registering domain name .kh via online system',
    howToStep1: 'Browse',
    howToStep2: 'Fill in personal information',
    howToStep3: 'Purchase domain name',
    howToStep4: 'Pay',
    howToStep5: 'Register an account',
    howToStep6: 'Search a domain name',
    howToStep7: 'Submit documents for approval',
    howToStep8: 'Activation',
    howToVideoTitle: 'Video for registering domain name',
    howToGuideTitle: 'Guidebook for registering domain name',
    howToDownload: 'Download',
    MyAccount: 'My Account',
    helpText: 'Help',
    DomainRegistered: 'This domain is already registered.',
    iCertifyText:
        'I certify that the information provided above and the attached documents are true and\ncorrect. I also understand that any false information or\ndeliberate omission may subject me to legal\nactions and my domain will be suspended.',
    IdentificationDocument: 'Identification Document',
    IDOrPassportRequireText: 'ID Card/Passport Number is required.',
    IDOrPassportText: 'ID Card / Passport Number',
    IHaveReadAndAgreeToThe: 'I have read and agree to the',
    IntroductionVideoText: 'Instruction Video',
    InternetTitle: 'Internet',
    InternetDescription: 'For institutions providing computer network services websites',
    IPAddress: 'IP Address',
    IPAddressNotValidFormatText: 'IP Address {{value}} is not in the correct format.',
    IPAddressRequire: 'IP Address of Hosting Server is required.',
    IsAvailableText: 'is available',
    IsNotAvailableText: 'is not available',
    IsThisDomainForCompanyOrPersonalUsage: 'Is this domain for institutional or personal use?',
    JobTitleRequireText: 'Job Title is required.',
    JobTitleText: 'Job Title',
    KHRText: 'KHR',
    LastName: 'Last Name',
    LastNameRequire: 'Last Name is require.',
    LatinNameRequireText: 'Latin Name is required.',
    LatinNamePatternText: 'Latin Name is required, special character is not allowed.',
    LatinNameText: 'Latin Name',
    legalText: 'Laws and Regulations',
    LoginText: 'Sign in',
    LogOutText: 'Sign out',
    copyright: 'Copyright',
    weAccept: 'We accept',
    mptc: 'Ministry of Post and Telecommunications',
    trc: 'Telecommunication Regulator of Cambodia (TRC)',
    allRightReserved: 'All Rights Reserved',
    MinistryName: 'Ministry Name',
    MinistryNameRequire: 'Ministry Name is require.',
    MyProfileText: 'My Profile',
    NameRequireText: 'Name is required.',
    NameServer1RequireText: 'Name Server 1 is require.',
    NameServerLabel: 'Name Server',
    NameServer2RequireText: 'Name Server 2 is require.',
    NameServer2Text: 'Name Server 2',
    NameServerNotValidFormatText: 'The Hosting Server Name {{value}} is not in the correct format.',
    NameServerRequireText: 'Hosting Server Name is required.',
    NameServerText: 'Hosting Server Name',
    nameServeText: 'HOSTING SERVER NAME',
    NameText: 'Name',
    NationalityRequireText: 'Nationality is require.',
    NationalityText: 'Nationality',
    NextText: 'Next',
    OnlyGovAndMilAvaiableForYouText: 'Only domain .mil.kh and .gov.kh avaliable for you',
    orderSummaryTitle: 'Order Summary',
    organizationDescription: 'For organizations, associations or trade unions websites',
    OrganizationOrCompanyInformationText: 'Organization / Company Information',
    OrganizationOrCompanyRequireText: 'Organization / Company is required.',
    OrganizationOrCompanyText: 'Organization / Company',
    organizationTitle: 'Organizations',
    OrText: 'Or',
    OurCustomer: 'Our Customers',
    OurMoreThan: 'More than 3,000 businesses has registered domain name .kh',
    'our-feeback': 'Our Feeback',
    PayerInformationText: 'Payment Billing Contact​ Information',
    PaymentDetailText: 'Payment Detail',
    paymentMethodTitle: 'Select Payment Method',
    PaymentText: 'Payment',
    PayNowText: 'Pay Now',
    Personal: 'Personal',
    PrivacyPolicy: 'Privacy Policies',
    educationDescription: 'For public and private education institutions website',
    PersonalInformation: 'Personal Information',
    personalInfoTitle: 'PERSONAL INFORMATION',
    educationTitle: 'Education',
    PhoneNumberRequireText: 'Phone Number is required.',
    PhoneNumberInvalidText: 'Phone Number is invalid.',
    PhoneNumberText: 'Phone Number',
    PhoneText: 'Phone',
    'please-choose-payment-method': 'Please choose payment method',
    PleaseAcceptReadandConfirmTermAndPrivacyText: 'Please read the terms and privacy policy.',
    PleaseAcceptTermAndConditionText: 'Please read and agree to the terms and conditions.',
    PleaseChooseOfBelowPaymentMethodText: 'Please choose one of the payment methods below',
    PleaseUpdateYourDomainInformation: 'Please edit your domain information',
    PleaseUploadAllRequireDocumentEndStar: 'Please upload all required document that end with * .',
    PleaseUploadOfBelowFileText: 'Please upload one of below document',
    Prefession: 'Profession',
    PreviousText: 'Previous',
    Price: 'Price',
    'profile-and-documenttitle': 'User Profile & Document',
    ViewComment: 'VIEW COMMENT',
    RequireChanges:
        'This Domain requires changes. Please review comment below and re-submit your information accordingly.',
    RequestChanges: 'Request Changes',
    ManageDomainTitle: 'Manage Domain',
    ProfileText: 'Profile',
    PurchaseNowText: 'Purchase',
    PurchaseThankText: 'Your domain is being setup!',
    PurchaseThankText1: 'We will send an email notification shortly to',
    Purpose: 'Purpose',
    PurposeOfDomainText: 'The purpose of the domain is for',
    PurposeoftheWebsite: 'Purpose of the Website',
    PurposeRequire: 'Purpose is require.',
    questionAnswer: 'Question | Answer',
    qnaTitle: 'Frequently Asked Questions',
    qnaText1: 'What is a domain name?',
    qnaAnswer1:
        'Domain name is a global unique identifier which facilitates people on the internet reach toward local internet resources. \n\t\t\t\tSimply put, it is your postal address in the online world.',
    qnaText2: 'What are the benefits of registering domain name?',
    qnaAnswer2:
        'Domain name provides a reliable and searchable online address and it serves as one-stop platform for information delivery of your products and services to the public.',
    qnaText3: 'What is the Top-Level Domain?',
    qnaAnswer3: 'Top-Level Domain is the highest level in the hierarchy of domain name.',
    'qnaAnswer3.1':
        'For example, domain “.kh” and “.ខ្មែរ” is highest level in the hierarchy of Cambodia national domain name which is indicated as the identity of the Kingdom of Cambodia on the Internet.',
    qnaText4:
        'What are the different between generic top level domain (gTLD) and country code top-level domain (ccTLD)?',
    qnaAnswer4:
        'The Generic Top-Level Domain (gTLD) is the top-level domain of on Internet including but not limited to .com, .net and .org. The gTLD does not identify any country and it is administered by the Internet Corporation for Assigned Names and Numbers (ICANN). The Country Code Top-Level Domain is a top-level domain on the Internet that is reserved for a country, region, or other geographic locations. For example, kh is the ccTLDs for the Kingdom of Cambodia.',
    'qnaAnswer4.1':
        'The Country Code Top-Level Domain is a top-level domain on the Internet that is reserved for a country, region, or other geographic locations. For example, kh is the ccTLDs for the Kingdom of Cambodia.',
    qnaText5: 'What is the second level domain?',
    qnaAnswer5:
        'The second level domain is a domain which is on the left of the Top-Level Domain. For example, In cambodia.kh, cambodia is considered as the Second Level Domain of .kh.',
    qnaText6: 'What is subdomain?',
    qnaAnswer6:
        'Subdomain is a domain that is created and located to the left after the requested domain name. For Example, with regard to mptc.gov.kh, mptc is subdomain of gov.kh, and .gov is subdomain of .kh. We can also say that mptc.gov.kh is a third level domain name and gov.kh is second level domain.',
    qnaText7: 'What is a registrar?',
    qnaAnswer7:
        'Registrar is a legal person registered in accordance with the laws of the Kingdom of Cambodia and they receive a certificate issued by the Telecommunication Regulator of Cambodia (TRC) to provide services concerning national domain name registration.',
    qnaText8: 'What is a registrant?',
    qnaAnswer8:
        'Registrant is a person who has received a certificate of registration for the use of a national domain name from the TRC.',
    qnaText9: 'How many types of domain name are provided for registration in the Kingdom of Cambodia?',
    qnaAnswer9:
        'You can register domain name under Top-Level Domain and Second Level Domain. The domain name registration under Top-Level Domain consists of two forms:',
    'qnaAnswer9.1': '1-“applied name” .kh for any person inside and outside of the Kingdom of Cambodia',
    'qnaAnswer9.2': '2-“applied name” .ខ្មែរ for any person inside and outside of the Kingdom of Cambodia ',
    'qnaAnswer9.3': 'The domain name registration under Second-Level Domain consists of the following forms:',
    'qnaAnswer9.4': '1-“applied name” .com.kh for commercial enterprises or public enterprises.',
    'qnaAnswer9.5': '2-“applied name” .org.kh for organizations, associations or trade unions.',
    'qnaAnswer9.6': '3-“applied name” .edu.kh for public and private education institutions.',
    'qnaAnswer9.7': '4-“applied name” .net.kh for companies or institutions providing computer network services.',
    qnaText10: 'Any tips for choosing good domain name?',
    qnaAnswer10: 'You may consider following tips:',
    'qnaAnswer10.1': '- Keep it short and easy to remember',
    'qnaAnswer10.2': '- Should closely reflect your identity, brand, nature of your product or services, etc',
    'qnaAnswer10.3':
        '- Avoid using Official Name, Marks, or Prohibited Name or any expression that may cause confusion to the public',
    qnaText11: 'What are the required documents for .com.kh or .net.kh domain registration?',
    qnaAnswer11: '1. A copy of National ID Card or Passport',
    'qnaAnswer11.1':
        '2. A copy of Certificate of Incorporation, or an approval letter issued by Ministry of Commerce for operating the business.',
    'qnaAnswer11.2': '3. Company Extract',
    'qnaAnswer11.3': '4. A copy of Certificate of Mark Registration (Optional)',
    qnaText12: 'What are the required documents for .edu.kh domain registration?',
    qnaAnswer12: '1. A copy of National ID Card or Passport',
    'qnaAnswer12.1':
        '2. A copy of Sub Degree, Prakas, or an approval letter issued by Ministry of Youth and Sport for operating the school.3. A copy of Certificate of Mark Registration (Optional)',
    'qnaAnswer12.2': '3. A copy of Certificate of Mark Registration (Optional)',
    qnaText13: 'What are the required documents for .org.kh domain registration?',
    qnaAnswer13: '1. A copy of National ID Card or Passport',
    'qnaAnswer13.1':
        '2. A copy of Sub Degree, Prakas, or an approval letter issued by Ministry of Interior or Ministry of Foreign Affairs and International Cooperation for operating the organization.',
    'qnaAnswer13.2': '3. A copy of Certificate of Mark Registration (Optional)',
    qnaText14: 'Can I change my domain name after I complete my registration process?',
    qnaAnswer14:
        'Once you have completed the registration process, you cannot change/edit your domain name. You need to register another domain name.',
    qnaText15: 'Once I have registered domain name with TRC, will TRC provide me a hosting service?',
    qnaAnswer15:
        'Generally, people always assume that a domain name registration also includes website hosting. Truth to be told, domain name registration and web hosting are completely different services. \n\t\t\t\tTRC provides only domain name registration service.',
    qnaText16: 'How much is domain name annual subscription fee?',
    qnaAnswer16: 'The annual subscription fee start from 120,000 KHR (VAT Tax excluded).',
    qnaText17: 'What will happen if I fail to renew a domain name after expiration date?',
    qnaAnswer17:
        'You domain name will be suspended. To reactivate this domain name, the registrant needs to pay the unpaid subscription fee within 60 days after its expiration date. If you still do not pay within such period, that domain name will be automatically removed from the system and will be available for the public to register.',
    qnaText18:
        'I forgot to pay the annual subscription fee and my domain name was removed from the system. What should I do to get it back?',
    qnaAnswer18:
        'Deleted domain name will be available for the public to register after grace period is passed. In case you want to get your domain name after it was removed, you must register it again.',
    qnaText19: 'Is there any priority of domain name registration?',
    qnaAnswer19: 'The registration process is on a first-come, first-served basis.',
    qnaText20:
        'My domain name is suspended because it is expired. How long will it take to reactivate the domain after I pay the renewal subscription fee?',
    qnaAnswer20: 'Your domain will be reactivated within 24 hours after you pay the subscription fee.',
    qnaText21:
        'Can I get a refund if my registration form is rejected or a refund for the remaining year of a domain I no longer need?',
    qnaAnswer21:
        'No, you will not get a refund. Based on our policies, the paid annual fee for using domain name is non-refundable.',
    qnaText22: 'Can I sell or rent my subdomain to others?',
    qnaAnswer22:
        'No, you are not allowed to sell or rent your subdomain to others. However, you can create subdomain for usage based on the need of your company/institution.',
    qnaText23: 'Why are some of my information available to the public?',
    qnaAnswer23:
        'When you register domain name, you have accepted that some of your information provided will be made available to public through WHOIS Database. \n\t\t\t\tThe information is displayed to provide the public concerning the status of your domain name, and to contact you regarding technical matters related to the domain name.',
    qnaText24: 'Would I be notified before my domain name expires?',
    qnaAnswer24:
        'Yes, we will send you notice emails before your domain name is expired. Please follow the instruction to renew your domain name.',
    qnaText25: 'Are there any reserved domain names or prohibited domain names?',
    qnaAnswer25: 'Yes.',
    qnaText26: 'Are there any premium domain names?',
    qnaAnswer26:
        'Yes. The premium domain names remain reserved on temporary basis and will be available for registration at appropriate time.',
    qnaText27: 'Can I transfer my domain name to other party?',
    qnaAnswer27:
        'Yes, you can transfer your domain name to other party; however, you need to follow the guideline and get an approval from Telecommunication Regulator of Cambodia.',
    qnaText28: 'Is there any dispute resolution in place?',
    qnaAnswer28:
        'Yes. Telecommunication Regulator of Cambodia will act as conciliator to settle any dispute related to domain name.',
    RegisterText: 'Register',
    RegisterDomain: 'Register Domain',
    RenewDomainText: 'RENEW DOMAIN',
    PayDomainText: 'Payment',
    RequesterInformation: 'Registrant Info',
    RequesterText: 'Registrant',
    ResetPasswordText: 'Reset Password',
    ResubmitYourDocument: 'Re-submit Your Document',
    SameAsDelegateManagementAndAdministrationToOfficer1: 'Same as delegate management and administration to officer 1',
    SameAsRequesterInfo: 'Same as Registrant Info',
    SameAsWebmasterInformationText: 'Same as Webmaster​ Information',
    Save: 'Save',
    BackHome: 'Home',
    SearchDomainNameText: 'Search your domain name here',
    SearchYourNextDomainNameText: 'Search your next domain name here',
    searchDomainText: 'Search DOMAIN',
    searchExampleText: 'For example, you can search for “mycoffeeshop.kh”',
    SearchText: 'Search',
    SelectSexText: '--Select Gender--',
    ServerInfoTitle: 'Server Info',
    SexRequireText: 'Gender is require.',
    SexText: 'Gender',
    SendEmailAlert: 'We will send an email to you soon !',
    SignInEmail: 'Sign in Email',
    Submit: 'Submit',
    SubTotalText: 'Sub Total',
    SuggestDomainsText: 'Suggested Domains',
    SummaryText: 'Summary',
    SummaryConfirm: 'Are you sure to confirm this form?',
    TechincalInformationText: 'Techincal Information',
    TermAndConditionDotText: 'Terms and Conditions',
    TermsandPrivacyPolicyText: 'Terms and Privacy Policy',
    ThankPurchased: 'Thank you for purchasing',
    DownloadTitlePage: 'Download Document',
    DownloadNumber: 'No',
    DocFormat: 'Document',
    DocType: 'Document Type',
    DocDownload: 'Download',
    DocMonthlyTax: 'លិខិតប្រកាសពន្ធប្រចាំខែ',
    DocGuideline: 'Guideline',
    DocGuidelineDownload: 'Guideline for Document Requirements',
    DomainDocument: 'document for ',
    DocFor: 'Document For',
    ThankYouForPurchaseText: 'Thank you for purchasing',
    ThankYouForRenewText: 'Thank you for renew',
    ThisDomainIsNotBelongToYouText: 'This domain is not belong to you.',
    ThisDomainNotYetUploadDocumentText: 'This domain is not yet upload document',
    ToPurchaseForUsagePleaseUpload: 'To purchase “{{domain}}”, please upload your document. (pdf) max size 10MB',
    Total: 'Total',
    TotalText: 'Total',
    TRCAddressText: 'Street 102, Sangkat Wat Phnom, \n\t\t\t\t Khan Daun Penh, Phnom Penh',
    Hotline: 'Hotline:',
    Telephone: 'Telephone:',
    FaxText: 'Fax',
    UntilText: 'Until',
    Update: 'Update',
    'update-your-domian-dns': 'Update Your Domain',
    UploadFileText: 'Drag and drop a file or select a document',
    'vat-10': 'VAT',
    feeOnBank: 'Base on bank',
    ViewAllMyDomainText: 'VIEW ALL MY DOMAINS',
    ViewMyDomainText: 'VIEW MY DOMAIN',
    ViewMore: 'See More',
    ViewMoreInfo: 'More Information',
    WebmasterInformationText: 'Webmaster Information',
    WebSiteText: 'Website',
    Welcome: 'Welcome',
    withText: 'with domain ',
    WokringFromDayToDayText: 'Monday - Friday',
    WorkingFromAfternoonTimeToAfternoonTimeText: '12:00 to 17:00',
    WorkingFromMorningTimeToMoringTimeText: '08:00 to 12:00',
    WorkingHourText: 'Working Hours',
    WrongFIleTypeText: 'Your file type format is wrong .',
    From: 'From',
    Year: 'yr',
    YearSubscriptionText: '{0} year subscription',
    YouCanUseOnlyOneDomainText: 'You can only have one domain.',
    YourDocumentHere: 'Your documents (jpg, png or pdf) here, or',
    YouDontHavePermission: "You don't have permission",
    MyDomainDescription: "You can update your domain's details, info and contact details.",
    MyDomainText: 'My Domain',
    MyCart: 'My Cart',
    YourOrderSummaryText: 'Your Order Summary',
    YourProfileInformationIsNotFillInText: 'Your Profile Information is not fill in.',
    ipaddressserver1requiretext: '​IP Address Server 1 is require.',
    ipaddressserver1text: '​IP Address Server 1',
    ipaddressserver2requiretext: '​IP Address Server 2 is require.',
    ipaddressserver2text: '​IP Address Server 2',
    ipaddressserverrequiretext: '​IP Address Server is require.',
    ipaddressservertext: '​IP Address Server',
    AgreeToAgreementText: 'Please check to accept the terms and conditions.',
    Institutional: 'Institutional',
    InstitutionType: 'Institution Type',
    BankFee: 'Bank Fee',
    ExpiredSince: 'Expired since',
    WillExpiredOn: 'Will expire on',
    ExampleText: 'eg :',
    WhoIsSearchText: 'WHOIS Search',
    WhoIsText: 'WHOIS',
    MyCartDescription: 'All domains in here are not belong to you yet.',
    MyCartDescription1: "It's belong to whom is purchased.",
    MyCartText: 'My Cart',
    DeleteDomainButton: 'Delete Domain',
    domainsinfosubtext: 'Search WHOIS database to learn about .kh domain’s ownership.',
    domainsinfotext: "Domain's Information",
    ExpirationDateDescriptionText: 'Knowing the expiration’s date of a taken domain can help you purchase it easier.',
    ExpirationDateText: 'Expiration’s Date',
    'findoutdomain-sownershiptext': "Find Out Domain's Ownership",
    FindTheOwnerText: 'Find the owner of the domain you would like to buy.',
    WhoIsTheOwnerText: 'Who is the owner',
    RemovedText: 'Removed',
    FinishText: 'Finish',
    CancelText: 'Cancel',
    ActionButton: 'Action',
    InReviewText: 'In Reviewing',
    ExpiredText: 'Expired',
    AreYouSureWantToDelThisText: 'Are you sure to delete this domain?',
    ConfirmText: 'Confirm',
    Years: 'yrs',
    DocumentFeeText: 'Document Fee',
    DomainFee: 'Domain Fee',
    'FindOutDomain’sOwnershipText': "Find Out Domain's Ownership",
    "Domain'sInfoText": "Domain's Information",
    "Domain'sInfoSubText": 'Search WHOIS database to learn about .kh domain’s ownership.',

    ResubmitYourDocumentText: 'Re-submit Your Document',
    PleaseFillInYourProfileInformationText: 'Please fill in your profile information to continue',
    Document1: 'Copy of Cambodian identity card or passport (in case of foreign applicant)',
    Document2: 'Copy of Trademark Certificate, if any',
    Document3: 'Copy of company quote (Company Extract)',
    Document4: 'Copy of Commercial Registration Certificate',
    Document5: 'Copy of permit issued by the Ministry of Foreign Affairs and International Cooperation for NGOs',
    Document6:
        'A copy of the sub-decree establishing an educational institution issued by the Royal Government or a permit to open a school issued by the Ministry of Education, Youth and Sports',
    GovDocument1: 'Official application letter from the relevant institution',
    GovDocument2:
        'Certificate of authorization to own the website and attach a copy of the identity card of the Cambodian nationality or the official card of the licensee',
    GovDomainLimitText: 'Sorry, government user account limited to one domain only',
    ProfileUpdatedText: 'Your profile information have been updated successfully',

    RequiredDocumentText: 'Required Document',
    RequiredDocumentDescriptionText:
        'Domain Name Registration Service provide an efficient domain name management system in accordance with the international standards and encourage the usage of domain .kh for all personals, companies, organizations, schools, and government institutions within Cambodia. Choose a domain name you like and suit you.',
    RequiredDocumentGuidelineTitleText: 'Guideline to apply for domain name .kh',
    RequiredDocumentLinkText: 'Click the link below to download documents',

    RequiredDocumentKhTitleText: 'For business and personal websites',
    RequiredDocumentKhDocument1: 'A Certified copy of Khmer national ID',

    RequiredDocumentComTitleText: 'For commercial enterprise, state-owned enterprise and companies website',
    RequiredDocumentComDocument1: 'A Certified copy of certificate of incorporation',
    RequiredDocumentComDocument2: 'A Copied of company extract',
    RequiredDocumentComDocument3: 'A Certified copy of Khmer national ID or passport',
    RequiredDocumentComDocument4: 'A Certified copy of trade mark, if any',

    RequiredDocumentNetTitleText: 'For business and network provider websites',
    RequiredDocumentNetDocument1: 'A Certified copy of certificate of incorporation',
    RequiredDocumentNetDocument2: 'A Copied of company extract',
    RequiredDocumentNetDocument3: 'A Certified copy of Khmer national ID or passport',
    RequiredDocumentNetDocument4: 'A Certified copy of trade mark, if any',

    RequiredDocumentEduTitleText: 'For education websites',
    RequiredDocumentEduDocument1:
        'A Certified copy of Sub Degree issued by Royal Government for university or Prakas or permit issued by Ministry of Education, Youth and Sports for other educational institutions',
    RequiredDocumentEduDocument2: 'A Certified copy of Khmer national ID or passport',

    RequiredDocumentOrgTitleText: 'For organizations, associations or trade unions websites',
    RequiredDocumentOrgDocument1:
        'A Certified copy of permit issued by Ministry of Foreign Affairs and International Cooperation for international NGO or association',
    RequiredDocumentOrgDocument2:
        'A Certified copy of permit issued by Ministry of Interior for local NGO or association',
    RequiredDocumentOrgDocument3:
        "A Certified copy of permit issued by Ministry of Labour and Vocational Training for Trade union or Employers' association, if any",
    RequiredDocumentOrgDocument4: 'A Certified copy of Khmer national ID or passport',

    RequiredDocumentGovTitleText: 'For government websites',

    AboutTitleText: 'Domain .kh',
    AboutDescriptionText:
        'is the Internet country code top-level domain (ccTLD) for Cambodia which is recognized by Internet Corporation for Assigned Names and Numbers (ICANN).',

    AboutValue1Title: 'Our Vision',
    AboutValue1Description:
        'To promote and encourage the use of national domain names, which contribute to transparent public services provision without discrimination.',

    AboutValue2Title: 'Our Mission',
    AboutValue2Description:
        'To legislate regulations and policies of managing and using domain name in a beneficial way to customers by providing transparent public services provision without discrimination.',

    AboutValue3Title: 'Our Value',
    AboutValue3Subtitle1: 'Quality of Service',
    AboutValue3Subtitle1Description:
        'We endeavor to improve the quality of service to ensure that all customer problems are resolved on time without discrimination.',
    AboutValue3Subtitle2: 'Righteous',
    AboutValue3Subtitle2Description:
        'We value customers by guarantee to provide the best services. Every interaction with customers and work practices must be obeyed by ethical standards.',
    AboutValue3Subtitle3: 'Reliance',
    AboutValue3Subtitle3Description:
        'We ensure that all services delivered to customers are transparent and professional without discrimination.',

    AboutWhatWeDoTitle: 'What We Do',
    AboutWhatWeDoDescription:
        'Domain name registration is divided into two levels: First Level and Second Level. The First Level are allocated for persons inside and outside of the Kingdom of Cambodia. For the second level, they are allocated for legal persons that are established or registered in accordance with the laws of the Kingdom of Cambodia as below:',

    PurchaseWizardSuccessText1: 'TRC has received your application form for registering domain name .kh.',
    PurchaseWizardSuccessText2: 'Our team will confirm you via email or you can visit in',

    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    E: 'E',
    F: 'F',
    G: 'G',
    H: 'H'
};

TRANSLATION_EN['DomainStatus' + DOMAIN_STATUS.READY] = 'Ready';
TRANSLATION_EN['DomainStatus' + DOMAIN_STATUS.EXPIRED] = 'Expired';
TRANSLATION_EN['DomainStatus' + DOMAIN_STATUS.DRAW] = 'Draw';
TRANSLATION_EN['DomainStatus' + DOMAIN_STATUS.REMOVED] = 'Removed';
TRANSLATION_EN['DomainStatus' + DOMAIN_STATUS.REQUESTED] = 'Requested';
TRANSLATION_EN['DomainStatus' + DOMAIN_STATUS.PENDING_PAYMENT] = 'Pending Payment';
TRANSLATION_EN['DomainStatus' + DOMAIN_STATUS.PENDING_ACTIVATION] = 'Pending Activation';
TRANSLATION_EN['DomainStatus' + DOMAIN_STATUS.PENDING_DEACTIVATION] = 'Pending Deactivation';
TRANSLATION_EN['DomainStatus' + DOMAIN_STATUS.REJECTED] = 'Rejected';
TRANSLATION_EN['DomainStatus' + DOMAIN_STATUS.DEACTIVATED] = 'Deactivated';

TRANSLATION_EN['DocumentStatus' + DOCUMENT_STATUS.PENDING_RESUBMIT] = 'Pending Resubmit Document';
TRANSLATION_EN['DocumentStatus' + DOCUMENT_STATUS.APPROVED] = 'Document Approved';
TRANSLATION_EN['DocumentStatus' + DOCUMENT_STATUS.REMOVED] = 'Removed';
TRANSLATION_EN['DocumentStatus' + DOCUMENT_STATUS.PENDING_APPROVAL] = 'Pending Document Approval';
TRANSLATION_EN['DocumentStatus' + DOCUMENT_STATUS.DEACTIVATED] = 'Deactivated';
TRANSLATION_EN['DocumentStatus' + DOCUMENT_STATUS.REJECTED] = 'Rejected';
TRANSLATION_EN['DocumentStatus' + DOCUMENT_STATUS.DRAW] = 'Draw';

//Errors
TRANSLATION_EN['ErrorTitle' + HTTP_STATUSES.BAD_REQUEST] = 'Bad Request';
TRANSLATION_EN['ErrorSubtitle' + HTTP_STATUSES.BAD_REQUEST] = 'Sorry, the page you requested is invalid.';

TRANSLATION_EN['ErrorTitle' + HTTP_STATUSES.NOT_FOUND] = 'Not Found';
TRANSLATION_EN['ErrorSubtitle' + HTTP_STATUSES.NOT_FOUND] = 'Sorry, the page you looking for does not exist.';

TRANSLATION_EN['ErrorTitle' + HTTP_STATUSES.SERVER_ERROR] = 'Server Error';
TRANSLATION_EN['ErrorSubtitle' + HTTP_STATUSES.SERVER_ERROR] = 'Sorry, an unhandled error has occurred.';
