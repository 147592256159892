import React, { useEffect, useState } from 'react';

import { CUSTOMER_TYPES } from '../../constants/constant';
import UserProfileService from '../../services/user/UserProfileService';
import { t } from 'i18next';
import { useMsalAccessToken } from '../../hooks/useMsalAccessToken';
import { useNavigate } from "react-router-dom"
import { DomainSearchResult } from 'components/search/DomainSearchResult';
import { SuggestedDomains } from 'components/search/SuggestedDomains';




export const SearchDomainContent = (props) => {
    const { isNotAvailable, query, data } = props;

    let navigate = useNavigate();
    let accessToken = useMsalAccessToken();

    const [customerType, setCustomerType] = useState(1);

    useEffect(() => {
        if (accessToken !== undefined) {
            getUserProfile(accessToken);
        }
    }, [accessToken]);

    const getUserProfile = (accessToken) => {
        let userProfileService = new UserProfileService();
        userProfileService.getProfile(accessToken).then((res) => {
            if (res.data.status === "Ok") {
                let profile = res.data.data;
                setCustomerType(profile.customerType.id);
            }
        })
    }

    const onClickPurchase = (item) => {
        navigate(`/purchase/profile?domain=${item.domainName}&type=${item.domainTypeId}`);
    }

    const DisplayDomainResult = () => {
        if (data) {
            if (data.domainSearchResult) {
                return <DomainSearchResult customerType={customerType} domainSearchResult={data.domainSearchResult} onClickPurchase={onClickPurchase} />
            }
        }

        return <></>;
    }

    const DisplaySuggestedDomains = () => {
        if (customerType === CUSTOMER_TYPES.GOVERNMENT) return <></>;

        if (data && data.suggestedDomains) {
            if (data.suggestedDomains.length > 0) {
                return <SuggestedDomains onClickPurchase={onClickPurchase} suggestedDomains={data.suggestedDomains} />
            }
        }

        return <></>;
    }

    return (
        <div className="content">
            <div className="row">
                {
                    isNotAvailable &&
                    <div className="col-lg-12">
                        <section className="row border-bottom py-4 domain-result">
                            <div className="col-sm-8 dashboard">
                                <p className="text-black-50">{query.get("domain")} <span className="badge badge-danger"> {t("IsNotAvailableText")}</span></p>
                            </div>
                        </section>
                    </div>
                }
                {
                    <div className="col-lg-12">
                        {
                            !isNotAvailable &&
                            DisplayDomainResult()
                        }
                        {
                            DisplaySuggestedDomains()
                        }
                    </div>
                }
            </div>
        </div>
    )
}