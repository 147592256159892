import { HEADER_CONFIG, API_ENDPOINT } from "../apiConfig";
import axios from 'axios';
const API_NAME = "LookedUp";

export function LookupService(){

    const getCountry = async (accessToken) => {
        HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;

        try {
            const response = await axios.get(API_ENDPOINT + API_NAME + "/country", HEADER_CONFIG);
            return response.data;
        } catch (error) {
            return error;
        }
    };

    const getGender = async (accessToken) => {
        HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;

        try {
            const response = await axios.get(API_ENDPOINT + API_NAME + "/gender", HEADER_CONFIG);
            return response.data;
        } catch (error) {
            return error;
        }
    };

    const getDocumentType = async (accessToken) => {
        HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;

        try {
            const response = await axios.get(API_ENDPOINT + API_NAME + "/document-type", HEADER_CONFIG);
            return response.data;
        } catch (error) {
            return error;
        }
    };

    return {getCountry, getGender, getDocumentType};
}