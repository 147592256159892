import { AuthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';
import { Link, useParams } from 'react-router-dom';
import React, { Fragment, useEffect, useState } from 'react';

import { DOMAIN_STATUS } from '../../constants/constant';
import { DomainDetail } from './DomainDetail';
import { DomainStatus } from '../../components/share/DomainStatus';
import { InteractionType } from '@azure/msal-browser';
import PurchaseSummaryService from '../../services/purchase/PurchaseSummaryService';
import moment from 'moment-timezone';
import { useMsalAccessToken } from '../../hooks/useMsalAccessToken';
import { useTranslation } from 'react-i18next';
import { getCurrentLanguage } from 'helpers/getLanguage';

export const DomainDetailIndex = () => {
    const { login, result, error } = useMsalAuthentication(InteractionType.Redirect, {
        extraQueryParameters: { ui_locales: getCurrentLanguage() }
    });

    const { t } = useTranslation();

    const [purchaseSummary, setPurchaseSummary] = useState(null);

    let { domainId } = useParams();
    let accessToken = useMsalAccessToken();

    useEffect(() => {
        accessToken !== undefined && getPurchaseSummary(accessToken);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken]);

    const getPurchaseSummary = (accessToken) => {
        let summaryService = new PurchaseSummaryService();
        summaryService.getSummaryDetail(accessToken, domainId, (data) => {
            //console.log("setPurchaseSummary:", data.data)
            setPurchaseSummary(data.data);
        });
    };

    return (
        <AuthenticatedTemplate>
            <Fragment>
                <div className="purchase-page mb-5 manage-domain">
                    <div className="container-1740">
                        <div className="my-3 mb-lg-4">
                            <Link to="/my-domain" className="caption-3-text">
                                <i className="fa fa-chevron-left" aria-hidden="true"></i>
                                {t('ViewAllMyDomainText')}
                            </Link>
                        </div>
                        {purchaseSummary && (
                            <React.Fragment>
                                <div className="d-flex align-items-center mb-3">
                                    <h2 className="mb-0">{purchaseSummary.domainDetail.name}</h2>
                                    <DomainStatus domainDetail={purchaseSummary.domainDetail} />
                                </div>
                                {purchaseSummary?.domainDetail?.expireDate && (
                                    <p
                                        className={`mb-2 ${
                                            purchaseSummary.domainDetail.statusId === DOMAIN_STATUS.EXPIRED
                                                ? 'text-danger'
                                                : ''
                                        }`}
                                    >
                                        {(purchaseSummary.domainDetail.statusId !== DOMAIN_STATUS.EXPIRED
                                            ? t('WillExpiredOn')
                                            : t('ExpiredSince')) +
                                            ' ' +
                                            moment(purchaseSummary.domainDetail.expireDate).format('DD-MMM-YYYY')}
                                    </p>
                                )}
                            </React.Fragment>
                        )}
                        {purchaseSummary && <DomainDetail purchaseSummary={purchaseSummary} />}
                    </div>
                </div>
            </Fragment>
        </AuthenticatedTemplate>
    );
};
