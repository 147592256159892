import './index.css';

import { AuthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { InteractionType } from '@azure/msal-browser';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'antd';
import { CustomModal } from 'components/share/Modal';
import moment from 'moment-timezone';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SummaryStep } from '../../components/resubmit/steps/SummaryStep';
import { wrapForPreview } from '../../helpers/fileHelper';
import { useMsalAccessToken } from '../../hooks/useMsalAccessToken';
import { summaryValidationOption } from '../../models/validations/purchase/SummaryValidation';
import DomainService from '../../services/domain/DomainService';
import PurchaseProfileService from '../../services/purchase/ProfileService';
import PurchaseSummaryService from '../../services/purchase/PurchaseSummaryService';
import { getCurrentLanguage } from "helpers/getLanguage";
import { ButtonBack } from 'components/purchase/ButtonBack';

export const RenewSummary = () => {
    const { login, result, error } = useMsalAuthentication(InteractionType.Redirect, { extraQueryParameters: { ui_locales: getCurrentLanguage() } });

    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors }
    } = useForm(summaryValidationOption);
    const { isAcceptTerm, isAcceptTerm2 } = watch();

    const { t, i18n } = useTranslation();

    const { confirm } = Modal;

    const [purchaseSummary, setPurchaseSummary] = useState(null);
    const [domainDetail, setDomainDetail] = useState();
    const [domainProfile, setDomainProfile] = useState();
    const [visible, setVisible] = useState(false);

    let navigate = useNavigate();
    let { domainId } = useParams();
    let accessToken = useMsalAccessToken();

    const isAccepted = () => isAcceptTerm === true && isAcceptTerm2 === true;

    useEffect(() => {
        if (accessToken !== undefined) {
            getDomainDetail(accessToken);

            getPurchaseSummary(accessToken);
            getPurchaseProfile(accessToken);
        }
    }, [accessToken]);

    const getDomainDetail = (accessToken) => {
        let domainService = new DomainService();

        domainService.getDomainById(accessToken, domainId).then((res) => {
            setDomainDetail(res.data);

            // console.log(res.result);
        });
    };

    const getPurchaseProfile = (accessToken) => {
        let profileService = new PurchaseProfileService();
        profileService.getDomainProfile(accessToken, domainId, (data) => {
            setDomainProfile(data.data);
        });
    };

    const getPurchaseSummary = (accessToken) => {
        let summaryService = new PurchaseSummaryService();
        summaryService.getSummaryDetail(accessToken, domainId, (data) => {
            setPurchaseSummary(data.data);
            // console.log("setPurchaseSummary:", data.data)
        });
    };

    const sectionPurposeWebsite = () => {
        if (domainProfile) {
            if (domainProfile.isPersonal) {
                return <span className="text-orange">{t('Personal')}</span>;
            } else {
                return <span className="text-orange">{t('Institutional')}</span>;
            }
        }
    };

    const sectionRegistrantInfo = () => {
        if (purchaseSummary?.domainPurchaseProfileRequest) {
            let requester = purchaseSummary.domainPurchaseProfileRequest.requester;
            return (
                <div className="content row">
                    <div className="col-md-6">
                        <div className="property">
                            <div className="field w-50">{t('NameText')}</div>
                            <div className="value w-50">{requester.nameKh}</div>
                        </div>
                        <div className="property">
                            <div className="field w-50">{t('LatinNameText')}</div>
                            <div className="value w-50">{requester.nameEn}</div>
                        </div>
                        <div className="property">
                            <div className="field w-50">{t('SexText')}</div>
                            <div className="value w-50">
                                {i18n.language === 'en' ? requester.gender : requester.genderKh}
                            </div>
                        </div>
                        <div className="property">
                            <div className="field w-50">{t('DateofBirthText')}</div>
                            <div className="value w-50">{moment(requester.dateOfBirth).format('DD MMM YYYY')}</div>
                        </div>
                        <div className="property">
                            <div className="field w-50">{t('NationalityText')}</div>
                            <div className="value w-50">
                                {i18n.language === 'en' ? requester.nationality : requester.nationalityKh}
                            </div>
                        </div>
                        <div className="property">
                            <div className="field w-50">{t('IDOrPassportText')}</div>
                            <div className="value w-50">{requester.idOrPassportNumber}</div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="property">
                            <div className="field w-50">{t('JobTitleText')}</div>
                            <div className="value w-50">{requester.jobTitle}</div>
                        </div>
                        <div className="property">
                            <div className="field w-50">{t('OrganizationOrCompanyText')}</div>
                            <div className="value w-50">{requester.companyName}</div>
                        </div>
                        <div className="property">
                            <div className="field w-50">{t('EmailText')}</div>
                            <div className="value w-50">{requester.email}</div>
                        </div>
                        <div className="property">
                            <div className="field w-50">{t('PhoneNumberText')}</div>
                            <div className="value w-50">{requester.phone}</div>
                        </div>
                        <div className="property">
                            <div className="field w-50">{t('AddressText')}</div>
                            <div className="value w-50">{requester.address}</div>
                        </div>
                    </div>
                </div>
            );
        }
    };

    const sectionDomainManagerContact = () => {
        if (purchaseSummary?.domainPurchaseProfileRequest) {
            if (purchaseSummary.domainPurchaseProfileRequest.isManagerSameRegistrant) {
                return (
                    <div>
                        <p>{t('SameAsRequesterInfo')}</p>
                    </div>
                );
            } else {
                let manager = purchaseSummary.domainPurchaseProfileRequest?.manager;
                return (
                    <div className="content row">
                        <div className="col-md-6">
                            <div className="property">
                                <div className="field w-50">{t('NameText')}</div>
                                <div className="value w-50">{manager.name}</div>
                            </div>
                            <div className="property">
                                <div className="field w-50">{t('OrganizationOrCompanyText')}</div>
                                <div className="value w-50">{manager.companyName}</div>
                            </div>
                            <div className="property">
                                <div className="field w-50">{t('EmailText')}</div>
                                <div className="value w-50">{manager.email}</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="property">
                                <div className="field w-50">{t('PhoneNumberText')}</div>
                                <div className="value w-50">{manager.phone}</div>
                            </div>
                            <div className="property">
                                <div className="field w-50">{t('AddressText')}</div>
                                <div className="value w-50">{manager.address}</div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    };

    const sectionDomainTechnicianContact = () => {
        if (purchaseSummary?.domainPurchaseProfileRequest) {
            if (purchaseSummary.domainPurchaseProfileRequest.isTechnicianSameRegistrant) {
                return (
                    <div>
                        <p>{t('SameAsRequesterInfo')}</p>
                    </div>
                );
            } else {
                let technician = purchaseSummary.domainPurchaseProfileRequest?.technician;
                return (
                    <div className="content row">
                        <div className="col-md-6">
                            <div className="property">
                                <div className="field w-50">{t('NameText')}</div>
                                <div className="value w-50">{technician.name}</div>
                            </div>
                            <div className="property">
                                <div className="field w-50">{t('OrganizationOrCompanyText')}</div>
                                <div className="value w-50">{technician.companyName}</div>
                            </div>
                            <div className="property">
                                <div className="field w-50">{t('EmailText')}</div>
                                <div className="value w-50">{technician.email}</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="property">
                                <div className="field w-50">{t('PhoneNumberText')}</div>
                                <div className="value w-50">{technician.phone}</div>
                            </div>
                            <div className="property">
                                <div className="field w-50">{t('AddressText')}</div>
                                <div className="value w-50">{technician.address}</div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    };

    const sectionDomainBillingContact = () => {
        if (purchaseSummary?.domainPurchaseProfileRequest) {
            if (purchaseSummary.domainPurchaseProfileRequest.isBillingSameRegistrant) {
                return (
                    <div>
                        <p>{t('SameAsRequesterInfo')}</p>
                    </div>
                );
            } else {
                let billing = purchaseSummary.domainPurchaseProfileRequest?.billing;

                return (
                    <div className="content row">
                        <div className="col-md-6">
                            <div className="property">
                                <div className="field w-50">{t('NameText')}</div>
                                <div className="value w-50">{billing.name}</div>
                            </div>
                            <div className="property">
                                <div className="field w-50">{t('OrganizationOrCompanyText')}</div>
                                <div className="value w-50">{billing.companyName}</div>
                            </div>
                            <div className="property">
                                <div className="field w-50">{t('EmailText')}</div>
                                <div className="value w-50">{billing.email}</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="property">
                                <div className="field w-50">{t('PhoneNumberText')}</div>
                                <div className="value w-50">{billing.phone}</div>
                            </div>
                            <div className="property">
                                <div className="field w-50">{t('AddressText')}</div>
                                <div className="value w-50">{billing.address}</div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    };

    const sectionDocument = () => {
        if (purchaseSummary?.domainPurchaseDocumentRequest) {
            let documents = purchaseSummary.domainPurchaseDocumentRequest.documents;
            let docs = [];

            if (documents.length) {
                docs = documents.map((item, index) => {
                    return (
                        <div className="d-flex align-items-center mb-3">
                            <i className="document-icon fas fa-file-alt fa-2x mr-3"></i>
                            <div className="mr-md-0" style={{ marginRight: '0.65rem' }}>
                                <a
                                    className="word-break-all"
                                    rel="noreferrer"
                                    href={wrapForPreview(item.fileUrl)}
                                    target="_blank"
                                >
                                    {i18n.language === 'en' ? item.name : item.nameKh}
                                </a>
                            </div>
                        </div>
                    );
                });
            }

            return docs;
        }
    };

    return (
        <AuthenticatedTemplate>
            <Fragment>
                <div className="purchase-page purchase-wizard body-section">
                    <div className="container-1200 container-1200-max">
                        <div className="heading-section">
                            <h4 className="text-center mb-4">{domainDetail?.name}</h4>
                            <SummaryStep />
                        </div>
                        <div className="content-section summary-section">
                            <form
                                onSubmit={
                                    // handleSubmit(onSubmit)
                                    (e) => {
                                        e.preventDefault();
                                        setVisible(true);
                                    }
                                }
                                noValidate
                                name="form"
                                className="custom-form"
                            >
                                <div className="wizard-navigation">
                                    <div className="d-flex btn-groups">
                                        <ButtonBack onClick={() => navigate(`/resubmit/document/${domainId}`)} />
                                        <button
                                            disabled={isAcceptTerm === false || isAcceptTerm2 === false}
                                            type="submit"
                                            className="btn btn-next" title={t("FinishText")}
                                        >
                                            <i className="fas fa-chevron-right"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="card dns-card card-border-top">
                                    <div className="card-body">
                                        <h4 className="text-primary title d-flex justify-content-between align-items-center​">
                                            1.1. {t('PurposeoftheWebsite')}
                                        </h4>
                                        <div className="mb-3 text-light-700 mr-5 mr-md-0">
                                            {t('IsThisDomainForCompanyOrPersonalUsage')}
                                            &nbsp;
                                            {sectionPurposeWebsite()}
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <h4 className="title d-flex">1.2. {t('RequesterInformation')}</h4>
                                        {sectionRegistrantInfo()}
                                    </div>
                                    <div className="card-body">
                                        <h4 className="title">1.3. {t('DomainManagerContact')}</h4>
                                        {sectionDomainManagerContact()}
                                    </div>
                                    <div className="card-body">
                                        <h4 className="title">1.4. {t('DomainTechnicianContact')}</h4>
                                        {sectionDomainTechnicianContact()}
                                    </div>
                                    <div className="card-body">
                                        <h4 className="title">1.5. {t('DomainPayerContact')}</h4>
                                        {sectionDomainBillingContact()}
                                    </div>
                                    <div className="card-body">
                                        <h4 className="title">2. {t('DocumentText')}</h4>
                                        {sectionDocument()}
                                    </div>
                                    <div className="card-body">
                                        <div className="custom-control custom-checkbox mb-3">
                                            <input
                                                {...register('isAcceptTerm')}
                                                id="agree"
                                                className="custom-control-input"
                                                type="checkbox"
                                                name="isAcceptTerm"
                                            />
                                            <label htmlFor="agree" className="custom-control-label body-2-text">
                                                <span className="text-danger">*</span>
                                                {t('IHaveReadAndAgreeToThe')}{' '}
                                                <Link to="/help/term-condition" target="_blank">
                                                    <u>{t('TermAndConditionDotText')}</u>
                                                </Link>
                                            </label>
                                            <label className="invalid-feedback-override" htmlFor="agree">
                                                {t(errors.isAcceptTerm?.message)}
                                            </label>
                                        </div>
                                        <div className="custom-control custom-checkbox mb-4">
                                            <input
                                                {...register('isAcceptTerm2')}
                                                id="certify"
                                                className="custom-control-input"
                                                type="checkbox"
                                                name="isAcceptTerm2"
                                            />
                                            <label
                                                htmlFor="certify"
                                                className="custom-control-label body-2-text pr-lg-5"
                                            >
                                                <span className="text-danger">*</span>
                                                {t('iCertifyText')}
                                            </label>
                                            <label className="invalid-feedback-override" htmlFor="certify">
                                                {t(errors.isAcceptTerm2?.message)}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="d-flex justify-content-md-end pt-3 mt-auto">
                                            <button
                                                disabled={!isAccepted()}
                                                className={`btn btn-${
                                                    isAccepted() ? 'primary' : 'secondary'
                                                } btn-block`}
                                                type="submit"
                                            >
                                                {t('FinishText')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <CustomModal
                                footer={
                                    <>
                                        <button
                                            onClick={() => setVisible(false)}
                                            type="button"
                                            className="ant-btn ant-btn-default btn-danger"
                                        >
                                            <span>{t('CancelText')}</span>
                                        </button>
                                        <button
                                            onClick={() => {
                                                let summaryService = new PurchaseSummaryService();
                                                summaryService.postPurchaseCompleted(
                                                    accessToken,
                                                    { domainId: domainId },
                                                    (data) => {
                                                        console.log('purchaseSummary:', data.data);
                                                        navigate(`/purchase/success/${domainId}`);
                                                    }
                                                );
                                            }}
                                            type="button"
                                            className="ant-btn ant-btn-primary btn-primary"
                                        >
                                            <span>{t('FinishText')}</span>
                                        </button>
                                    </>
                                }
                                title={<></>}
                                closable={false}
                                visible={visible}
                                maskClosable={false}
                                className="is-confirm"
                            >
                                <div className="ant-modal-body-container">
                                    <span className="ant-modal-body-icon">
                                        <FontAwesomeIcon icon={faCircleExclamation} className="orange" />
                                    </span>
                                    <p className="ant-modal-body-msg">{t('SummaryConfirm')}</p>
                                </div>
                            </CustomModal>
                        </div>
                    </div>
                </div>
            </Fragment>
        </AuthenticatedTemplate>
    );
};
