import { faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InfoMessageModal } from 'components/share/modals/InfoMessageModal';
import { CUSTOMER_TYPES } from 'constants/constant';
import { isDomainSearchValid } from 'helpers/domainHelper';
import { useMsalAccessToken } from 'hooks/useMsalAccessToken';
import { govRootDomains, rootDomains } from 'models/RootDomainList';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import UserProfileService from 'services/user/UserProfileService';
import './search.scss';

const SearchComponent = (props) => {
    const { isWhoIsSearch, placeholder, withSubmitButton = false, withExample = false, isHeader = false, fullWidth = false } = props;

    const [active, setActive] = useState(false);

    const [visible, setVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [rootDomain, setRootDomain] = useState('.kh');
    const [rootDomainType, setRootDomainType] = useState(1);
    const [customerType, setCustomerType] = useState(1);
    const [value, setValue] = useState('');

    const { t } = useTranslation();

    let navigate = useNavigate();
    let accessToken = useMsalAccessToken();

    const getDomainTypes = () => {
        let domainTypes = rootDomains;

        if (customerType === CUSTOMER_TYPES.GOVERNMENT) {
            if (isWhoIsSearch) {
                domainTypes = rootDomains.concat(govRootDomains);
            } else {
                domainTypes = govRootDomains;
            }
        }

        return domainTypes;
    };

    const suffixClicked = (e) => {
        e.stopPropagation();
        setActive(!active);
    };

    const domainClicked = (item) => {
        setRootDomain(item.type);
        setRootDomainType(item.id);
        setActive(false);
    };

    const getUserProfile = (accessToken) => {
        let userProfileService = new UserProfileService();
        userProfileService.getProfile(accessToken).then((res) => {
            if (res.data.status === 'Ok') {
                let profile = res.data.data;
                setCustomerType(profile.customerType.id);

                if (profile.customerType.id === CUSTOMER_TYPES.GOVERNMENT) {
                    let root = govRootDomains[0];
                    setRootDomain(root.type);
                    setRootDomainType(root.id);
                }
            }
        });
    };

    const onSearchDomain = (e) => {
        e.preventDefault();

        if (!value) {
            setVisible(true);
            setErrorMessage(t('DomainNameRequireText'));
            return;
        }

        if (!isDomainSearchValid(value, rootDomain)) {
            setVisible(true);
            setErrorMessage(t('DomainNameNotValidatedText'));
            return;
        }

        let domainSplit = value.split('.');
        if (domainSplit[domainSplit.length - 1] === 'gov' || domainSplit[domainSplit.length - 1] === 'mil') {
            setVisible(true);
            setErrorMessage(t('DomainGovAndMilNotAvailableText'));
            return;
        }

        if (!isWhoIsSearch) {
            if (customerType === CUSTOMER_TYPES.GOVERNMENT) {
                setVisible(true);
                setErrorMessage(t('YouCanUseOnlyOneDomainText'));
                return;
            }
        }
        navigate(`${isWhoIsSearch ? '/whois' : ''}/search-domain?domain=${value}${rootDomain}&type=${rootDomainType}`);
    };

    const handleCloseModal = () => {
        setVisible(false);
    };

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    useEffect(() => {
        document.body.addEventListener('click', () => setActive(false));

        if (accessToken !== undefined) {
            getUserProfile(accessToken);
        }
    }, [accessToken]);

    return (
        <form onSubmit={onSearchDomain} className={`search-container ${isHeader && 'isHeader'}`}>
            <div className={`input-container`}>
                <div className="input">
                    <FontAwesomeIcon icon={faSearch} className="prefix icon-search" />
                    <input
                        autoFocus
                        className={`${fullWidth ? 'mw-100' : ''}`}
                        type="text"
                        onChange={handleChange}
                        placeholder={t(placeholder ? placeholder : 'SearchDomainNameText')}
                    />
                    <span className="suffix" onClick={suffixClicked}>
                        <input type="hidden" name="type" value={rootDomainType} />
                        {rootDomain} <FontAwesomeIcon icon={faChevronDown} />
                    </span>
                    <div className={active ? 'domain-dropdown active' : 'domain-dropdown'}>
                        {getDomainTypes().map((item) => (
                            <span key={`domain-${item.type}`} onClick={() => domainClicked(item)}>
                                {item.type}
                            </span>
                        ))}
                    </div>
                </div>
                {withExample && <span className="example">{t('searchExampleText')}</span>}
            </div>
            {withSubmitButton && (
                <button type="submit" className="button primary btnSubmit">
                    {t('SearchText')}
                </button>
            )}
            <InfoMessageModal
                handleCancel={() => handleCloseModal()}
                cssClassName="alert-warning"
                visible={visible}
                infoMessage={errorMessage}
            />
        </form>
    );
};

export default SearchComponent;
