import React from "react";
import { Modal } from "antd";
export const CustomModal = (props) => {
  return (
    <Modal
      title={props.title}
      visible={props.visible}
      onOk={props.onOk}
      onCancel={props.onCancel}
      footer={props.footer}
      width={props.width}
      height={props.height}
      closable={props.closable}
      className={props.className}
    >
      {props.children}
    </Modal>
  );
};
