import React from "react";
import {DOCUMENT_STATUS} from "../../constants/constant";
import { useTranslation } from "react-i18next";
import { getBulletClasses } from "../../helpers/statusDisplayHelper";

export function DocumentStatus(props) {
    const { t } = useTranslation();
    const statusId = props.statusId;
    // let textStyle = "";
    // if (statusId === DOCUMENT_STATUS.REJECTED) textStyle = "text-danger";

    return (
        <div className="d-flex bullet-wrap align-items-center">
            <div className={`bullet ${getBulletClasses(statusId)}`}></div>
            <div style={{ whiteSpace: 'nowrap', lineHeight: '1.25rem' }}>{ t("DocumentStatus" + statusId) }</div>
        </div>
        
    )
}