
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom"
import { useMsalAccessToken } from '../../hooks/useMsalAccessToken';
import UserProfileService from '../../services/user/UserProfileService';
import { CUSTOMER_TYPES } from '../../constants/constant';
import { t } from 'i18next';

import { DomainSearchResult } from 'components/search/DomainSearchResult';
import { SuggestedDomains } from 'components/search/SuggestedDomains';

export const WhoIsDomainContact = ({ domainContact }) => {
    return (
        <div className="card">
            <div className="card-body">
                <h4 className="card-whoIs-header d-md-block">{t("DomainManagerContact")}</h4>
                <div className="content">
                    <div className="row mb-2">
                        <div className="col-md-6 col-sm-4 card-whoIs-label">{t("NameText")}</div>
                        <div className="col-md-6 col-sm-8">{domainContact.name}</div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-6 col-sm-4 card-whoIs-label">{t("Company")}</div>
                        <div className="col-md-6 col-sm-8">{domainContact.company}</div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-6 col-sm-4 card-whoIs-label">{t("EmailText")}</div>
                        <div className="col-md-6 col-sm-8">{domainContact.email}</div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-6 col-sm-4 card-whoIs-label">{t("PhoneNumberText")}</div>
                        <div className="col-md-6 col-sm-8">{domainContact.phone_number}</div>
                    </div>
                </div>
            </div>
        </div>
    );;
}

export const WhoIsSearchDomainContent = (props) => {
    const { query, data } = props;

    const { whoIsContact, domainSearchResult, suggestedDomains } = data;

    const { t } = useTranslation();

    const [customerType, setCustomerType] = useState(1);

    let navigate = useNavigate();
    let accessToken = useMsalAccessToken();

    let isNotAvailable = whoIsContact == null && (domainSearchResult === null || (domainSearchResult !== null && domainSearchResult.isAvailable === false));

    useEffect(() => {
        if (accessToken !== undefined) {
            getUserProfile(accessToken);
        }
    }, [accessToken]);

    const getUserProfile = (accessToken) => {
        let userProfileService = new UserProfileService();
        userProfileService.getProfile(accessToken).then((res) => {
            if (res.data.status === "Ok") {
                let profile = res.data.data;
                setCustomerType(profile.customerType.id);
            }
        })
    }

    const onClickPurchase = (item) => {
        navigate(`/purchase/profile?domain=${item.domainName}&type=${item.domainTypeId}`);
    }

    const DisplayWhoIsDomain = () => {
        if (whoIsContact) {
            return <WhoIsDomainContact domainContact={whoIsContact} />;
        };
    }

    const DisplayDomainResult = () => {
        if (domainSearchResult && domainSearchResult.isAvailable === true) {
            return <DomainSearchResult customerType={customerType} domainSearchResult={domainSearchResult} onClickPurchase={onClickPurchase} />
        }
    }

    const DisplaySuggestedDomains = () => {
        if (customerType === CUSTOMER_TYPES.GOVERNMENT) return <></>;

        if (suggestedDomains && suggestedDomains.length > 0) {
            return <SuggestedDomains onClickPurchase={onClickPurchase} suggestedDomains={suggestedDomains} />
        }
    }

    return (
        <div className="content">
            <div className="row">
                {
                    isNotAvailable &&
                    <div className="col-lg-12">
                        <section className="row border-bottom py-4 domain-result">
                            <div className="col-sm-8 dashboard">
                                <p className="text-black-50">{query.get("domain")} <span className='badge badge-danger'>{t("IsNotAvailableText")}</span></p>
                            </div>
                        </section>
                    </div>
                }
                {
                    <div className="col-lg-12">
                        {
                            DisplayWhoIsDomain()
                        }
                        {
                            DisplayDomainResult()
                        }
                        {
                            DisplaySuggestedDomains()
                        }
                    </div>
                }
            </div>
        </div>
    )
}