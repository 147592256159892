import React from 'react';
import { useTranslation } from "react-i18next";
import { ReactComponent as SVGDomainInfo } from '../../../src/v2/assets/svg/whois/we.svg';
import { ReactComponent as SVGWhoIs } from '../../../src/v2/assets/svg/whois/search name.svg';
import { ReactComponent as SVGExpiry } from '../../../src/v2/assets/svg/whois/deadline.svg';
import SearchComponent from 'v2/core/components/search/search';
import PageTitle from 'v2/core/components/page-title/page-title';

export const WhoIs = () => {
    const { t } = useTranslation();

    return (
        <div className="domain-search-page body-section">
            {/* <Header isMainPage={false} /> */}
            <div className="container-1200 container-1200-max heading-section">
                <section className="content-section">
                    <PageTitle title={t("FindOutDomain’sOwnershipText")}/>
                    <SearchComponent placeholder="FindOutDomain’sOwnershipText" isWhoIsSearch={true} withSubmitButton fullWidth/>
                </section>
            </div>
            <div className="container-1200 container-1200-max">
                <div className="row mt-5">
                    <div className="col-md-4 border-sm-bottom pl-4 pl-md-2 pb-3 py-md-0">
                        <div className="d-flex d-md-block">
                            <span className="description-icon pr-3">
                                <SVGDomainInfo />
                            </span>
                            <div className="description-block">
                                <h5 className="description-title">{t("Domain'sInfoText")}</h5>
                                <p className="description-text">{t("Domain'sInfoSubText")}</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 border-left border-sm-left-0 border-light border-sm-bottom pl-4 py-3 py-md-0">
                        <div className="d-flex d-md-block">
                            <span className="description-icon">
                                <SVGWhoIs />
                            </span>
                            <div className="description-block">
                                <h5 className="description-title">{t("WhoIsTheOwnerText")}</h5>
                                <p className="description-text">{t('FindTheOwnerText')}</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 border-left border-sm-left-0 border-light pl-4 pt-3 pt-md-0">
                        <div className="d-flex d-md-block">
                            <span className="description-icon pr-2">
                                <SVGExpiry />
                            </span>
                            <div className="description-block">
                                <h5 className="description-title">{t("ExpirationDateText")}</h5>
                                <p className="description-text">{t("ExpirationDateDescriptionText")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}