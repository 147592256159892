import axios from "axios";
import { HEADER_CONFIG, API_ENDPOINT } from "../../apiConfig";
const API_NAME = "Government";
export default class GovernmentServerService {

    async saveServerInfo(accessToken, body) {
        HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
        try {
            const response = await axios.post(API_ENDPOINT + API_NAME + "/server", body, HEADER_CONFIG);
            return response.data;
        } catch (error) {
            return error;
        }
    };

    async getDomainServerInfo(accessToken, domainId) {
        HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
        try {
            const response = await axios
                .get(API_ENDPOINT + `${API_NAME}/server?domainId=${domainId}`, HEADER_CONFIG);
            return response.data;
        } catch (error) {
            return error;
        }
    };
}