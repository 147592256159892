
import { faCircle, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FILE_SIZE_LIMIT } from "../../apiConfig";
import { DocumentValidFileTypes } from "../../models/validations/DocumentValidFileTypes";
import { wrapForPreview } from './../../helpers/fileHelper';

export const GovernmentDocumentRender = ({ domainAttachmentFile, onChangeFile, onRemoveFile, register }) => {
    const { t } = useTranslation();

    const [fileName, setFileName] = useState(new Array(domainAttachmentFile.documents.length));
    const [inputEl, setInputEl] = useState(new Array(domainAttachmentFile.documents.length));

    const onChange = (e, i) => {
        let file = e.target.files[0];
        let fileSize = file.size / 1024 / 1024;
        let isValidFileType = DocumentValidFileTypes.indexOf(file.type) !== -1;

        //TODO: To refactor
        //Temporary to not display name if error file size
        if (isValidFileType && fileSize <= FILE_SIZE_LIMIT) {
            //Set filename		
            let oldArray = [...fileName];
            oldArray[i] = file.name;
            setFileName(oldArray);

            //Store element
            let oldArrayEl = [...inputEl];
            oldArrayEl[i] = e.target;
            setInputEl(oldArrayEl);
        }

        //Continue default
        onChangeFile(e, i);
    }

    const onRemove = (item, i) => {
        //Clear filename
        let oldArray = [...fileName];
        oldArray[i] = '';
        setFileName(oldArray);

        //Clear file input
        if (inputEl[i]) {
            inputEl[i].value = ''
        }

        //Continue default
        onRemoveFile(item, i);
    }

    const getSampleDocumentTranslate = (documentTypeId) => {
        switch (documentTypeId) {
            case 12:
                return t('GovDocument1')
            default:
                return t('GovDocument2');
        }
    }

    const getSampleDocumentUrl = (documentTypeId, preview) => {
        let sampleDocUrl = "https://dnskhblob.blob.core.windows.net/uat/d5bf3b5e-f104-428a-8982-74218eb85957..jpg";

        switch (documentTypeId) {
            case 12:
                sampleDocUrl = "https://skaitemplate.blob.core.windows.net/skaiimage/1c7c329a-5a62-4790-abfd-61adc78050ee..pdf";
                break;
            default:
                sampleDocUrl = "https://dnskhblob.blob.core.windows.net/uat/d5bf3b5e-f104-428a-8982-74218eb85957..jpg";
        }

        if (preview) {
            return wrapForPreview(sampleDocUrl);
        }

        return sampleDocUrl;
    }

    const displayFile = (item, i) => {
        if (fileName[i]) {
            return (
                <p className="text-center mb-0 word-break-all">
                    {fileName[i]}
                </p>
            )
        } else {
            return (
                <>
                    {item.name !== "" && <p className="text-center mb-0 mx-3 mx-md-0">{t('DownloadYourOldDocumentText')}</p>}
                    {item.name !== "" &&
                        <p className="text-center mb-0 word-break-all">
                            <a target="_blank" rel="noreferrer" className="text-center word-break-all" href={wrapForPreview(item.fileUrl)}> {getSampleDocumentTranslate(item.documentTypeId)}{"."}{item.fileType}</a>
                        </p>
                    }
                </>
            )
        }
    }

    return (
        <>
            {
                domainAttachmentFile !== undefined && domainAttachmentFile.documents.map((item, i) => {
                    //console.log("item", item)
                    return (
                        <div className="mb-4">
                            <div className="upload-info mb-3">
                                <div>
                                    <h6 className="mb-2 word-break-all">
                                        {i + 1}- {getSampleDocumentTranslate(item.documentTypeId)}
                                        <span className="text-danger"> *</span>
                                        <span className="ml-2">
                                            <a
                                                target="_blank"
                                                className="text-underline"
                                                rel="noreferrer"
                                                href={getSampleDocumentUrl(item.documentTypeId)}
                                                download>
                                                <FontAwesomeIcon icon={faDownload} />
                                            </a>
                                        </span>
                                    </h6>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="dm-uploader mt-4">
                                <div className='upload-head'>
                                    <div className='dots'>
                                        <FontAwesomeIcon icon={faCircle} fontSize={'14px'} color="#fff"></FontAwesomeIcon>
                                        <FontAwesomeIcon icon={faCircle} fontSize={'14px'} color="#fff"></FontAwesomeIcon>
                                        <FontAwesomeIcon icon={faCircle} fontSize={'14px'} color="#fff"></FontAwesomeIcon>
                                    </div>
                                </div>
                                <div className='upload-icon'>
                                    <svg width="80" height="80" viewBox="0 0 122 122" fill="none">
                                        <g clipPath="url(#clip0)">
                                            <path
                                                d="M101.648 44.845C96.0095 22.3828 73.2297 8.74457 50.7674 14.3831C33.2138 18.7896 20.4678 33.961 19.1546 52.0115C6.69071 54.0669 -1.74709 65.837 0.308333 78.3008C2.13551 89.3813 11.7367 97.4961 22.9666 97.4515H42.0271V89.8273H22.9666C14.5453 89.8273 7.71831 83.0004 7.71831 74.579C7.71831 66.1576 14.5453 59.3307 22.9666 59.3307C25.0721 59.3307 26.7787 57.6241 26.7787 55.5186C26.7597 36.5704 42.105 21.1944 61.0532 21.1755C77.4556 21.1591 91.5753 32.755 94.7482 48.8474C95.0615 50.4542 96.3654 51.6817 97.9884 51.8971C108.41 53.3812 115.655 63.0327 114.171 73.4542C112.839 82.8124 104.849 89.7802 95.3962 89.8273H80.1479V97.4515H95.3962C110.134 97.407 122.045 85.4237 122 70.6861C121.963 58.4184 113.566 47.7562 101.648 44.845Z"
                                                fill="#16347B"
                                            />
                                            <path
                                                d="M58.3811 60.4367L43.1328 75.6851L48.5078 81.0601L57.2756 72.3304V108.888H64.8998V72.3304L73.6295 81.0601L79.0045 75.6851L63.7562 60.4367C62.2692 58.9586 59.8681 58.9586 58.3811 60.4367Z"
                                                fill="#16347B"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="122" height="122" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <div className="custom-file col-md-3 py-0 mx-auto my-3">
                                    <div>
                                        <input 
                                            {...register("domainAttachmentFile")} 
                                            type="file" 
                                            className="custom-file-input" 
                                            title='Click to add Files' 
                                            accept="application/pdf"
                                            onChange={(e) => onChange(e, i)} />
                                    </div>
                                    <label className="custom-file-label text-white mb-0 pt-2 px-0" htmlFor="validatedCustomFile">{t('ChooseFile')}</label>
                                </div>

                                <div className="take_bs_left">
                                    {
                                        displayFile(item, i)
                                    }
                                    {
                                        item.name !== "" &&
                                        <button type="button" onClick={() => onRemove(item, i)} className="btn text-danger text-uppercase d-flex align-items-center mx-auto"><span aria-hidden="true" className="mr-2 font-weight-bold h4 mb-1">&times;</span>{t('RemoveFile')}</button>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })
            }

        </>
    )
}