import React, { Fragment, useEffect, useState } from 'react';

import A3CARD from '../../assets/images/A-3Card_3x.png';
import { DOMAIN_STATUS } from '../../constants/constant';
import ICABAPAY from '../../assets/images/ic_ABA PAY_3x.png';
import ICGNERIC from '../../assets/images/ic_generic_2x.png';
import ICKHQR from '../../assets/images/KHQR.png';
import { InfoMessageModal } from '../../components/share/modals/InfoMessageModal';
import PaymentService from '../../services/payment/PaymentService';
import { numberWithCommas } from '../../helpers/formatter';
import { useMsalAccessToken } from '../../hooks/useMsalAccessToken';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const PaymentForm = ({ domainDetail, paymentOption, domain, year }) => {
    const { t } = useTranslation();
    let { domainId } = useParams();
    const [visible, setVisible] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [paymentOptionSelect, setPaymentOptionSelect] = useState(undefined);

    let accessToken = useMsalAccessToken();

    const paymentService = new PaymentService();
    const [paymentGateway, setPaymentGateway] = useState({
        bankPostApiUrl: '',
        bankPostData: {}
    });

    const [paymentFormRef, setPaymentFormRef] = useState({});
    const vatCharge = (domainFee, documentFee, vat) => {
        return ((domainFee + documentFee) * vat) / 100;
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://checkout.payway.com.kh/plugins/checkout2-0.js';
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    });

    const pay = () => {
        if (paymentMethod === '') {
            setVisible(true);
            return;
        }
        const postData = {
            DomainId: domainId,
            PaymentOption: paymentMethod,
            Year: year
        };
        paymentService
            .getPaymentGateway(accessToken, postData)
            .then((res) => {
                //todo: add error handling in case server return 2XX with some error code in data
                setPaymentGateway(res.data);
                if (paymentMethod !== 'acleda') {
                    // eslint-disable-next-line no-undef
                    AbaPayway.checkout();
                } else {
                    paymentFormRef.submit();
                }
            })
            .catch(function (error) {
                //todo: add error handling
                console.log(error);
            });
    };

    const getPaymentGatewayUrl = () => {
        return paymentGateway.bankPostApiUrl;
    };

    const onChangePaymentMethod = (event) => {
        let value = event.currentTarget.value;
        const paymentOptionTemp = paymentOption;
        if (value === 'abapay') {
            let opt = paymentOptionTemp.filter((i) => i.bankId === 1);
            setPaymentOptionSelect(opt[0]);
        }
        if (value === 'cards') {
            let opt = paymentOptionTemp.filter((i) => i.bankId === 3);
            setPaymentOptionSelect(opt[0]);
        }
        if (value === 'bakong') {
            let opt = paymentOptionTemp.filter((i) => i.bankId === 4);
            setPaymentOptionSelect(opt[0]);
        }
        setPaymentMethod(value);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const isRenew = () => domainDetail.statusId !== DOMAIN_STATUS.PENDING_PAYMENT;
    const documentFee = () => (isRenew() ? 0 : paymentOptionSelect.applicationFee ?? 0);
    const getFeePerYear = () => (isRenew() ? domainDetail.renewPrice : domainDetail.price);
    const getDomainFee = () => {
        if (isRenew()) return domainDetail.renewPrice * +year;

        let total = domainDetail.price;
        if (+year > 1) {
            total += (+year - 1) * domainDetail.renewPrice;
        }

        return total;
    };
    //console.log("isRenew", isRenew());

    return (
        <Fragment>
            <div className="content-section payment-section container-1200">
                <form name="form" className="custom-form">
                    <div className="content d-flex">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="payment pt-3">
                                    <h4 className="mb-4">{t('paymentMethodTitle')}</h4>

                                    <div className="pay-method">
                                        <input
                                            type="radio"
                                            value="abapay"
                                            onChange={(event) => onChangePaymentMethod(event)}
                                            name="paymentOption"
                                            id="aba"
                                            className="input-hidden"
                                        />
                                        <div className="payment-option">
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <div className="form-check-input"></div>
                                                <div className="payment-ui--item">
                                                    <div className="payment-ui--logo">
                                                        <img src={ICABAPAY} alt="ic_generic" width="50px" />
                                                    </div>
                                                    <div className="payment-ui--item-content" htmlFor="aba-card">
                                                        <div className="payment-ui--item-title">ABA PAY</div>
                                                        <div className="payment-ui--item-subtitle">
                                                            {t('abaPayDescription')}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="pay-method">
                                        <input
                                            type="radio"
                                            value="bakong"
                                            onChange={(event) => onChangePaymentMethod(event)}
                                            name="paymentOption"
                                            id="bakong"
                                            className="input-hidden"
                                        />
                                        <div className="payment-option">
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <div className="form-check-input"></div>
                                                <div className="payment-ui--item">
                                                    <div className="payment-ui--logo">
                                                        <img src={ICKHQR} alt="ic_generic" width="50px" />
                                                    </div>
                                                    <div className="payment-ui--item-content" htmlFor="aba-card">
                                                        <div className="payment-ui--item-title">KHQR</div>
                                                        <div className="payment-ui--item-subtitle">
                                                            {t('khqrDescription')}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pay-method">
                                        <input
                                            type="radio"
                                            value="cards"
                                            onChange={(event) => onChangePaymentMethod(event)}
                                            name="paymentOption"
                                            id="aba-card"
                                            className="input-hidden"
                                        />
                                        <div className="payment-option">
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <div className="form-check-input"></div>
                                                <div className="payment-ui--item">
                                                    <div className="payment-ui--logo">
                                                        <img src={ICGNERIC} alt="ic_generic" width="50px" />
                                                    </div>
                                                    <div className="payment-ui--item-content" htmlFor="aba-card">
                                                        <div className="payment-ui--item-title">Credit/Debit Card</div>
                                                        <div className="payment-ui--item-subtitle">
                                                            <img src={A3CARD} alt="Credit/Debit Card" width="96px" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card payment-card order-summary p-0">
                                    <div className="card-header">
                                        <h4>{t('YourOrderSummaryText')}</h4>
                                    </div>
                                    <div className="card-body text-blue">
                                        <div className="d-flex justify-content-between body-text mb-3">
                                            <div>{t('domain')}</div>
                                            <div>{domainDetail?.name}</div>
                                        </div>
                                        {!isRenew() && (
                                            <>
                                                <div className="d-flex justify-content-between body-text mb-3">
                                                    <div>{t('firstYearFee')}</div>
                                                    <div>KHR {numberWithCommas(domainDetail.price)} </div>
                                                </div>
                                                <div className="d-flex justify-content-between body-text mb-3">
                                                    <div>
                                                        {t('fromTwoYearFee')}{' '}
                                                        {+year > 1 && `(${+year - 1} ${t('Years')})`}
                                                    </div>
                                                    <div>
                                                        KHR{' '}
                                                        {+year > 1
                                                            ? numberWithCommas(domainDetail.renewPrice * (+year - 1))
                                                            : 0}
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between body-text mb-3">
                                                    <div>{t('DocumentFeeText')}</div>
                                                    <div>
                                                        KHR{' '}
                                                        {paymentOptionSelect !== undefined
                                                            ? numberWithCommas(paymentOptionSelect.applicationFee)
                                                            : 0}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {isRenew() && (
                                            <div className="d-flex justify-content-between body-text mb-3">
                                                <div>{t('renewPriceFee') + ` (${+year} ${t('Years')})`}</div>
                                                <div>KHR {numberWithCommas(domainDetail.renewPrice * +year)}</div>
                                            </div>
                                        )}
                                        <div className="d-flex justify-content-between body-text mb-3">
                                            <div>{t('vat-10')} </div>
                                            <div>
                                                KHR{' '}
                                                {paymentOptionSelect !== undefined
                                                    ? numberWithCommas(
                                                          vatCharge(
                                                              getDomainFee(),
                                                              paymentOptionSelect.applicationFee,
                                                              paymentOptionSelect.vat
                                                          )
                                                      )
                                                    : 0}
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between body-text mb-3">
                                            <div>{t('BankFee')}</div>
                                            {paymentOptionSelect !== undefined && !paymentOptionSelect.isFeeApply ? (
                                                <div>{t('feeOnBank')}</div>
                                            ) : (
                                                <div>
                                                    KHR{' '}
                                                    {paymentOptionSelect !== undefined
                                                        ? numberWithCommas(paymentOptionSelect.paymentFee)
                                                        : 0}
                                                </div>
                                            )}
                                        </div>
                                        <div className="d-flex justify-content-between body-text">
                                            <div>{t('TotalText')}</div>
                                            {paymentOptionSelect !== undefined && !paymentOptionSelect.isFeeApply ? (
                                                <div>{t('feeOnBank')}</div>
                                            ) : (
                                                <div className="text-orange">
                                                    KHR{' '}
                                                    {paymentOptionSelect !== undefined &&
                                                        numberWithCommas(
                                                            vatCharge(
                                                                getDomainFee(),
                                                                paymentOptionSelect.applicationFee,
                                                                paymentOptionSelect.vat
                                                            ) +
                                                                getDomainFee() +
                                                                documentFee() +
                                                                paymentOptionSelect.paymentFee
                                                        )}
                                                </div>
                                            )}
                                        </div>
                                        <div className="mt-3">
                                            <a onClick={() => pay()} className="btn btn-primary btn-block">
                                                {t('NextText')}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div id="aba_main_modal" className="aba-modal">
                    <div className="aba-modal-content">
                        <form
                            method="POST"
                            target="aba_webservice"
                            action={getPaymentGatewayUrl()}
                            id="aba_merchant_request"
                            ref={setPaymentFormRef}
                        >
                            {Object.entries(paymentGateway.bankPostData).map(([key, value]) => (
                                <input key={'aba' + key} type="hidden" name={key} value={value} />
                            ))}
                        </form>
                    </div>
                </div>
            </div>
            <InfoMessageModal
                cssClassName="alert-warning"
                handleCancel={() => handleCancel()}
                visible={visible}
                infoMessage={t('please-choose-payment-method')}
            />
        </Fragment>
    );
};
