import { DOCUMENT_STATUS, DOMAIN_STATUS } from '../../constants/constant';
import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useState } from 'react';

import { DocumentStatus } from '../../components/share/DocumentStatus';
import { ViewCommentModal } from '../../components/share/modals/ViewCommentModal';
import moment from 'moment-timezone';
import { numberWithCommas } from '../../helpers/formatter';
import { useTranslation } from 'react-i18next';
import { NameValue } from 'components/share/content/NameValue';
import { ShowDocument } from 'components/share/content/ShowDocument';

export const DomainDetail = ({ purchaseSummary }) => {
    const { domainDetail, domainPurchaseProfileRequest, domainPurchaseDocumentRequest, domainPurchaseServerRequest } =
        purchaseSummary;

    const { t, i18n } = useTranslation();

    const [year, setYear] = useState('2');
    const [visibleCommentDialog, setVisibleCommentDialog] = useState(false);

    let navigate = useNavigate();

    const isRenew = () => domainDetail.statusId !== DOMAIN_STATUS.PENDING_PAYMENT;
    const documentFee = () => (isRenew() ? 0 : domainDetail.applicationFee);

    const getFeePerYear = () => (isRenew() ? domainDetail.renewPrice : domainDetail.price);
    // const getDomainFee = () => +year * getFeePerYear();
    const getDomainFee = () => {
        // let total=documentFee+

        if (isRenew()) return domainDetail.renewPrice * +year;

        // isNew domain purchase
        let total = domainDetail.price;
        if (+year > 1) {
            total += (+year - 1) * domainDetail.renewPrice;
        }

        return total;
    };
    const getTotalFee = () => getDomainFee() + documentFee();
    const isShowingPaymentInfo = () =>
        domainDetail.statusId === DOMAIN_STATUS.EXPIRED ||
        domainDetail.statusId === DOMAIN_STATUS.PENDING_ACTIVATION ||
        domainDetail.statusId === DOMAIN_STATUS.PENDING_PAYMENT ||
        domainDetail.statusId === DOMAIN_STATUS.READY;

    const getDomainId = () => domainDetail.id;
    const getDomainName = () => domainDetail.name;

    const getUntilDate = (domainDetail, yearPaid) => {
        let isExpired = domainDetail.statusId === DOMAIN_STATUS.EXPIRED;
        let currentDate = moment();
        let expireDate = currentDate;

        if (domainDetail.expireDate) {
            expireDate = moment(domainDetail.expireDate);
        }

        if (isExpired) {
            let different = moment(currentDate).diff(domainDetail.expireDate, 'days', false);
            if (different >= 60) {
                expireDate = currentDate;

                return expireDate.add(yearPaid, 'year').add(-1, 'day');
            }
        }

        if (isRenew()) {
            return expireDate.add(yearPaid, 'year');
        }

        return expireDate.add(yearPaid, 'year').add(-1, 'day');
    };

    const formatDate = (date) => date.format('DD-MMM-YYYY');

    const formatDateString = (dateString) => formatDate(moment(dateString));

    const PaymentInfo = ({ domainStatusId }) => (
        <div className="card payment-card mb-3 p-0">
            <div className="card-header py-3 d-flex justify-content-between">
                <h4>{t('DomainFee')}</h4>
                <h4>{domainDetail.name}</h4>
            </div>
            <div className="card-body">
                <div className="custom-form">
                    <select
                        className="form-control"
                        onChange={(event) => setYear(event.target.value)}
                        value={`${year}`}
                    >
                        <option value="1">1 {t('Year')}</option>
                        <option value="2">2 {t('Year')}</option>
                        <option value="3">3 {t('Year')}</option>
                        <option value="4">4 {t('Year')}</option>
                        <option value="5">5 {t('Year')}</option>
                    </select>
                    <div className="text-blue mt-2 mb-3">
                        {t('UntilText')} {formatDate(getUntilDate(domainDetail, year))}
                    </div>
                </div>
                {!isRenew() && (
                    <>
                        <div className="d-flex justify-content-between mb-3">
                            <h6 className="text-primary font-weight-bold mb-0">{t('firstYearFee')}</h6>
                            <h6 className="text-primary mb-0">KHR {numberWithCommas(domainDetail.price)}</h6>
                        </div>
                        <div className="d-flex justify-content-between mb-3">
                            <h6 className="text-primary font-weight-bold mb-0">
                                {t('fromTwoYearFee')} {+year > 1 && `(${+year - 1} ${t('Years')})`}
                            </h6>
                            <h6 className="text-primary mb-0">
                                KHR {+year > 1 ? numberWithCommas(domainDetail.renewPrice * (+year - 1)) : 0}
                            </h6>
                        </div>
                        <div className="d-flex justify-content-between mb-3">
                            <h6 className="text-primary font-weight-bold mb-0">{t('DocumentFeeText')}</h6>
                            <h6 className="text-primary mb-0">KHR {numberWithCommas(documentFee())}</h6>
                        </div>
                    </>
                )}
                {isRenew() && (
                    <div className="d-flex justify-content-between mb-3">
                        <h6 className="text-primary font-weight-bold mb-0">{t('renewPriceFee')}</h6>
                        <h6 className="text-primary mb-0">KHR {numberWithCommas(domainDetail.renewPrice)}</h6>
                    </div>
                )}
                <div className="d-flex justify-content-between mb-3">
                    <h6 className="text-primary font-weight-bold mb-0">{t('Total')}</h6>
                    <h6 className="text-orange mb-0">KHR {numberWithCommas(getTotalFee())}</h6>
                </div>
                <div className="custom-form">
                    <div className="mt-3">
                        <Link
                            to={'/payment/' + getDomainId() + '/' + year + '/' + getDomainName()}
                            className="btn btn-primary btn-block"
                        >
                            {t('PayNowText')}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );

    const ServerInfoItem = ({ nameServer, index }) => (
        <div key={nameServer.id}>
            <NameValue name={t('NameServerText') + ' ' + (index + 1)} value={nameServer.nameServer} />
            <NameValue name={t('IPAddress') + ' ' + (index + 1)} value={nameServer.ipAddress} />
        </div>
    );

    const ServerInfo = ({ nameServerList }) => (
        <div className="card-body server-info">
            <h4 className="title text-primary">{t('ServerInfoTitle')}</h4>
            <hr className="my-3" />
            <div className="content">
                {nameServerList.map((server, index) => (
                    <ServerInfoItem key={index} nameServer={server} index={index} />
                ))}
            </div>
            <Link
                to={`/update-server-info/${getDomainId()}`}
                className="btn btn-block btn-outline-primary text-uppercase mt-4"
            >
                {t('AddOrEditDomainInfoText')}
            </Link>
        </div>
    );

    const ProfileAndDocumentTitle = ({ documentStatusId }) => (
        <div className="card-body border-0 pb-0">
            <h4 className="title text-primary">{t('profile-and-documenttitle')}</h4>
            <div>
                <DocumentStatus statusId={documentStatusId} />
            </div>
            <hr className="d-none d-md-block my-3" />
        </div>
    );

    const RegistrantInfo = ({ registrantInfo, isPersonal }) => (
        <div className="card-body border-0 py-0">
            <h4 className="mb-3 d-none d-md-block">{t('RequesterInformation')}</h4>
            {registrantInfo && (
                <React.Fragment>
                    <div className="caption-text d-none d-md-block">
                        {t('PurposeOfDomainText')} {isPersonal ? t('Personal') : t('Company')}
                    </div>
                    <div className="content mt-4">
                        <NameValue name={t('NameText')} value={registrantInfo.nameKh} />
                        <NameValue name={t('LatinNameText')} value={registrantInfo.nameEn} />
                        <NameValue
                            name={t('SexText')}
                            value={i18n.language === 'en' ? registrantInfo.gender : registrantInfo.genderKh}
                        />
                        <NameValue name={t('DateofBirthText')} value={formatDateString(registrantInfo.dateOfBirth)} />
                        <NameValue
                            name={t('NationalityText')}
                            value={i18n.language === 'en' ? registrantInfo.nationality : registrantInfo.nationalityKh}
                        />
                        <NameValue name={t('IDOrPassportText')} value={registrantInfo.idOrPassportNumber} />
                        <NameValue name={t('JobTitleText')} value={registrantInfo.jobTitle} />
                        <NameValue name={t('OrganizationOrCompanyText')} value={registrantInfo.companyName} />
                        <NameValue name={t('EmailText')} value={registrantInfo.email} />
                        <NameValue name={t('PhoneNumberText')} value={registrantInfo.phoneNumber} />
                        <NameValue name={t('AddressText')} value={registrantInfo.address} />
                    </div>
                </React.Fragment>
            )}
        </div>
    );

    const DocumentInfo = ({ documents }) => (
        <div className="card-body border-0 py-0">
            <hr className="my-3" />
            <h4 className="mb-4">{t('DocumentText')}</h4>
            <div className="mb-3">
                {documents.map((document, index) => (
                    <ShowDocument document={document} key={index} />
                ))}
            </div>
        </div>
    );

    const Contact = ({ title, contact }) => (
        <div className="card-body border-0 py-0">
            <hr className="my-3" />
            <h4 className="mb-4">{title}</h4>
            {contact && (
                <div className="content">
                    <NameValue name={t('NameText')} value={contact.name} />
                    <NameValue name={t('OrganizationOrCompanyText')} value={contact.companyName} />
                    <NameValue name={t('EmailText')} value={contact.email} />
                    <NameValue name={t('PhoneNumberText')} value={contact.phone} />
                    <NameValue name={t('AddressText')} value={contact.address} />
                </div>
            )}
        </div>
    );

    const DomainManagerContact = ({ contact }) => <Contact title={t('DomainManagerContact')} contact={contact} />;

    const DomainTechnicianContact = ({ contact }) => <Contact title={t('DomainTechnicianContact')} contact={contact} />;

    const DomainBillingContact = ({ contact }) => <Contact title={t('DomainPayerContact')} contact={contact} />;

    const ResubmitDocument = () => {
        if (domainDetail.documentStatusId !== DOCUMENT_STATUS.PENDING_RESUBMIT) {
            return <React.Fragment />;
        }

        return (
            <React.Fragment>
                <div className="feedback p-3 p-lg-4 mb-2">
                    <p className="mb-2">{t('RequireChanges')}</p>
                    <button
                        type="button"
                        className="btn btn-outline-primary mr-2"
                        onClick={() => setVisibleCommentDialog(true)}
                    >
                        {t('ViewComment')}
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary mr-2"
                        onClick={() => navigate(`/resubmit/profile/${getDomainId()}`)}
                    >
                        {t('ResubmitYourDocumentText')}
                    </button>
                </div>
                <ViewCommentModal
                    domainId={getDomainId()}
                    visible={visibleCommentDialog}
                    handleCancel={() => handleCloseCommentDialog()}
                />
            </React.Fragment>
        );
    };

    const handleCloseCommentDialog = () => {
        setVisibleCommentDialog(false);
    };

    return (
        <div className="content-section">
            <div className="custom-form">
                {ResubmitDocument()}
                <div className="row">
                    <div className="col-md-6">
                        <div className="summary-section">
                            <div className="card dns-card card-border-top">
                                <div className="d-md-none">
                                    <ServerInfo nameServerList={domainPurchaseServerRequest.nameServerList} />
                                </div>
                                <ProfileAndDocumentTitle documentStatusId={domainDetail.documentStatusId} />
                                <RegistrantInfo
                                    registrantInfo={domainPurchaseProfileRequest.requester}
                                    isPersonal={domainPurchaseProfileRequest.isPersonal}
                                />
                                <DocumentInfo documents={domainPurchaseDocumentRequest.documents} />
                                <DomainManagerContact contact={domainPurchaseProfileRequest.manager} />
                                <DomainTechnicianContact contact={domainPurchaseProfileRequest.technician} />
                                <DomainBillingContact contact={domainPurchaseProfileRequest.billing} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="summary-section">
                            {isShowingPaymentInfo() && <PaymentInfo domainStatusId={domainDetail.statusId} />}
                            <div className="card dns-card card-border-top d-none d-md-block ">
                                <ServerInfo nameServerList={domainPurchaseServerRequest.nameServerList} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
