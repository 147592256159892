import { AuthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';
import { Link, useParams } from 'react-router-dom';
import React, { Fragment, useEffect, useState } from 'react';

import { DOMAIN_STATUS } from '../../constants/constant';
import { DomainStatus } from '../../components/share/DomainStatus';
import { GovernmentDomainDetail } from './GovernmentDomainDetail';
import GovernmentSummaryService from '../../services/government/SummaryService';
import { InteractionType } from '@azure/msal-browser';
import moment from 'moment-timezone';
import { useMsalAccessToken } from '../../hooks/useMsalAccessToken';
import { useTranslation } from "react-i18next";
import { getCurrentLanguage } from "helpers/getLanguage";

export const GovernmentDomainDetailIndex = () => {
    const { login, result, error } = useMsalAuthentication(InteractionType.Redirect, { extraQueryParameters: { ui_locales: getCurrentLanguage() } });

    const { t } = useTranslation();

    const [govPurchaseSummary, setGovPurchaseSummary] = useState(null);

    let { domainId } = useParams();
    let accessToken = useMsalAccessToken();

    useEffect(() => {
        accessToken !== undefined && getGovernmentPurchaseSummary(accessToken);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken]);

    const getGovernmentPurchaseSummary = (accessToken) => {
        let summaryService = new GovernmentSummaryService();
        summaryService.getSummaryDetail(
            accessToken,
            domainId,
            (data) => {
                setGovPurchaseSummary(data.data)
            }
        )
    }

    return (
        <AuthenticatedTemplate>
            <Fragment>
                <div className="purchase-page mb-5 manage-domain">
                    <div className="container-1740">
                        <div className="my-3 mb-lg-4">
                            <Link to="/my-domain" className="caption-3-text">
                                <i className="fa fa-chevron-left" aria-hidden="true"></i>
                                {t("ViewAllMyDomainText")}
                            </Link>
                        </div>
                        {
                            govPurchaseSummary &&
                            <React.Fragment>
                                <div className="d-flex align-items-center mb-3">
                                    <h2 className="mb-0">{govPurchaseSummary.domainDetail.name}</h2>
                                    <DomainStatus domainDetail={govPurchaseSummary.domainDetail} />
                                </div>
                                {
                                    govPurchaseSummary?.domainDetail?.expireDate &&
                                    <p className={`mb-2 ${(govPurchaseSummary.domainDetail.statusId === DOMAIN_STATUS.EXPIRED ? "text-danger" : "")}`}>{
                                        (govPurchaseSummary.domainDetail.statusId !== DOMAIN_STATUS.EXPIRED ? t("WillExpiredOn") : t("ExpiredSince")) + " " + moment(govPurchaseSummary.domainDetail.expireDate).format("DD-MMM-YYYY")
                                    }</p>
                                }
                            </React.Fragment>
                        }
                        {
                            govPurchaseSummary &&
                            <GovernmentDomainDetail purchaseSummary={govPurchaseSummary} />
                        }
                    </div>
                </div>
            </Fragment>
        </AuthenticatedTemplate>
    )
}