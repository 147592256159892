
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useEffect, useState } from 'react';
import { useAccount, useMsal } from '@azure/msal-react';
import { tokenRequest } from '../authConfig';

export const useMsalAccessToken = () => {
    const { accounts, inProgress, instance } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [accessToken, setAccessToken] = useState();

    useEffect(() => {
        if (account && inProgress === "none") {
            instance.acquireTokenSilent({
                ...tokenRequest(),
                account: account,
            }).then((response) => {
                setAccessToken(response.accessToken);
            }).catch((error) => {
                // in case if silent token acquisition fails, fallback to an interactive method
                if (error instanceof InteractionRequiredAuthError) {
                    if (account && inProgress === "none") {
                        instance.acquireTokenRedirect({
                            ...tokenRequest(),
                            account: account
                        }).then((response) => {
                            setAccessToken(response.accessToken);
                        }).catch(error => console.log(error));
                    }
                }
            });
        }
    }, [account, inProgress, instance]);

    return accessToken;
}