export const DOMAIN_STATUS = {
    READY: 2,
    EXPIRED: 3,
    DRAW: 4,
    REMOVED: 5,
    REQUESTED: 6,
    PENDING_PAYMENT: 7,
    PENDING_ACTIVATION: 8,
    PENDING_DEACTIVATION: 9,
    REJECTED: 10,
    DEACTIVATED: 11
}

export const DOCUMENT_STATUS = {
    PENDING_RESUBMIT: 2,
    APPROVED: 3,
    REMOVED: 4,
    PENDING_APPROVAL: 5,
    DEACTIVATED: 6,
    REJECTED: 7,
    DRAW: 8
}

export const CUSTOMER_TYPES = {
    NON_GOVERNMENT: 1,
    GOVERNMENT: 2
}

export const DOMAIN_TYPES = {
    KH: 1,
    COM: 3,
    NET: 4,
    ORG: 5,
    EDU: 6,
    GOV: 7,
    MIL: 8
}

export const INSTITUTION_TYPES = {
    PERSONAL: null,
    COMPANY: 1,
    ORGANIZATION: 2,
    EDUCATIONAL_INSTITUTION: 3
}

export const HTTP_STATUSES = {
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    SERVER_ERROR: 500,
}