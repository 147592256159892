import './index.css';

import { AuthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { InteractionType } from '@azure/msal-browser';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'antd';
import { CustomModal } from 'components/share/Modal';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { GovResubmitSummaryStep } from '../../components/government_resubmit/steps/SummaryStep';
import { wrapForPreview } from '../../helpers/fileHelper';
import { useMsalAccessToken } from '../../hooks/useMsalAccessToken';
import { summaryValidationOption } from '../../models/validations/purchase/SummaryValidation';
import DomainService from '../../services/domain/DomainService';
import GovernmentSummaryService from '../../services/government/SummaryService';
import { getCurrentLanguage } from "helpers/getLanguage";
import { ButtonForward } from 'components/purchase/ButtonForward';

export const GovernmentResubmitSummary = () => {
    const { login, result, error } = useMsalAuthentication(InteractionType.Redirect, { extraQueryParameters: { ui_locales: getCurrentLanguage() } });

    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors }
    } = useForm(summaryValidationOption);
    const { isAcceptTerm, isAcceptTerm2 } = watch();

    const { t, i18n } = useTranslation();

    const { confirm } = Modal;

    const [purchaseSummary, setPurchaseSummary] = useState(null);
    const [domainDetail, setDomainDetail] = useState();
    const [visible, setVisible] = useState(false);

    let navigate = useNavigate();
    let { domainId } = useParams();
    let accessToken = useMsalAccessToken();

    const isAccepted = () => isAcceptTerm === true && isAcceptTerm2 === true;

    useEffect(() => {
        if (accessToken !== undefined) {
            getDomainDetail(accessToken);

            getGovernmentSummary(accessToken);
        }
    }, [accessToken]);

    const getDomainDetail = (accessToken) => {
        let domainService = new DomainService();

        domainService.getDomainById(accessToken, domainId).then((res) => {
            setDomainDetail(res.data);
        });
    };

    const getGovernmentSummary = (accessToken) => {
        let summaryService = new GovernmentSummaryService();
        summaryService.getSummaryDetail(accessToken, domainId, (data) => {
            setPurchaseSummary(data.data);
            console.log('setPurchaseSummary:', data.data);
        });
    };

    const onSubmit = (data) => {
        confirm({
            title: t('SummaryConfirm'),
            icon: <ExclamationCircleOutlined />,
            onOk() {
                let summaryService = new GovernmentSummaryService();
                summaryService.postPurchaseCompleted(accessToken, { domainId: domainId }, (data) => {
                    console.log('purchaseSummary:', data.data);
                    navigate(`/purchase/success/${domainId}`);
                });
            },
            okText: t('FinishText'),
            okButtonProps: {
                className: 'btn-primary rounded-0'
            },
            onCancel() {},
            cancelText: t('CancelText'),
            cancelButtonProps: {
                className: 'btn-danger'
            }
        });
    };

    const sectionPurposeWebsite = () => {
        if (purchaseSummary?.governmentDomainProfileRequest) {
            let requester = purchaseSummary.governmentDomainProfileRequest;
            return (
                <div className="content row">
                    <div className="col-md-6">
                        <div className="property">
                            <div className="field w-50">{t('MinistryName')}</div>
                            <div className="value w-50">{requester.ministryName}</div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="property">
                            <div className="field w-50">{t('Purpose')}</div>
                            <div className="value w-50">{requester.purpose}</div>
                        </div>
                    </div>
                </div>
            );
        }
    };

    const sectionDelegateOfficer1 = () => {
        if (purchaseSummary?.governmentDomainProfileRequest) {
            let requester = purchaseSummary.governmentDomainProfileRequest.officer1;
            return (
                <div className="content row">
                    <div className="col-md-6">
                        <div className="property">
                            <div className="field w-50">{t('NameText')}</div>
                            <div className="value w-50">{requester.name}</div>
                        </div>
                        <div className="property">
                            <div className="field w-50">{t('JobTitleText')}</div>
                            <div className="value w-50">{requester.jobTitle}</div>
                        </div>
                        <div className="property">
                            <div className="field w-50">{t('CivilServantID')}</div>
                            <div className="value w-50">{requester.code}</div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="property">
                            <div className="field w-50">{t('PhoneNumberText')}</div>
                            <div className="value w-50">{requester.phone}</div>
                        </div>
                        <div className="property">
                            <div className="field w-50">{t('EmailText')}</div>
                            <div className="value w-50">{requester.email}</div>
                        </div>
                    </div>
                </div>
            );
        }
    };

    const sectionDelegateOfficer2 = () => {
        if (purchaseSummary?.governmentDomainProfileRequest) {
            let requester = purchaseSummary.governmentDomainProfileRequest.officer2;
            return (
                <div className="content row">
                    <div className="col-md-6">
                        <div className="property">
                            <div className="field w-50">{t('NameText')}</div>
                            <div className="value w-50">{requester.name}</div>
                        </div>
                        <div className="property">
                            <div className="field w-50">{t('JobTitleText')}</div>
                            <div className="value w-50">{requester.jobTitle}</div>
                        </div>
                        <div className="property">
                            <div className="field w-50">{t('CivilServantID')}</div>
                            <div className="value w-50">{requester.code}</div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="property">
                            <div className="field w-50">{t('PhoneNumberText')}</div>
                            <div className="value w-50">{requester.phone}</div>
                        </div>
                        <div className="property">
                            <div className="field w-50">{t('EmailText')}</div>
                            <div className="value w-50">{requester.email}</div>
                        </div>
                    </div>
                </div>
            );
        }
    };

    const sectionDelegateOfficer3 = () => {
        if (purchaseSummary?.governmentDomainProfileRequest) {
            let requester = purchaseSummary.governmentDomainProfileRequest.officer3;
            return (
                <div className="content row">
                    <div className="col-md-6">
                        <div className="property">
                            <div className="field w-50">{t('NameText')}</div>
                            <div className="value w-50">{requester.name}</div>
                        </div>
                        <div className="property">
                            <div className="field w-50">{t('JobTitleText')}</div>
                            <div className="value w-50">{requester.jobTitle}</div>
                        </div>
                        <div className="property">
                            <div className="field w-50">{t('CivilServantID')}</div>
                            <div className="value w-50">{requester.code}</div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="property">
                            <div className="field w-50">{t('PhoneNumberText')}</div>
                            <div className="value w-50">{requester.phone}</div>
                        </div>
                        <div className="property">
                            <div className="field w-50">{t('EmailText')}</div>
                            <div className="value w-50">{requester.email}</div>
                        </div>
                    </div>
                </div>
            );
        }
    };

    const sectionDocument = () => {
        if (purchaseSummary?.domainPurchaseDocumentRequest) {
            let documents = purchaseSummary.domainPurchaseDocumentRequest.documents;
            let docs = [];

            if (documents.length) {
                docs = documents.map((item, index) => {
                    return (
                        <div className="d-flex align-items-center mb-3">
                            <i className="document-icon fas fa-file-alt fa-2x mr-3"></i>
                            <div className="mr-md-0" style={{ marginRight: '0.65rem' }}>
                                <a
                                    className="word-break-all"
                                    rel="noreferrer"
                                    href={wrapForPreview(item.fileUrl)}
                                    target="_blank"
                                >
                                    {i18n.language === 'en' ? item.name : item.nameKh}
                                </a>
                            </div>
                        </div>
                    );
                });
            }

            return docs;
        }
    };

    return (
        <AuthenticatedTemplate>
            <Fragment>
                <div className="purchase-page purchase-wizard body-section">
                    <div className="container-1200 container-1200-max">
                        <div className="heading-section">
                            <h4 className="text-center mb-4">{domainDetail?.name}</h4>
                            <GovResubmitSummaryStep />
                        </div>
                        <div className="content-section summary-section">
                            <form
                                onSubmit={
                                    // handleSubmit(onSubmit)
                                    (e) => {
                                        e.preventDefault();
                                        setVisible(true);
                                    }
                                }
                                noValidate
                                name="form"
                                className="custom-form"
                            >
                                <div className="wizard-navigation">
                                    <div className="d-flex btn-groups">
                                        <Link to={`/government-resubmit/document/${domainId}`} className="btn btn-prev" title={t("Back")}>
                                            <i className="fas fa-chevron-left"></i>
                                        </Link>                                        
                                        <button
                                            disabled={isAcceptTerm === false || isAcceptTerm2 === false}
                                            type="submit"
                                            className="btn btn-next" title={t("FinishText")}
                                        >
                                            <i className="fas fa-chevron-right"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="card dns-card card-border-top">
                                    <div className="card-body">
                                        <h4 className="text-primary title d-flex justify-content-between align-items-center​">
                                            1.1. {t('PurposeoftheWebsite')}
                                        </h4>
                                        <div className="mb-3 caption-text text-light-700 mr-5 mr-md-0"></div>
                                        {sectionPurposeWebsite()}
                                    </div>
                                    <div className="card-body">
                                        <h4 className="title d-flex">
                                            1.2. {t('DelegateManagementAndAdministrationToOfficer1')}
                                        </h4>
                                        {sectionDelegateOfficer1()}
                                    </div>
                                    <div className="card-body">
                                        <h4 className="title">
                                            1.3. {t('DelegateManagementAndAdministrationToOfficer2')}
                                        </h4>
                                        {sectionDelegateOfficer2()}
                                    </div>
                                    <div className="card-body">
                                        <h4 className="title">
                                            1.4. {t('DelegateManagementAndAdministrationToOfficer3')}
                                        </h4>
                                        {sectionDelegateOfficer3()}
                                    </div>
                                    <div className="card-body">
                                        <h4 className="title">2. {t('DocumentText')}</h4>
                                        {sectionDocument()}
                                    </div>
                                    <div className="card-body">
                                        <div className="custom-control custom-checkbox mb-3">
                                            <input
                                                {...register('isAcceptTerm')}
                                                id="agree"
                                                className="custom-control-input"
                                                type="checkbox"
                                                name="isAcceptTerm"
                                            />
                                            <label htmlFor="agree" className="custom-control-label body-2-text">
                                                <span className="text-danger">*</span>
                                                {t('IHaveReadAndAgreeToThe')}{' '}
                                                <Link to="/help/term-condition" target="_blank">
                                                    <u>{t('TermAndConditionDotText')}</u>
                                                </Link>
                                            </label>
                                            <label className="invalid-feedback-override" htmlFor="agree">
                                                {t(errors.isAcceptTerm?.message)}
                                            </label>
                                        </div>
                                        <div className="custom-control custom-checkbox mb-4">
                                            <input
                                                {...register('isAcceptTerm2')}
                                                id="certify"
                                                className="custom-control-input"
                                                type="checkbox"
                                                name="isAcceptTerm2"
                                            />
                                            <label htmlFor="certify" className="custom-control-label body-2-text">
                                                <span className="text-danger">*</span>
                                                {t('iCertifyText')}
                                            </label>
                                            <label className="invalid-feedback-override" htmlFor="certify">
                                                {t(errors.isAcceptTerm2?.message)}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="d-flex justify-content-md-end pt-3 mt-auto">
                                            <button
                                                disabled={!isAccepted()}
                                                className={`btn btn-${
                                                    isAccepted() ? 'primary' : 'secondary'
                                                } btn-block`}
                                                type="submit"
                                            >
                                                {t('FinishText')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <CustomModal
                                footer={
                                    <>
                                        <button
                                            onClick={() => setVisible(false)}
                                            type="button"
                                            className="ant-btn ant-btn-default btn-danger"
                                        >
                                            <span>{t('CancelText')}</span>
                                        </button>
                                        <button
                                            onClick={() => {
                                                let summaryService = new GovernmentSummaryService();
                                                summaryService.postPurchaseCompleted(
                                                    accessToken,
                                                    { domainId: domainId },
                                                    (data) => {
                                                        console.log('purchaseSummary:', data.data);
                                                        navigate(`/purchase/success/${domainId}`);
                                                    }
                                                );
                                            }}
                                            type="button"
                                            className="ant-btn ant-btn-primary btn-primary"
                                        >
                                            <span>{t('FinishText')}</span>
                                        </button>
                                    </>
                                }
                                title={<></>}
                                closable={false}
                                visible={visible}
                                maskClosable={false}
                                className="is-confirm"
                            >
                                <div className="ant-modal-body-container">
                                    <span className="ant-modal-body-icon">
                                        <FontAwesomeIcon icon={faCircleExclamation} className="orange" />
                                    </span>
                                    <p className="ant-modal-body-msg">{t('SummaryConfirm')}</p>
                                </div>
                            </CustomModal>
                        </div>
                    </div>
                </div>
            </Fragment>
        </AuthenticatedTemplate>
    );
};
