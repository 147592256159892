import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

import imgComment from '../../../../assets/images/comment.png';
import webpComment from '../../../../assets/images/comment.webp';
import Picture from '../../picture/picture';

import './fixed-actions.scss';

const FixedActions = () => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const [showScrollTop, setScrollTop] = useState(true);

    const showHideScrollTop = () => {
        const scroll = document.documentElement.scrollTop || document.body.scrollTop;
        let offsetTop = 52;
        if (scroll >= offsetTop) setScrollTop(true);
        else setScrollTop(false);
    };

    useEffect(() => {
        showHideScrollTop();
        document.addEventListener('scroll', (e) => {
            showHideScrollTop();
        });
    }, []);

    return (
        <div className="fixed-actions">
            {showScrollTop && (
                <button onClick={scrollToTop} className="scroll-top">
                    <FontAwesomeIcon icon={faCaretUp} />
                </button>
            )}

            <a href="https://t.me/domain_kh_bot" target="_blank" rel="noopener noreferrer" className="action-chat" title="Chat on Telegram">
                <Picture imgSrc={imgComment} webpSrc={webpComment} />
            </a>
        </div>
    );
};

export default FixedActions;
