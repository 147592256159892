import { AuthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';
import { Link, useNavigate } from 'react-router-dom';
import React, { Fragment, useEffect, useState } from 'react';

import { CUSTOMER_TYPES } from '../../constants/constant';
import { CustomSpinner } from "../../components/share/Spinner";
import CustomerService from '../../services/customer/CustomerService';
import { InteractionType } from '@azure/msal-browser';
import { Modal } from 'antd';
import UserProfileService from '../../services/user/UserProfileService';
import { useMsalAccessToken } from '../../hooks/useMsalAccessToken';
import { useTranslation } from "react-i18next";
import { faCircleExclamation, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomModal from 'v2/core/components/antd/modal/custom-modal';
import SearchComponent from 'v2/core/components/search/search';
import { getCurrentLanguage } from "helpers/getLanguage";

export const MyCart = () => {
    const { login, result, error } = useMsalAuthentication(InteractionType.Redirect, { extraQueryParameters: { ui_locales: getCurrentLanguage() } });

    const { t } = useTranslation();

    const { confirm } = Modal;
    const navigate = useNavigate();
    const [myCartList, setMyCartList] = useState([]);
    const [loading, setLoading] = useState('none')
    const [userProfile, setUserProfile] = useState(undefined);
    const [customerType, setCustomerType] = useState(null);
    const [selectedId, setSelectedId] = useState();
    const [visible, setVisible] = useState(false);

    let accessToken = useMsalAccessToken();

    useEffect(() => {
        if (accessToken !== undefined) {
            getMyCart(accessToken);
            getUserProfile(accessToken);
        }

    }, [accessToken]);

    const getUserProfile = (accessToken) => {
        let userService = new UserProfileService();
        userService.getProfile(accessToken).then((res) => {
            if (res.status === 200) {
                let userProfile = res.data.data;
                setCustomerType(userProfile.customerType.id);

                setUserProfile(userProfile);
                if (!userProfile.isProfileCompleted) {
                    navigate("/my-profile");
                }
            }
            console.log("user profile ", res);
        })
    }

    const getMyCart = (accessToken) => {
        setLoading('');
        let customerService = new CustomerService();
        customerService.getCustomerCart(accessToken).then((res) => {
            // console.log("my cart", res);
            setMyCartList(res.data.data);
            setLoading('none');
        })
    }

    const confirmRemoveCart = (id) => {
        // confirm({
        //     title: t("AreYouSureWantToDelThisText"),
        //     icon: <ExclamationCircleOutlined />,
        //     onOk() {
        //         removeCart(accessToken, { id: id });
        //     },
        //     okText: t("ConfirmText"),
        //     okButtonProps: {
        //         className: "btn-primary rounded-0"
        //     },
        //     onCancel() { },
        //     cancelText: t("CloseText"),
        //     cancelButtonProps: {
        //         className: "btn-danger"
        //     }
        // });
        setSelectedId(id);
        setVisible(true);
    }

    const removeCart = (accessToken, body) => {
        let customerService = new CustomerService();
        customerService.removeCustomerCart(accessToken, body).then((res) => {
            if (res.status === 200) {
                getMyCart(accessToken);
            }
        });
    }

    return (
        <AuthenticatedTemplate>
            <Fragment>
                <div className="my-cart-page domain-list dashboard body-section">
                    <div className="container-1200 container-1200-max">

                        <div className="mb-3">
                            <SearchComponent placeholder={`SearchYourNextDomainNameText`} withSubmitButton fullWidth />
                        </div>
                        <div>
                            <h5 className="page-description word-break-all">{t('MyCartDescription')}</h5>
                            <h5 className="page-description word-break-all">{t('MyCartDescription1')}</h5>
                            <h4 className="table-head-text">{t('DomainText')}</h4>
                        </div>
                        {
                            customerType !== undefined &&
                            <table className="table my-cart">
                                <thead>
                                    <tr>
                                        <th className="mb-3">N<sup>0</sup></th>
                                        <th>{t('DomainText')}</th>
                                        <th>{t('DocumentStatus')}</th>
                                        {
                                            customerType !== CUSTOMER_TYPES.GOVERNMENT &&
                                            <th>{t('ActionButton')}</th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    <CustomSpinner display={loading} />
                                    {
                                        myCartList.length > 0 &&
                                        myCartList.map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{i + 1}|</td>
                                                    <td>
                                                        <div>
                                                            <div>
                                                                {
                                                                    customerType === CUSTOMER_TYPES.GOVERNMENT ?
                                                                        <Link to={`/government/profile/${item.id}`}>{item.name}</Link>
                                                                        :
                                                                        <Link to={`/purchase/profile/${item.id}?domain=${item.name}&type=${item.domain_type_id}`}>{item.name}</Link>
                                                                }
                                                                {/* <span className={`badge ${getBadgeClasses(item.domain_status_id)} mx-2`}>{t("DomainStatus" + item.domain_status_id)}</span> */}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <div className="d-flex align-items-center">
                                                                <div className="bullet bullet-warning"></div>
                                                                <div>{t("DocumentStatus" + item.document_status_id)}</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    {
                                                        customerType !== CUSTOMER_TYPES.GOVERNMENT &&
                                                        <td>
                                                            <button onClick={() => confirmRemoveCart(item.id)} className="btn">
                                                                <FontAwesomeIcon icon={faTrashCan} className="text-danger" />
                                                            </button>
                                                        </td>
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        }

                        <CustomModal
                            footer={
                                <>
                                    <button
                                        onClick={() => setVisible(false)}
                                        type="button"
                                        className="ant-btn ant-btn-default btn-danger"
                                    >
                                        <span>{t('CancelText')}</span>
                                    </button>
                                    <button
                                        onClick={() => {
                                            removeCart(accessToken, { id: selectedId });
                                            setVisible(false);
                                        }}
                                        type="button"
                                        className="ant-btn ant-btn-primary btn-primary"
                                    >
                                        <span>{t("ConfirmText")}</span>
                                    </button>
                                </>
                            }
                            title={<></>}
                            closable={false}
                            visible={visible}
                            maskClosable={false}
                            className="is-confirm"
                        >
                            <div className="ant-modal-body-container">
                                <span className="ant-modal-body-icon">
                                    <FontAwesomeIcon icon={faCircleExclamation} className="orange" />
                                </span>
                                <p className="ant-modal-body-msg">{t("AreYouSureWantToDelThisText")}</p>
                            </div>
                        </CustomModal>
                    </div>
                </div>

            </Fragment>
        </AuthenticatedTemplate>
    )
}