import React, {  } from 'react';

import { Button } from 'antd';
import { t } from 'i18next';

import imgCartwhite from '../../v2/assets/images/cart-white.png';
import webpCartwhite from '../../v2/assets/images/cart-white.webp';


import Picture from 'v2/core/components/picture/picture';

export const SuggestedDomains = ({ suggestedDomains, onClickPurchase }) => {
  return (
      <React.Fragment>
          <h4 className="my-3 suggest-text">{t("SuggestDomainsText")}</h4>
          <section>
              <ul className="list-unstyled">
                  {
                      suggestedDomains.map((item, i) => (
                          <li key={i} className="s-border-bottom mt-3">
                              <div className="d-flex justify-content-between align-items-center pb-3">
                                  <div className="d-flex suggested-domain-item">
                                      {item.domainName}
                                      {/* <span className="text-light-100 mt-2 d-md-none">{item.price}</span> */}
                                  </div>
                                  <div className="d-flex ml-auto align-items-center">
                                      <Button onClick={() => onClickPurchase(item)} className="btn btn-primary btn-purchase">
                                          <Picture imgSrc={imgCartwhite} webpSrc={webpCartwhite} />&nbsp;
                                          {t("PurchaseNowText")}
                                      </Button>
                                  </div>
                              </div>
                          </li>
                      ))
                  }
              </ul>
          </section>
      </React.Fragment>
  )
}