import DocumentFilterSvg from '../../../assets/images/document-filter.svg';
import DomainFilterSvg from '../../../assets/images/domain-filter.svg';
import SummaryFilterSvg from '../../../assets/images/summary-filter.svg';
import ProfileSvg from '../../../assets/images/profile.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export const GovernmentProfileStep = () => {
    const { t } = useTranslation();
    return (
        <nav aria-label="breadcrumb">
            <ol className="d-flex breadcrumb col-xl-12 mx-auto">
                <li className="active"><Link to="/government/profile"><span className="circle"><img src={ProfileSvg} width="30px" alt='' /></span>1. {t('ProfileText')}</Link></li>
                <li><a href="/#" className="disabled"><span className="circle"><img src={DocumentFilterSvg} width="30px" alt='' /></span>2. {t('DocumentText')}</a></li>
                <li><a href="/#" className="disabled"><span className="circle"><img src={DomainFilterSvg} width="30px" alt='' /></span>3. {t('DomainInformationText')}</a></li>
                <li><a href="/#" className="disabled"><span className="circle"><img src={SummaryFilterSvg} width="30px" alt='' /></span>4. {t('SummaryText')}</a></li>
            </ol>
        </nav>

    )
}