import React from "react";
import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";

import Routers from "./routers";
function App({ instance }) {
  return (
    <BrowserRouter>
      <MsalProvider instance={instance}>
        <Routers />
      </MsalProvider>
    </BrowserRouter>
  );
}
export default App;
