import { t } from "i18next";
import React from "react";

export function ButtonForward(props) {
  return (
    <button
      hidden={props.hidden}
      onClick={props.onClick}
      size={props.size}
      type="submit"
      disabled={props.disabled}
      icon={props.icon}
      style={props.style}
      htmlType={props.htmlType}
      className="btn btn-next"
      title={t("NextText")}
      danger={props.danger}
      shape={props.shape}
      loading={props.loading}
    >
      <i className="fas fa-chevron-right"></i>
    </button>
  );
}