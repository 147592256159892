export const documentTypeList = [
	{
		sampleUrl: 'https://dnskhblob.blob.core.windows.net/prd/1.%20National%20ID%20Card.pdf',
		isRequired: true,
		id: 19,
		text: 'A Certified copy of Khmer national ID\r\n',
		textKh: 'ច្បាប់ចម្លងអត្តសញ្ញាណបណ្ណសញ្ជាតិខ្មែរ\r\n'
	},
	{
		sampleUrl: 'https://dnskhblob.blob.core.windows.net/prd/514dcc12-e2f5-4430-b6e0-9912cef0fd65..pdf',
		isRequired: true,
		id: 20,
		text: 'A Certified copy of certificate of incorporation\r\n',
		textKh: 'ច្បាប់ចម្លងវិញ្ញាបនបត្របញ្ជាក់ការចុះឈ្មោះក្នុងបញ្ជីពាណិជ្ជកម្ម\r\n'
	},
	{
		sampleUrl: 'https://dnskhblob.blob.core.windows.net/prd/bdd331c5-aebd-4061-868f-cc5c918cd889..pdf',
		isRequired: true,
		id: 21,
		text: 'A Copied of company extract\r\n',
		textKh: 'ច្បាប់ចម្លងសម្រង់ក្រុមហ៊ុន\r\n'
	},
	{
		sampleUrl: 'https://dnskhblob.blob.core.windows.net/prd/97e79069-9787-4ec1-91e9-6af7123e05e6..pdf',
		isRequired: true,
		id: 22,
		text: 'A Certified copy of Khmer national ID or passport\r\n',
		textKh: 'ច្បាប់ចម្លងអត្តសញ្ញាណបណ្ណសញ្ជាតិខ្មែរ ឬ លិខិតឆ្លងដែន\r\n'
	},
	{
		sampleUrl: 'https://dnskhblob.blob.core.windows.net/prd/ea026c66-59b0-4beb-b74f-9a7c07b8fd88..pdf',
		isRequired: false,
		id: 23,
		text: 'A Certified copy of trade mark, if any\r\n',
		textKh: 'ច្បាប់ចម្លងវិញ្ញាបនបត្រចុះបញ្ជីម៉ាក (ករណីប្រសិនបើមាន)\r\n'
	},
	{
		sampleUrl: 'https://dnskhblob.blob.core.windows.net/prd/1.%20Doc%20Proof%20as%20ORG%20-%20Sample.pdf',
		isRequired: true,
		id: 24,
		text:
			'A certified copy of the approval letter issued by the Ministry of Foreign Affairs and International Cooperation for non-governmental organization (NGO), or by the Ministry of Interior of Cambodia for local organizations or associations\r\n',
		textKh:
			'ច្បាប់ចម្លងលិខិតអនុញ្ញាតដែលចេញដោយក្រសួងការបរទេស និងសហប្រតិបត្តិការអន្ដរជាតិសម្រាប់អង្គការក្រៅរដ្ឋាភិបាល ឬ ច្បាប់ចម្លងលិខិតអនុញ្ញាតដែលចេញដោយក្រសួងមហាផ្ទៃសម្រាប់អង្គការ ឬសមាគមក្នុងស្រុក\r\n'
	},
	{
		sampleUrl: 'https://dnskhblob.blob.core.windows.net/prd/1.%20Doc%20Proof%20as%20EDU%20-%20Sample.pdf',
		isRequired: true,
		id: 25,
		text:
			'A certified copy of the sub-decree establishing the school issued by the government or the permit to establish the school issued by the Ministry of Education, Youth and Sports\r\n',
		textKh: 'ច្បាប់ចម្លងនៃអនុក្រឹត្យបង្កើតគ្រឹះស្ថានសិក្សាដែលចេញដោយរាជរដ្ឋាភិបាល ប្រកាសឬលិខិតអនុញ្ញតឱ្យបើកសាលាដែលចេញដោយក្រសួងអប់រំ យុវជន និងកីឡា\r\n'
	}
];
