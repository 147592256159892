import { httpApi } from "../../helpers/httpApi";
export default class PurchaseSummaryService {

    getSummary(accessToken, domainId, successCallBack) {
        const uri = "Purchase/summary";
        httpApi.post(accessToken, uri, { "domainId": domainId }, successCallBack);
    };

    getSummaryDetail(accessToken, domainId, successCallBack) {
        const uri = "Purchase/summary-detail?domainId=" + domainId;
        httpApi.get(accessToken, uri, successCallBack,
            (error)=>{
                switch(error.response.status){
                    case 400: 
                        window.location.href = '/error/bad-request';
                        break;
                    default: 
                        window.location.href = '/error/server-error';
                        break;  
                }
            });
    };

    postPurchaseCompleted(accessToken, body, successCallBack) {
        const uri = "Purchase/completed";
        httpApi.post(accessToken, uri, body, successCallBack);
    };
}