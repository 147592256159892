export const EducationalDocumentData = {
  domainId: 0,
  documents: [
    {
      "id": 0,
      "name": "",
      "fileUrl": "",
      "fileType": "",
      "documentTypeId": 25
    },
    {
      "id": 0,
      "name": "",
      "fileUrl": "",
      "fileType": "",
      "documentTypeId": 22
    }
  ]
}