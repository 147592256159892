import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCurrentLanguage } from 'helpers/getLanguage';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as GbFlag } from '../../../../assets/svg/gb.svg';
import { ReactComponent as KhFlag } from '../../../../assets/svg/kh.svg';
import SearchComponent from '../../search/search';
import './desktop-navigation.scss';

const DesktopNavigation = ({ onLoginClick, onLogoutClick, onResetPasswordClick, onSwitchLanguage }) => {
    const [showNav, setShowNav] = useState(true);
    const location = useLocation();

    const showHideNavBasedOnScroll = () => {
        const HOMEPAGE = '/';
        const scroll = document.documentElement.scrollTop || document.body.scrollTop;

        let offsetTop = 80;

        if (location.pathname === HOMEPAGE) {
            const homeDomain = document.querySelector('.home-domain-type-card');
            if (homeDomain) offsetTop = homeDomain.offsetTop - 120;
        }

        if (scroll >= offsetTop) setShowNav(false);
        else setShowNav(true);
    };

    useEffect(() => {
        showHideNavBasedOnScroll();
        document.addEventListener('scroll', (e) => {
            showHideNavBasedOnScroll();
        });
    }, [location]);

    const renderFlag = () => {
        if (getCurrentLanguage() === 'km') return <GbFlag />;
        else return <KhFlag />;
    };

    return (
        <>
            {!showNav && (
                <div className="flex-column desktop search-header">
                    <SearchComponent withSubmitButton isHeader />
                </div>
            )}
            <div className="flex-column desktop">
                <nav>
                    {showNav && (
                        <>
                            <div className="menu">
                                <span className="curp">
                                    {t('RegisterDomain')}
                                    <FontAwesomeIcon icon={faChevronDown} fontSize={'16px'}></FontAwesomeIcon>
                                </span>
                                <div className="dropdown">
                                    <div className="dropdown-header"></div>
                                    <div className="dropdown-body">
                                        <NavLink to="/help/general-info" className="link">
                                            <span>{t('generalInformation')}</span>
                                        </NavLink>
                                        <NavLink to="/help/faq" className="link">
                                            <span>{t('questionAnswer')}</span>
                                        </NavLink>
                                        <NavLink to="/whois" className="link">
                                            <span>{t('findoutdomain-sownershiptext')}</span>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                            <NavLink to="/help/how-to-register" className="menu">
                                <span>{t('howToBuyResgisterText')}</span>
                            </NavLink>
                            <NavLink to="/required-documents" className="menu">
                                <span>{t('DocumentText')}</span>
                            </NavLink>
                            <NavLink to="/about" className="menu">
                                <span>{t('AboutDNS')}.kh</span>
                            </NavLink>
                            <AuthenticatedTemplate>
                                <>
                                    <div className="menu">
                                        <span className="curp">
                                            {t('DomainText')}
                                            <FontAwesomeIcon icon={faChevronDown} fontSize={'16px'}></FontAwesomeIcon>
                                        </span>
                                        <div className="dropdown">
                                            <div className="dropdown-header"></div>
                                            <div className="dropdown-body">
                                                <NavLink to="/my-cart" className="link">
                                                    <span>{t('MyCartText')}</span>
                                                </NavLink>
                                                <NavLink to="/my-domain" className="link">
                                                    <span>{t('MyDomainText')}</span>
                                                </NavLink>
                                            </div>
                                            <div className="dropdown-footer"></div>
                                        </div>
                                    </div>
                                    <div className="menu">
                                        <span className="curp">
                                            {t('AccountText')}
                                            <FontAwesomeIcon icon={faChevronDown} fontSize={'16px'}></FontAwesomeIcon>
                                        </span>
                                        <div className="dropdown right">
                                            <div className="dropdown-header"></div>
                                            <div className="dropdown-body">
                                                <NavLink to="/my-profile" className="link">
                                                    <span>{t('MyProfileText')}</span>
                                                </NavLink>
                                                <button onClick={onResetPasswordClick} className="link">
                                                    <span>{t('ResetPasswordText')}</span>
                                                </button>
                                                <button className="link" onClick={onLogoutClick}>
                                                    <span>{t('LogOutText')}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            </AuthenticatedTemplate>
                        </>
                    )}
                    <UnauthenticatedTemplate>
                        <button className="menu btnLogin button primary" onClick={onLoginClick}>
                            {t('LoginText')}
                        </button>
                    </UnauthenticatedTemplate>
                    <button type="button" className="btnFlag" onClick={onSwitchLanguage}>
                        {renderFlag()}
                    </button>
                </nav>
            </div>
        </>
    );
};

export default DesktopNavigation;
