import React, { } from 'react';

import { Button } from 'antd';
import { CUSTOMER_TYPES } from '../../constants/constant';
import { numberWithCommas } from '../../helpers/formatter';
import { t } from 'i18next';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import imgCartGreen from '../../v2/assets/images/cart-green.png';
import webpCartGreen from '../../v2/assets/images/cart-green.webp';

import Picture from 'v2/core/components/picture/picture';

export const DomainSearchResult = ({ domainSearchResult, customerType, onClickPurchase }) => {
    return (
        <section className="row border-bottom py-4 domain-result">
            <div className="col-auto align-self-center">
                <div className="domain-result-available">
                    <span className="search-text">
                        <FontAwesomeIcon className="domain-icon checked" icon={faCheck} />&nbsp;
                        <span>{domainSearchResult.domainName}</span>
                    </span>
                    <span className="badge badge-success badge-pill">{t("IsAvailableText")}</span>
                </div>
            </div>
            <div className="col align-self-center text-right">
                {
                    customerType !== CUSTOMER_TYPES.GOVERNMENT &&
                    <div className="d-flex domain-result-available-purchase">
                        <span className="mr-2 domain-price">KHR {numberWithCommas(domainSearchResult.price)}/{t("Year")}</span>
                        <Button onClick={() => onClickPurchase(domainSearchResult)} className="btn btn-purchase btn-green">
                            <Picture imgSrc={imgCartGreen} webpSrc={webpCartGreen} />&nbsp;
                            {t("PurchaseNowText")}
                        </Button>
                    </div>
                }
            </div>
        </section>
    )
}