import { CUSTOMER_TYPES, DOCUMENT_STATUS, DOMAIN_STATUS } from '../../constants/constant';
import { getBadgeClasses, getBulletClasses } from '../../helpers/statusDisplayHelper';

import { Link } from 'react-router-dom';
import React from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

export const DomainList = ({ domainList, customerType }) => {
    const { t } = useTranslation();

    const RouteResubmit = (item) =>
        customerType.id === CUSTOMER_TYPES.GOVERNMENT
            ? `/government-resubmit/profile/${item.id}`
            : `/resubmit/profile/${item.id}`;
    const RouteDomainDetail = (item) =>
        customerType.id === CUSTOMER_TYPES.GOVERNMENT
            ? `/government-domain-detail/${item.id}`
            : `/my-domain-detail/${item.id}`;

    return (
        <div className="domain-list">
            <table className="table bg-white">
                <thead>
                    <tr>
                        <th className="mb-3">
                            N<sup>0</sup>
                        </th>
                        <th>{t('DomainText')}</th>
                        <th>{t('DocumentStatus')}</th>
                        <th scope="col">{t('ExpireDate')}</th>
                    </tr>
                </thead>
                <tbody>
                    {domainList !== undefined &&
                        domainList.length > 0 &&
                        domainList.map((item, i) => {
                            return (
                                <tr key={item.id}>
                                    <td>{i + 1}|</td>
                                    <td>
                                        <Link to={RouteDomainDetail(item)} className="mr-2">
                                            {item.name}
                                        </Link>
                                        <div>
                                            <span className={`badge ${getBadgeClasses(item.domain_status_id)} mr-2`}>
                                                {item.domain_status_id === DOMAIN_STATUS.READY ? (
                                                    <i className="fas fa-check mr-1"></i>
                                                ) : (
                                                    ''
                                                )}
                                                {t('DomainStatus' + item.domain_status_id)}
                                            </span>
                                            {item.document_status_id === DOCUMENT_STATUS.PENDING_RESUBMIT && (
                                                <Link to={RouteResubmit(item)}>
                                                    <span className="badge badge-primary">
                                                        {t('ResubmitYourDocumentText')}
                                                    </span>
                                                </Link>
                                            )}
                                            {item.is_required_fill_server_info === true &&
                                                item.domain_status_id === DOMAIN_STATUS.PENDING_ACTIVATION && (
                                                    <Link to={`/update-server-info/${item.id}`}>
                                                        <span className="badge badge-primary">
                                                            {t('FillinServerInfo')}
                                                        </span>
                                                    </Link>
                                                )}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div
                                                className={`bullet ${getBulletClasses(
                                                    item.document_status_id
                                                )} flex-shrink-0`}
                                            ></div>
                                            <div>{t('DocumentStatus' + item.document_status_id)}</div>
                                        </div>
                                    </td>
                                    <td>
                                        {item.expire_date && (
                                            <span
                                                className={`${
                                                    item.domain_status_id === DOMAIN_STATUS.EXPIRED ? 'text-danger' : ''
                                                }`}
                                            >
                                                {moment(item.expire_date).format('DD-MMM-YYYY')}
                                            </span>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
};
