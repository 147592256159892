import axios from "axios";
import { HEADER_CONFIG, API_ENDPOINT } from "../../apiConfig";
const API_NAME = "customer";
export default class CustomerService {
    async getCustomerCart(accessToken) {
        HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
        return axios.get(API_ENDPOINT + API_NAME + "/cart", HEADER_CONFIG);
    };
    async removeCustomerCart(accessToken, body) {
        HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
        return axios.post(API_ENDPOINT + API_NAME + "/cart/remove", body, HEADER_CONFIG);
    };

    async addCustomerCart(accessToken, body) {
        console.log("token");
        HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
        try {
            const response = await axios.post(API_ENDPOINT + API_NAME + "/cart/add", body, HEADER_CONFIG);
            return response.data;
        } catch (error) {
            return error;
        }
    };
}